import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import { ReurbProvider } from './frontend/contexts/reurb';
import AppRoutes from './AppRoutes';

function App() {
  return (
    <div className="App">
      <ChakraProvider>
        <ReurbProvider>
          <AppRoutes />
        </ReurbProvider>
      </ChakraProvider>
    </div>
  );
}

export default App;
