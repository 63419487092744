import axios from "axios";

export const api = axios.create({
    baseURL: "https://integrado.site/backend/app",
    headers: {
        'Authorization': sessionStorage.getItem("token") ? `Bearer ${sessionStorage.getItem("token")}` : null
    }
});

export function urlImages() {
    const baseUrl = "https://integrado.site/frontend/images";
    return baseUrl;
};

export const createSession = async (email, password) => {
    return api.post("../public/Login.php", JSON.stringify({ email, password }));
};

