import React from "react";
import { useContext, useState, useEffect } from "react";

import NavSuperior from "../../components/NavBar/navsuperior";
import DadosRemessa from "../../components/Remessa/dadosremessa";

import { ReurbContext } from "../../contexts/reurb";

import EditarProcesso from "./editarprocesso";
import ExibirProcesso from "./exibirprocesso";

const ProcessoPage = () => {
    const { editar_processo, setEditar_processo } = useContext(ReurbContext);

    const [loaded, setLoaded] = useState(0);

    //Ler dados
    useEffect(() => {
        setEditar_processo(0);
        setLoaded(1);
    }, []);

    function renderizar() {
        if (loaded === 1) {
            if (editar_processo === 0) {
                return (
                    <ExibirProcesso />
                );
            }
            else {
                return (
                    <EditarProcesso />
                );
            }
        } else {
            return null;
        }
    }

    return (
        <div id="processo">
            <NavSuperior />
            <DadosRemessa titulo="Painel do Processo" />
            {renderizar()}
        </div>
    );
}

export default ProcessoPage;