import React from "react";
import { useContext, useState, useEffect } from "react";
import { SimpleGrid, Center, Collapse, useDisclosure, Spacer, Box, Text, List, ListItem, ListIcon } from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon, WarningTwoIcon } from "@chakra-ui/icons";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

import { api } from "../../../services/api";
import { ChevronRightIcon } from "@chakra-ui/icons";

const Fase = () => {
  //Contexto
  const { logout } = useContext(AuthContext);
  const { unid_id } = useContext(ReurbContext);

  //Transitions  
  const { isOpen, onToggle } = useDisclosure();

  //Fase
  const [fase, setFase] = useState("");

  //Documental
  const [contrato, setContrato] = useState(0);
  const [documentos, setDocumentos] = useState(0);
  const [dados, setDados] = useState(0);

  //Topografia
  const [medicao, setMedicao] = useState(0);
  const [lepac, setLepac] = useState(0);
  const [conferencia, setConferencia] = useState(0);

  //Projeto
  const [nucleo, setNucleo] = useState(0);
  const [analise_matricula, setAnalise_matricula] = useState(0);
  const [fase_projeto, setFase_projeto] = useState("");

  //Prefeitura
  const [prefeitura, setPrefeitura] = useState(0);
  const [diligencia_prefeitura, setDiligencia_prefeitura] = useState(0);

  //Registro
  const [registro, setRegistro] = useState(0);
  const [diligencia_registro, setDiligencia_registro] = useState(0);

  //Diligência respondida
  const [diligencia_respondida, setDiligencia_respondida] = useState(0);

  //Matrícula
  const [matricula, setMatricula] = useState(0);

  //Ler dados
  useEffect(() => {
    const data = {
      unid_id
    };
    api.post('/fase/LerFase.php', JSON.stringify(data))
      .then(function (rp) {

        setContrato(rp.data.contrato);
        setDocumentos(rp.data.documentos);
        setDados(rp.data.dados);

        setMedicao(rp.data.medicao);
        setLepac(rp.data.lepac);
        setConferencia(rp.data.conferencia);

        setNucleo(rp.data.nucleo);
        setAnalise_matricula(rp.data.analise_matricula);
        setFase_projeto(rp.data.fase_projeto);

        setPrefeitura(rp.data.prefeitura);
        setDiligencia_prefeitura(rp.data.diligencia_prefeitura);

        setRegistro(rp.data.registro);
        setDiligencia_registro(rp.data.diligencia_registro);

        setDiligencia_respondida(rp.data.diligencia_respondida);

        setMatricula(rp.data.matricula);

        setFase(rp.data.fase);

      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        }
      });
  }, []);

  return (
    <div id="fase">
      <SimpleGrid columns={6} h={'50px'} bg={'gray'} color={'white'} cursor='pointer' onClick={onToggle} >
        <Center borderTopWidth={'8px'} borderColor={fase === 'Documental' ? 'teal.400' : 'none'}><Text ml={'30%'}>DOCUMENTAL</Text><Spacer /><ChevronRightIcon boxSize={8} /></Center>
        <Center borderTopWidth={'8px'} borderColor={fase === 'Topografia' ? 'teal.400' : 'none'}><Text ml={'30%'}>TOPOGRAFIA</Text><Spacer /><ChevronRightIcon boxSize={8} /></Center>
        <Center borderTopWidth={'8px'} borderColor={fase === 'Projeto' ? 'teal.400' : 'none'}><Text ml={'30%'}>PROJETO</Text><Spacer /><ChevronRightIcon boxSize={8} /></Center>
        <Center borderTopWidth={'8px'} borderColor={fase === 'Prefeitura' ? 'teal.400' : 'none'}><Text ml={'30%'}>PREFEITURA</Text><Spacer /><ChevronRightIcon boxSize={8} /></Center>
        <Center borderTopWidth={'8px'} borderColor={fase === 'Registro' ? 'teal.400' : 'none'}><Text ml={'30%'}>REGISTRO</Text><Spacer /><ChevronRightIcon boxSize={8} /></Center>
        <Center borderTopWidth={'8px'} borderColor={fase === 'Matrícula' ? 'teal.400' : 'none'}><Text ml={'30%'}>MATRÍCULA</Text><Spacer /></Center>
      </SimpleGrid>
      <Collapse in={isOpen} animateOpacity>
        <SimpleGrid columns={6} bg={'gray.100'}>

          <Box className="documental" p={5}><List spacing={1}>
            <ListItem display={contrato === 1 ? 'block' : 'none'}>
              <ListIcon as={CheckCircleIcon} color='green.500' />
              Contrato assinado
            </ListItem>
            <ListItem display={contrato === 0 ? 'block' : 'none'}>
              <ListIcon as={WarningTwoIcon} color='orange.500' />
              Contrato não assinado
            </ListItem>
            <ListItem display={documentos === 1 ? 'block' : 'none'}>
              <ListIcon as={CheckCircleIcon} color='green.500' />
              Documentos completos
            </ListItem>
            <ListItem display={documentos === 0 ? 'block' : 'none'}>
              <ListIcon as={WarningTwoIcon} color='orange.500' />
              Documentos incompletos
            </ListItem>
            <ListItem display={dados === 1 ? 'block' : 'none'}>
              <ListIcon as={CheckCircleIcon} color='green.500' />
              Dados completos
            </ListItem>
            <ListItem display={dados === 0 ? 'block' : 'none'}>
              <ListIcon as={WarningTwoIcon} color='orange.500' />
              Dados incompletos
            </ListItem>
          </List>
          </Box>

          <Box className="topografia" p={5}><List spacing={1}>
            <ListItem display={medicao === 1 ? 'block' : 'none'}>
              <ListIcon as={CheckCircleIcon} color='green.500' />
              Medição concluída
            </ListItem>
            <ListItem display={medicao === 0 ? 'block' : 'none'}>
              <ListIcon as={WarningTwoIcon} color='orange.500' />
              Medição pendente
            </ListItem>
            <ListItem display={lepac === 1 ? 'block' : 'none'}>
              <ListIcon as={CheckCircleIcon} color='green.500' />
              LEPAC elaborado
            </ListItem>
            <ListItem display={lepac === 0 ? 'block' : 'none'}>
              <ListIcon as={WarningTwoIcon} color='orange.500' />
              LEPAC pendente
            </ListItem>
            <ListItem display={conferencia === 1 ? 'block' : 'none'}>
              <ListIcon as={CheckCircleIcon} color='green.500' />
              LEPAC conferido
            </ListItem>
            <ListItem display={conferencia === 0 ? 'block' : 'none'}>
              <ListIcon as={WarningTwoIcon} color='orange.500' />
              LEPAC não conferido
            </ListItem>
          </List>
          </Box>

          <Box className="projeto" p={5}><List spacing={1}>
            <ListItem display={nucleo === 1 ? 'block' : 'none'}>
              <ListIcon as={CheckCircleIcon} color='green.500' />
              Núcleo definido
            </ListItem>
            <ListItem display={nucleo === 0 ? 'block' : 'none'}>
              <ListIcon as={WarningTwoIcon} color='orange.500' />
              Núcleo indefinido
            </ListItem>
            <ListItem display={analise_matricula === 1 ? 'block' : 'none'}>
              <ListIcon as={CheckCircleIcon} color='green.500' />
              Matrículas analisadas
            </ListItem>
            <ListItem display={analise_matricula === 0 ? 'block' : 'none'}>
              <ListIcon as={WarningTwoIcon} color='orange.500' />
              Análise de matrículas pendente
            </ListItem>
            <ListItem display={fase_projeto === 'Concluído' ? 'block' : 'none'}>
              <ListIcon as={CheckCircleIcon} color='green.500' />
              Projeto concluído
            </ListItem>
            <ListItem display={fase_projeto === 'Pendente' ? 'block' : 'none'}>
              <ListIcon as={WarningTwoIcon} color='orange.500' />
              Projeto pendente
            </ListItem>
            <ListItem display={fase_projeto === 'Aguardando' ? 'block' : 'none'}>
              <ListIcon as={CheckCircleIcon} color='green.500' />
              Projeto aguardando
            </ListItem>
            <ListItem display={fase_projeto === 'Em Andamento' ? 'block' : 'none'}>
              <ListIcon as={WarningIcon} color='blue.500' />
              Projeto em andamento
            </ListItem>
            <ListItem display={fase_projeto === 'Em Revisão' ? 'block' : 'none'}>
              <ListIcon as={WarningIcon} color='blue.500' />
              Projeto em revisão
            </ListItem>
          </List>
          </Box>

          <Box className="prefeitura" p={5}><List spacing={1}>
            <ListItem display={prefeitura === 1 ? 'block' : 'none'}>
              <ListIcon as={CheckCircleIcon} color='green.500' />
              Protocolo efetuado
            </ListItem>
            <ListItem display={prefeitura === 0 ? 'block' : 'none'}>
              <ListIcon as={WarningTwoIcon} color='orange.500' />
              Protocolo pendente
            </ListItem>
            <ListItem display={registro === 1 && diligencia_prefeitura === 0 ? 'block' : 'none'}>
              <ListIcon as={WarningIcon} color='blue.500' />
              Sem diligência
            </ListItem>
            <ListItem display={diligencia_prefeitura === 1 && diligencia_respondida === 1 ? 'block' : 'none'}>
              <ListIcon as={CheckCircleIcon} color='green.500' />
              Diligência respondida
            </ListItem>
            <ListItem display={registro === 0 && diligencia_prefeitura === 1 && diligencia_respondida === 0 ? 'block' : 'none'}>
              <ListIcon as={WarningTwoIcon} color='orange.500' />
              Diligência em aberto
            </ListItem>
          </List>
          </Box>

          <Box className="registro" p={5}><List spacing={1}>
            <ListItem display={registro === 1 ? 'block' : 'none'}>
              <ListIcon as={CheckCircleIcon} color='green.500' />
              Protocolo efetuado
            </ListItem>
            <ListItem display={registro === 0 ? 'block' : 'none'}>
              <ListIcon as={WarningTwoIcon} color='orange.500' />
              Protocolo pendente
            </ListItem>
            <ListItem display={matricula === 1 && diligencia_registro === 0 ? 'block' : 'none'}>
              <ListIcon as={WarningIcon} color='blue.500' />
              Sem diligência
            </ListItem>
            <ListItem display={registro === 1 && diligencia_registro === 1 && diligencia_respondida === 1 ? 'block' : 'none'}>
              <ListIcon as={CheckCircleIcon} color='green.500' />
              Diligência respondida
            </ListItem>
            <ListItem display={registro === 1 && diligencia_registro === 1 && diligencia_respondida === 0 ? 'block' : 'none'}>
              <ListIcon as={WarningTwoIcon} color='orange.500' />
              Diligência em aberto
            </ListItem>
          </List>
          </Box>

          <Box className="matricula" p={5}><List spacing={1}>
            <ListItem display={matricula === 1 ? 'block' : 'none'}>
              <ListIcon as={CheckCircleIcon} color='green.500' />
              Matrícula emitida
            </ListItem>
            <ListItem display={matricula === 0 ? 'block' : 'none'}>
              <ListIcon as={WarningTwoIcon} color='orange.500' />
              Matrícula pendente
            </ListItem>
          </List>
          </Box>

        </SimpleGrid>
      </Collapse>
    </div>
  );
}

export default Fase;