import React from "react";
import { useContext, useState, useEffect } from "react";
import { Grid, GridItem, Text, Button, Flex, SimpleGrid, Center, Heading, Container, Spacer, ButtonGroup } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon, SettingsIcon } from "@chakra-ui/icons";
import { NucleoIcon } from "../../icons/nucleoicon";
import { ProcessoIcon } from "../../icons/processoicon";
import { RequerenteIcon } from "../../icons/requerenteicon";
import { UploadIcon } from "../../icons/uploadicon";

import NavSuperior from "../../components/NavBar/navsuperior";
import DadosRemessa from "../../components/Remessa/dadosremessa";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

import { api } from "../../../services/api";

const RemessaPage = () => {
  const navigate = useNavigate();

  //Variáveis do Contexto
  const { logout } = useContext(AuthContext);
  const { rem_id } = useContext(ReurbContext);

  //Variáveis Remessa
  const [nucleos, setNucleos] = useState(0);
  const [processos, setProcessos] = useState(0);
  const [requerentes, setRequerentes] = useState(0);

  //Ler dados
  useEffect(() => {
    api.post('/remessa/LerRemessa.php', JSON.stringify({ rem_id }))
    .then(function (rp) {
      setNucleos(rp.data.nucleos);
        setProcessos(rp.data.processos);
        setRequerentes(rp.data.requerentes);
    })
    .catch(function (error) {
      if (error.response.data === "EXPIRED") {
        logout();
      }else{
        console.log(error);
      }
    });
  }, []);

  //Ir para home
  function goToRemessas() {
    navigate('/reurb');
  }

  //Ir para lista de núcleos
  function goToNucleos() {
    navigate('/nucleos');
  }

  //Ir para lista de processos
  function goToProcessos() {
    navigate('/processos');
  }

  //Ir para lista de requerentes
  function goToRequerentes() {
    navigate('/requerentes');
  }

  //Ir para configurações
  function goToConfiguracoes() {
    navigate('/configuracoes');
  }

  //Ir para importar
  function goToImportar() {
    navigate('/importar');
  }

  //Ir para documental
  function goToDocumental() {
    navigate('/documental');
  }

  //Ir para topografia
  function goToTopografia() {
    navigate('/topografia');
  }

  //Ir para modalidade
  function goToModalidade() {
    navigate('/modalidade');
  }

  //Ir para qualificação
  function goToQualificacao() {
    navigate('/qualificacao');
  }

  //Ir para financeiro
  function goToFinanceiro() {
    navigate('/financeiro');
  }

  return (
    <div id="remessa">
      <NavSuperior />
      <DadosRemessa titulo="Painel da Remessa" />
      <Container maxW={'95%'} mb={20}>
        <Flex direction='row'>
          <Button colorScheme="teal" w={'5%'} rounded={'none'} h={'58px'} onClick={e => goToRemessas()}><ChevronLeftIcon boxSize={8} /></Button>
          <Flex background={'white'} border={'1px'} p={2} w={'95%'}>
            <Heading size={'md'} color={'gray.700'} mt={2} ml={3}></Heading>
            <Spacer />
            <ButtonGroup gap='4'>
              <Button colorScheme="teal" variant='outline' onClick={e => goToImportar()}>Importar<UploadIcon ml={2} /></Button>
              <Button colorScheme="teal" variant='outline' onClick={e => goToConfiguracoes()}>Configurar<SettingsIcon ml={2} /></Button>
            </ButtonGroup>
          </Flex>
        </Flex>

        <Grid
          templateRows='repeat(1, 1fr)'
          templateColumns='repeat(12, 1fr)'
        >
          <GridItem colSpan={7}>
            <SimpleGrid columns={3} spacing={10} m={10}>
              <Button colorScheme={'teal'} variant={'outline'} h={'300px'} rounded={20} boxShadow={'lg'} onClick={e => goToRequerentes()}>
                <Flex direction={'column'} gap={5}>
                  <Center><RequerenteIcon boxSize={16} /></Center>
                  <Center><Heading size='md' color={'gray'}>Requerentes</Heading></Center>
                  <Center><Text fontSize={'4xl'} color={'black'}>{requerentes}</Text></Center>
                </Flex>
              </Button>
              <Button colorScheme={'teal'} variant={'outline'} h={'300px'} rounded={20} boxShadow={'lg'} onClick={e => goToProcessos()}>
                <Flex direction={'column'} gap={5}>
                  <Center><ProcessoIcon boxSize={16} /></Center>
                  <Center><Heading size='md' color={'gray'}>Processos</Heading></Center>
                  <Center><Text fontSize={'4xl'} color={'black'}>{processos}</Text></Center>
                </Flex>
              </Button>
              <Button colorScheme={'teal'} variant={'outline'} h={'300px'} rounded={20} boxShadow={'lg'} onClick={e => goToNucleos()}>
                <Flex direction={'column'} gap={5}>
                  <Center><NucleoIcon boxSize={16} /></Center>
                  <Center><Heading size='md' color={'gray'}>Núcleos</Heading></Center>
                  <Center><Text fontSize={'4xl'} color={'black'}>{nucleos}</Text></Center>
                </Flex>
              </Button>
            </SimpleGrid>
          </GridItem>
          <GridItem colSpan={5}>
            <SimpleGrid rows={4} m={10} boxShadow={'lg'}> 
              <Button textAlign={'left'} h={'60px'} colorScheme={'gray'} variant={'outline'} color={'teal'} rounded={0} onClick={e => goToDocumental()}>DOCUMENTAL</Button>
              <Button textAlign={'left'} h={'60px'} colorScheme={'gray'} variant={'outline'} color={'teal'} rounded={0} onClick={e => goToTopografia()}>TOPOGRAFIA</Button>
              <Button textAlign={'left'} h={'60px'} colorScheme={'gray'} variant={'outline'} color={'teal'} rounded={0} onClick={e => goToModalidade()}>MODALIDADE</Button>
              <Button textAlign={'left'} h={'60px'} colorScheme={'gray'} variant={'outline'} color={'teal'} rounded={0} onClick={e => goToQualificacao()}>QUALIFICAÇÃO</Button>
              <Button textAlign={'left'} h={'60px'} colorScheme={'gray'} variant={'outline'} color={'teal'} rounded={0} onClick={e => goToFinanceiro()}>FINANCEIRO</Button>
            </SimpleGrid>
          </GridItem>
        </Grid>
      </Container>
    </div>
  );
}

export default RemessaPage;