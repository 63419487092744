import React, { useRef } from "react";
import { useEffect, useState, useContext } from 'react';
import { useToast, Text, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Button, Select, Flex, Spacer, Container, ButtonGroup, Tabs, TabList, Tab, Heading, TabPanel, TabPanels } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import ExportExcel from "../../components/Excel/excelexport";
import { ChevronLeftIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";

import NavSuperior from "../../components/NavBar/navsuperior";
import DadosRemessa from "../../components/Remessa/dadosremessa";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

import { api } from "../../../services/api";

const DocumentalPage = () => {
	const { logout } = useContext(AuthContext);

	//Contexto rem_id
	const { rem_id, setReq_id, rem_municipio, rem_remessa } = useContext(ReurbContext);

	//Navegação
	const navigate = useNavigate();

	//Variáveis Chakra UI
	const toast = useToast();

	//Variáveis
	const [lista_requerentes, setLista_requerentes] = useState([{ req_id: 0, codigo: '-', requerente: '-', contato: '-', estado_civil: '-', tipo: '-', contrato: -1, procuracao: -1, requerimento: -1, distrato: -1, documento_faltante: '-', informacao_faltante: '-', doc_observacao: '-', situacao: '-' }]);
	const [excel_documental, setExcel_documental] = useState([{ CodigoProcesso: '-', Requerente: '-', Contato: '-', EstadoCivil: '-', Tipo: '-', Contrato: '-', Procuracao: '-', Requerimento: '-', Distrato: '-', DocumentoFaltante: '-', InformacaoFaltante: '-', Observacao: '-', Situacao: '-' }]);
	const [lista_documento, setLista_documento] = useState([{ doc_id: 0, documento: '-' }]);
	const [lista_informacao, setLista_informacao] = useState([{ inf_id: 0, informacao: '-' }]);
	const [lista_situacao, setLista_situacao] = useState([{ sit_id: 0, situacao: '-' }]);

	//Filtros
	const [documento_selecionado, setDocumento_selecionado] = useState(0);
	const [informacao_selecionada, setInformacao_selecionada] = useState(0);
	const [situacao_selecionada, setSituacao_selecionada] = useState(0);

	//Ler dados
	useEffect(() => {
		const data = {
			rem_id,
			documento_selecionado,
			informacao_selecionada,
			situacao_selecionada
		}
		api.post('/documental/LerDocumental.php', JSON.stringify(data))
			.then(function (rp) {
				setLista_requerentes(rp.data.lista_requerentes);
				setExcel_documental(rp.data.excel_documental);
				setLista_documento(rp.data.lista_documento);
				setLista_informacao(rp.data.lista_informacao);
				setLista_situacao(rp.data.lista_situacao);
			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				} else {
					console.log(error);
				}
			});
	}, [documento_selecionado, informacao_selecionada, situacao_selecionada]);

	//Ir para remessa
	function goToRemessa() {
		navigate('/remessa');
	}

	//Ir para requerente
	function irParaRequerente(id) {
		if (id !== 0) {
			setReq_id(id);
			navigate('/Requerente', { replace: true });
		}
	}

	return (
		<div id="documental">
			<NavSuperior />
			<DadosRemessa titulo="Análise Documental" />

			<Container maxW='95%' bg='white' mb={20}>
				<Flex direction='row'>
					<Button colorScheme="teal" w={'5%'} rounded={'none'} h={'58px'} onClick={e => goToRemessa()}><ChevronLeftIcon boxSize={8} /></Button>
					<Flex background={'white'} border={'1px'} p={2} w={'95%'}>
						<Spacer />
						<Text mr={2} pt={2}>Documento</Text>
						<Select borderColor={'teal'} size='sm' w='150px' pt={1} mr={8} value={documento_selecionado} onChange={e => { setDocumento_selecionado(e.target.value) }}>
							<option key={0} value={0}>{'-'}</option>
							{lista_documento.map((item, i) => {
								return (<option key={i + 1} value={item.doc_id}>{item.documento}</option>);
							})}
						</Select>
						<Text mr={2} pt={2}>Informação</Text>
						<Select borderColor={'teal'} size='sm' w='150px' pt={1} mr={8} value={informacao_selecionada} onChange={e => { setInformacao_selecionada(e.target.value) }}>
							<option key={0} value={0}>{'-'}</option>
							{lista_informacao.map((item, i) => {
								return (<option key={i + 1} value={item.inf_id}>{item.informacao}</option>);
							})}
						</Select>
						<Text mr={2} pt={2}>Situação</Text>
						<Select borderColor={'teal'} size='sm' w='150px' pt={1} mr={8} value={situacao_selecionada} onChange={e => { setSituacao_selecionada(e.target.value) }}>
							<option key={0} value={0}>{'-'}</option>
							{lista_situacao.map((item, i) => {
								return (<option key={i + 1} value={item.sit_id}>{item.situacao}</option>);
							})}
						</Select>
						<ButtonGroup gap='4' ml={5}>
							<ExportExcel excelData={excel_documental} fileName={"Dados Documental"} />
						</ButtonGroup>
					</Flex>
				</Flex>

				<Tabs isFitted>
					<TabList backgroundColor={'gray.200'} h={'50px'} mb={5} mt={5}>
						<Tab className="docs_internos" _selected={{ color: 'white', bg: 'gray' }}><Heading as='h5' size='sm'>DOCUMENTOS INTERNOS</Heading></Tab>
						<Tab className="docs_requerentes" _selected={{ color: 'white', bg: 'gray' }}><Heading as='h5' size='sm'>DOCUMENTOS DOS REQUERENTES</Heading></Tab>
						<Tab className="info_qualificacao" _selected={{ color: 'white', bg: 'gray' }}><Heading as='h5' size='sm'>INFORMAÇÕES DA QUALIFICAÇÃO</Heading></Tab>
					</TabList>
					<TabPanels>
						<TabPanel className="docs_internos">
						<TableContainer>
								<Table size='sm'>
									<Thead>
										<Tr>
											<Th textAlign={'center'}>CÓDIGO</Th>
											<Th textAlign={'center'}>REQUERENTE</Th>
											<Th textAlign={'center'}>CONTATO</Th>
											<Th textAlign={'center'}>CONTRATO</Th>
											<Th textAlign={'center'}>PROCURAÇÃO</Th>
											<Th textAlign={'center'}>REQUERIMENTO</Th>
											<Th textAlign={'center'}>DISTRATO</Th>
											<Th textAlign={'center'}>SITUAÇÃO</Th>
										</Tr>
									</Thead>
									<Tbody>
										{lista_requerentes.map((item, i) => {
											return (
												<Tr key={i} cursor='pointer' _hover={{ bg: 'gray.200' }} onClick={e => { irParaRequerente(item.req_id) }} bg={item.situacao === 'Cancelado' ? 'red.100' : 'white'}>
													<Td textAlign={'center'}>{item.codigo}</Td>
													<Td whiteSpace={'pre-wrap'} maxW={'250px'}>{item.requerente}</Td>
													<Td whiteSpace={'pre-wrap'} textAlign={'center'}>{item.contato}</Td>
													<Td textAlign={'center'}>
														<Text display={item.contrato === -1 ? 'inline' : 'none'}>-</Text>
														<CheckIcon display={item.contrato === 1 ? 'inline' : 'none'} color={'green.500'} />
														<CloseIcon display={item.contrato === 0 ? 'inline' : 'none'} color={'red'} />
													</Td>
													<Td textAlign={'center'}>
														<Text display={item.procuracao === -1 ? 'inline' : 'none'}>-</Text>
														<CheckIcon display={item.procuracao === 1 ? 'inline' : 'none'} color={'green.500'} />
														<CloseIcon display={item.procuracao === 0 ? 'inline' : 'none'} color={'red'} />
													</Td>
													<Td textAlign={'center'}>
														<Text display={item.requerimento === -1 ? 'inline' : 'none'}>-</Text>
														<CheckIcon display={item.requerimento === 1 ? 'inline' : 'none'} color={'green.500'} />
														<CloseIcon display={item.requerimento === 0 ? 'inline' : 'none'} color={'red'} />
													</Td>
													<Td textAlign={'center'}>
														<Text display={item.distrato === -1 ? 'inline' : 'none'}>-</Text>
														<CheckIcon display={item.distrato === 1 ? 'inline' : 'none'} color={'green.500'} />
														<CloseIcon display={item.distrato === 0 ? 'inline' : 'none'} color={'red'} />
													</Td>
													<Td textAlign={'center'}>{item.situacao}</Td>
												</Tr>
											);
										})}
									</Tbody>
								</Table>
							</TableContainer>

						</TabPanel>
						<TabPanel className="docs_requerentes">
							<TableContainer>
								<Table size='sm'>
									<Thead>
										<Tr>
											<Th textAlign={'center'}>CÓDIGO</Th>
											<Th textAlign={'center'}>REQUERENTE</Th>
											<Th textAlign={'center'}>CONTATO</Th>
											<Th textAlign={'center'}>TIPO</Th>
											<Th textAlign={'center'}>DOCUMENTO FALTANTE</Th>
											<Th textAlign={'center'}>OBSERVAÇÃO</Th>
											<Th textAlign={'center'}>SITUAÇÃO</Th>
										</Tr>
									</Thead>
									<Tbody>
										{lista_requerentes.map((item, i) => {
											return (
												<Tr key={i} cursor='pointer' _hover={{ bg: 'gray.200' }} onClick={e => { irParaRequerente(item.req_id) }} bg={item.situacao === 'Cancelado' ? 'red.100' : 'white'}>
													<Td textAlign={'center'}>{item.codigo}</Td>
													<Td whiteSpace={'pre-wrap'} maxW={'250px'}>{item.requerente}</Td>
													<Td whiteSpace={'pre-wrap'} textAlign={'center'}>{item.contato}</Td>
													<Td textAlign={'center'} whiteSpace={'pre-wrap'}>{item.tipo}</Td>
													<Td whiteSpace={'pre-wrap'} maxW={'20vw'}>{item.documento_faltante}</Td>
													<Td whiteSpace={'pre-wrap'} maxW={'20vw'}>{item.doc_observacao}</Td>
													<Td textAlign={'center'}>{item.situacao}</Td>
												</Tr>
											);
										})}
									</Tbody>
								</Table>
							</TableContainer>
						</TabPanel>
						<TabPanel className="info_qualificacao">
							<TableContainer>
								<Table size='sm'>
									<Thead>
										<Tr>
											<Th textAlign={'center'}>CÓDIGO</Th>
											<Th textAlign={'center'}>REQUERENTE</Th>
											<Th textAlign={'center'}>CONTATO</Th>
											<Th textAlign={'center'}>TIPO</Th>
											<Th textAlign={'center'}>DOCUMENTO FALTANTE</Th>
											<Th textAlign={'center'}>SITUAÇÃO</Th>
										</Tr>
									</Thead>
									<Tbody>
										{lista_requerentes.map((item, i) => {
											return (
												<Tr key={i} cursor='pointer' _hover={{ bg: 'gray.200' }} onClick={e => { irParaRequerente(item.req_id) }} bg={item.situacao === 'Cancelado' ? 'red.100' : 'white'}>
													<Td textAlign={'center'}>{item.codigo}</Td>
													<Td whiteSpace={'pre-wrap'} maxW={'250px'}>{item.requerente}</Td>
													<Td whiteSpace={'pre-wrap'} textAlign={'center'}>{item.contato}</Td>
													<Td textAlign={'center'} whiteSpace={'pre-wrap'}>{item.tipo}</Td>
													<Td whiteSpace={'pre-wrap'} maxW={'30vw'}>{item.informacao_faltante}</Td>
													<Td textAlign={'center'}>{item.situacao}</Td>
												</Tr>
											);
										})}
									</Tbody>
								</Table>
							</TableContainer>
						</TabPanel>
					</TabPanels>
				</Tabs>

			</Container>
		</div>
	);
}

export default DocumentalPage;