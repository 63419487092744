import React from "react";
import { useContext, useState, useEffect } from "react";
import { Grid, GridItem, Text, Input, Button, ButtonGroup, Select, Stack, RadioGroup, Radio, Checkbox, Box, Heading, Flex, Divider, useToast, useDisclosure, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Container, Spacer } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon, DeleteIcon } from "@chakra-ui/icons";
import { SaveIcon } from "../../icons/saveicon";

import NavSuperior from "../../components/NavBar/navsuperior";
import DadosRemessa from "../../components/Remessa/dadosremessa";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

import { api } from "../../../services/api";

const OcupantePage = () => {
  const { logout } = useContext(AuthContext);
  const { rem_id, ocup_id } = useContext(ReurbContext);

  //Variáveis Chakra UI
  const navigate = useNavigate();
  const cancelRef = React.useRef();
  const toast = useToast();
  const { isOpen: isExcluirOpen, onOpen: onExcluirOpen, onClose: onExcluirClose } = useDisclosure();

  //Variáveis Processo
  const [unid_cod, setUnid_cod] = useState("");
  const [modalidade, setModalidade] = useState("");
  const [nui_cod, setNui_cod] = useState("");

  //Variáveis Ocupante    
  const [id_requerente, setId_requerente] = useState(0);

  const [ocup_nome, setOcup_nome] = useState("");
  const [ocup_parentesco, setOcup_parentesco] = useState("");
  const [ocup_sexo, setOcup_sexo] = useState("");
  const [ocup_nascimento, setOcup_nascimento] = useState("");
  const [ocup_idade, setOcup_idade] = useState(0);
  const [ocup_deficiencia, setOcup_deficiencia] = useState(0);
  const [ocup_profissao, setOcup_profissao] = useState("");
  const [ocup_renda, setOcup_renda] = useState("");

  const [lista_requerentes, setLista_requerentes] = useState([{ value: 0, text: '' }]);


  //Ler dados
  useEffect(() => {
    //Dados do Ocupante
    api.post('/ocupante/LerOcupante.php', JSON.stringify({ ocup_id }))
      .then(function (rp) {

        setUnid_cod(rp.data.unid_cod);
        setModalidade(rp.data.modalidade);
        setNui_cod(rp.data.nui_cod);

        setId_requerente(rp.data.id_requerente);
        setOcup_nome(rp.data.ocup_nome);
        setOcup_parentesco(rp.data.ocup_parentesco);
        setOcup_sexo(rp.data.ocup_sexo);
        setOcup_nascimento(rp.data.ocup_nascimento);
        setOcup_idade(rp.data.ocup_idade);
        setOcup_deficiencia(rp.data.ocup_deficiencia);
        setOcup_profissao(rp.data.ocup_profissao);
        setOcup_renda(rp.data.ocup_renda);

        setLista_requerentes(rp.data.lista_requerentes);

      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }, []);

  //Salvar dados ocupante
  async function salvarOcupante() {
    const data = {

      rem_id,
      ocup_id,

      //Dados Gerais            
      id_requerente,
      ocup_nome,
      ocup_parentesco,
      ocup_sexo,
      ocup_nascimento,
      ocup_idade,
      ocup_deficiencia,
      ocup_profissao,
      ocup_renda

    }
    api.post('/ocupante/SalvarOcupante.php', JSON.stringify(data))
      .then(function (rp) {
        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
        })
        );
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Excluir ocupante
  async function excluirOcupante() {
    const data = {
      ocup_id
    }
    await api.post('/ocupante/ExcluirOcupante.php', JSON.stringify(data))
      .then(function (rp) {
        //Ir para processo
        navigate('/processo');
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Calcular Idade
  function CalcularIdade(dataNasc) {
    //verificar se data é válida
    var date_regex = /^([0-9]{4}[-/]?((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))|([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00)[-/]?02[-/]?29)$/;
    if (date_regex.test(dataNasc)) {
      var dataAtual = new Date();
      var anoAtual = dataAtual.getFullYear();
      var anoNascParts = dataNasc.split('-');
      var diaNasc = parseInt(anoNascParts[2]);
      var mesNasc = parseInt(anoNascParts[1]);
      var anoNasc = parseInt(anoNascParts[0]);
      var idade = anoAtual - anoNasc;
      var mesAtual = dataAtual.getMonth() + 1;
      //Se mes atual for menor que o nascimento, nao fez aniversario ainda;  
      if (mesAtual < mesNasc) {
        idade--;
      } else {
        //Se estiver no mes do nascimento, verificar o dia
        if (mesAtual === mesNasc) {
          if (new Date().getDate() < diaNasc) {
            //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
            idade--;
          }
        }
      }
      if (idade < 0) {
        return 0;
      } else {
        return idade;
      }
    }
    else {
      return "";
    }
  }

  //Ir para processo
  function goToProcesso() {
    navigate('/processo');
  }

  return (
    <div id="ocupante">
      <NavSuperior />
      <DadosRemessa titulo="Painel do Ocupante" />
      <Container maxW='95%' bg='white'>
        <Flex direction='row' mb={8}>
          <Button colorScheme="teal" w={'5%'} rounded={'none'} h={'58px'} onClick={e => goToProcesso()}><ChevronLeftIcon boxSize={8} /></Button>
          <Flex background={'white'} border={'1px'} p={2} w={'95%'}>
            <Heading size={'md'} color={'gray.700'} mt={2} ml={3}>Processo {unid_cod} - {modalidade} - {nui_cod}</Heading>
            <Spacer />
            <ButtonGroup gap='4'>
              <Button colorScheme="teal" variant='outline' onClick={onExcluirOpen}>Excluir<DeleteIcon ml={2} /></Button>
            </ButtonGroup>
          </Flex>
        </Flex>

        <Heading as='h4' size='md' textAlign={'left'}>
          Dados Gerais
        </Heading>
        <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />

        <Grid
          h='120px'
          templateRows='repeat(4, 1fr)'
          templateColumns='repeat(12, 1fr)'
          gap={4}
        >
          <GridItem colSpan={4}>
            <Stack spacing={3} direction='row'><Text>Nome</Text><Input borderColor={'gray'} maxLength={250} placeholder='Nome Completo' size='sm' value={ocup_nome} onChange={e => setOcup_nome(e.target.value)} /></Stack>
          </GridItem>
          <GridItem colSpan={3}>
            <RadioGroup value={ocup_sexo}>
              <Stack spacing={3} direction='row'>
                <Text>Sexo</Text>
                <Radio borderColor={'gray'} size='md' colorScheme='teal' value="M" onChange={e => setOcup_sexo(e.target.value)} >Masculino</Radio>
                <Radio borderColor={'gray'} size='md' colorScheme='teal' value="F" onChange={e => setOcup_sexo(e.target.value)} >Feminino</Radio>
              </Stack>
            </RadioGroup>
          </GridItem>
          <GridItem colSpan={3}>
            <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Data de Nascimento</Text><Input borderColor={'gray'} type='date' size='sm' value={ocup_nascimento} onChange={e => { setOcup_nascimento(e.target.value); setOcup_idade(CalcularIdade(e.target.value)); }} /></Stack>
          </GridItem>
          <GridItem colSpan={2}>
            <Stack spacing={3} direction='row'><Text>Idade</Text><Input borderColor={'gray'} size='sm' value={ocup_idade} disabled /></Stack>
          </GridItem>

          <GridItem colSpan={6}><Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Requerente Vinculado</Text>
            <Select borderColor={'gray'} size='sm' value={id_requerente} onChange={e => setId_requerente(e.target.value)} >
              <option key={0} value={0}></option>
              {lista_requerentes.map(item => {
                return (<option key={item.value} value={item.value}>{item.text}</option>);
              })}
            </Select></Stack>
          </GridItem>
          <GridItem colSpan={6}>
            <Stack spacing={3} direction='row'><Text>Parentesco</Text><Input borderColor={'gray'} maxLength={50} placeholder='Pai, avó, filho, prima...' size='sm' value={ocup_parentesco} onChange={e => setOcup_parentesco(e.target.value)} /></Stack>
          </GridItem>

          <GridItem colSpan={6}>
            <Stack spacing={3} direction='row'><Text>Profissão</Text><Input borderColor={'gray'} maxLength={50} placeholder='Atividade, Desempregado, Estudante...' size='sm' value={ocup_profissao} onChange={e => setOcup_profissao(e.target.value)} /></Stack>
          </GridItem>
          <GridItem colSpan={3}>
            <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Renda Mensal</Text><Input borderColor={'gray'} maxLength={11} placeholder='R$/mês' size='sm' value={ocup_renda} onChange={e => setOcup_renda(e.target.value)} /></Stack>
          </GridItem>
          <GridItem colSpan={3}>
            <Checkbox borderColor={'gray'} size='md' colorScheme='teal' whiteSpace='nowrap' onChange={e => setOcup_deficiencia(e.target.checked ? 1 : 0)} isChecked={ocup_deficiencia === 1}>Possui Deficiência Incapacitante</Checkbox>
          </GridItem>

        </Grid>

        <Flex direction='row' p={5} mt={4} mb={4}>
          <Spacer />
          <Button colorScheme="teal" variant={'outline'} onClick={e => salvarOcupante()}>Salvar Ocupante <SaveIcon ml={2} /></Button>
        </Flex>

      </Container>

      {/*MODAL EXCLUIR*/}
      <AlertDialog isOpen={isExcluirOpen} leastDestructiveRef={cancelRef.current} onClose={onExcluirClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Excluir Ocupante
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza que deseja excluir o ocupante {ocup_nome}?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef.current} onClick={onExcluirClose}>
                Cancelar
              </Button>
              <Button colorScheme='red' onClick={() => { excluirOcupante(); onExcluirClose(); }} ml={3}>
                Excluir
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

    </div>
  );
}

export default OcupantePage;