import React from "react";
import { useContext, useState, useEffect } from "react";
import ResizeTextarea from "react-textarea-autosize";
import { Box, Flex, Spacer, Button, ButtonGroup, Textarea, Heading, Text, Stack, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, useDisclosure } from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";

import { api } from "../../../services/api";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

const Comentarios = (props) => {
	const { logout, user } = useContext(AuthContext);
	const { rem_id, nui_id, unid_id, comentario_alterado } = useContext(ReurbContext);

	//Variáveis Chakra UI
	const cancelRef = React.useRef();
	const { isOpen: isExcluirOpen, onOpen: onExcluirOpen, onClose: onExcluirClose } = useDisclosure();

	//Variáveis
	const [com_editar, setCom_editar] = useState(0);
	const [com_excluir, setCom_excluir] = useState(0);
	const [com_comentario, setCom_comentario] = useState("");
	const [com_editado, setCom_editado] = useState("");

	//Lista de comentários
	const [lista_comentarios, setLista_comentarios] = useState([{ usu_id: 0, usuario: "-", com_id: 0, com_controle: '-', painel: '-', comentario: '-', criado: '-', modificado: '-', }]);

	//Ler dados comentários
	useEffect(() => {
		const data = {
			rem_id,
			unid_id,
			nui_id,
			com_painel: props.painel
		}
		api.post('/comentario/LerComentario.php', JSON.stringify(data))
			.then(function (rp) {

				setLista_comentarios(rp.data.lista_comentarios);

			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				}
			});
	}, [comentario_alterado]);

	//Recarregar lista de comentários
	async function recarregarComentarios() {
		const data = {
			rem_id,
			unid_id,
			nui_id,
			com_painel: props.painel
		}
		await api.post('/comentario/LerComentario.php', JSON.stringify(data))
			.then(function (rp) {

				setLista_comentarios(rp.data.lista_comentarios);

			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				}
			});
	}

	//Novo comentário
	async function novoComentario() {
		if (com_comentario !== "") {
			const data = {
				user,
				rem_id,
				unid_id,
				nui_id,
				com_comentario,
				com_painel: props.painel
			}
			await api.post('/comentario/NovoComentario.php', JSON.stringify(data))
				.then(function (rp) {

					setCom_comentario("");
					recarregarComentarios();

				})
				.catch(function (error) {
					if (error.response.data === "EXPIRED") {
						logout();
					}
				});
		}
	}

	//Salvar Comentário
	async function salvarComentario(comentario) {
		if (com_editado !== "" && com_editado !== comentario) {
			const data = {
				com_editar,
				com_editado
			}
			await api.post('/comentario/SalvarComentario.php', JSON.stringify(data))
				.then(function (rp) {

					setCom_editado("");
					setCom_editar(0);
					recarregarComentarios();

				})
				.catch(function (error) {
					if (error.response.data === "EXPIRED") {
						logout();
					}
				});
		}
	}

	//Excluir comentário
	async function excluirComentario() {
		if (com_excluir !== 0) {
			const data = {
				com_excluir
			}
			await api.post('/comentario/ExcluirComentario.php', JSON.stringify(data))
				.then(function (rp) {

					recarregarComentarios();
					setCom_excluir(0);

				})
				.catch(function (error) {
					if (error.response.data === "EXPIRED") {
						logout();
					}
				});
		}
	}

	return (
		<div id="comentarios">
			<Box backgroundColor={'gray.100'} p={8}>
				<Heading as='h4' size='md' color={'gray.600'}>Comentários</Heading>
				<Flex direction={'column'} mt={2}>
					<Textarea borderColor={'gray'} bg={'white'} mb={'-60px'}
						p={8}
						pr={'170px'}
						borderRadius={0}
						minH="unset"
						overflow="hidden"
						w="100%"
						resize="none"
						minRows={1}
						as={ResizeTextarea}
						placeholder="Escreva um comentário..."
						value={com_comentario}
						onChange={e => setCom_comentario(e.target.value)}
						textAlign={'justify'}
					/>
					<Button colorScheme="teal" variant='outline' w={'100px'} alignSelf={'end'} zIndex={1000} mr={12} mb={7} onClick={e => novoComentario()}>Salvar</Button>

					{lista_comentarios.map((item, i) => {
						if (item.com_id !== 0) {
							if (com_editar !== item.com_id) {
								return (
									<Box p={8} borderColor={'gray.400'} borderWidth={'thin'} mb={2}>
										<Stack direction={'row'} spacing={5} mb={2}>
											<Text as='b'>{item.usuario}</Text>
											<Text as='cite' color={'gray.700'}>{item.criado}</Text>
											<Text as='cite' color={'gray.700'} display={item.modificado !== "-" ? 'block' : 'none'}>{"(editado em " + item.modificado + ")"}</Text>
											<Spacer />
											<ButtonGroup colorScheme="teal" variant='outline' gap={2} display={item.usu_id === parseInt(user) && item.com_controle !== 'permanente' ? 'block' : 'none'}>
												<Button boxSize={8} mt={'-10px'} onClick={e => { setCom_editado(item.comentario); setCom_editar(item.com_id); recarregarComentarios(); }}><EditIcon /></Button>
												<Button boxSize={8} mt={'-10px'} mr={4} onClick={e => { setCom_excluir(item.com_id); onExcluirOpen(); }}><DeleteIcon /></Button>
											</ButtonGroup>
										</Stack>

										<Text as={'pre'} mr={5} ml={5} fontFamily={'body'} textAlign={'justify'} whiteSpace={'pre-wrap'}>{item.comentario}</Text>

									</Box>
								)
							} else {
								return (
									<Box p={8} borderColor={'gray.400'} borderWidth={'thin'} mb={2}>
										<Stack direction={'row'} spacing={5} mb={2}>
											<Text as='b'>{item.usuario}</Text>
											<Text as='cite' color={'gray.700'}>{item.criado}</Text>
											<Text as='cite' color={'gray.700'} display={item.modificado !== "-" ? 'block' : 'none'}>{"(editado em " + item.modificado + ")"}</Text>
										</Stack>
										<Flex direction={'column'} mr={3}>
											<Textarea borderColor={'gray.400'} bg={'white'}
												p={8}
												borderRadius={0}
												minH="unset"
												overflow="hidden"
												w="100%"
												resize="none"
												minRows={3}
												as={ResizeTextarea}
												value={com_editado}
												onChange={e => setCom_editado(e.target.value)}
												textAlign={'justify'}
											/>
											<ButtonGroup colorScheme="teal" variant='outline' gap={2} mt={2} alignSelf={'end'}>
												<Button w={'100px'} onClick={e => salvarComentario(item.comentario)} >Salvar</Button>
												<Button w={'100px'} onClick={e => { setCom_editar(0); recarregarComentarios(); }}>Sair</Button>
											</ButtonGroup>
										</Flex>
									</Box>
								)
							}
						}
					}
					)}

				</Flex>
			</Box>

			{/*MODAL EXCLUIR*/}
			<AlertDialog isOpen={isExcluirOpen} leastDestructiveRef={cancelRef.current} onClose={onExcluirClose} isCentered>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							Excluir
						</AlertDialogHeader>

						<AlertDialogBody>
							Tem certeza que deseja excluir o comentário?
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef.current} onClick={onExcluirClose}>
								Cancelar
							</Button>
							<Button colorScheme='red' onClick={() => { excluirComentario(); onExcluirClose(); }} ml={3}>
								Excluir
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</div>
	);
}

export default Comentarios;