import React from "react";
import { useState, useContext, useEffect } from 'react';
import AppParticles from "./particles";
import { Text, Box, FormControl, FormLabel, Input, Stack, Button, Center } from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { AuthContext } from "../../contexts/auth";

import Logo from "./logo";

const LoginPage = () => {
  const { authenticated, login } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //Verificação senha
  const [ver_senha, setVer_senha] = useState(false);

  //Mensagem
  const [msg_login, setMsg_login] = useState("");

  async function handleSubmit() {
    await login(email, password); //integração com contexto / api
    if (!authenticated) {
      setMsg_login("Acesso Negado");
    }
  }

  function handleKeyDown(event) {
		if (event.key == "Enter") {
			handleSubmit();
		}
	}

  //Desabilitar mensagem
  useEffect(() => {
    if (msg_login !== "") {
      setMsg_login("");
    }
  }, [email, password]);

  return (
    <div id="login">
      <AppParticles />
      
      <Box spacing={8} mx={'auto'} maxW={'min'} bg={'white'} zIndex={10} textAlign={'center'} mt={'10vh'} borderRadius={20} boxShadow={'xl'}>
        <Center pt={5} pr={20} pl={20}>
        <Logo />
        </Center>
        <Box
          p={8}
          width={'400px'}>
          <Stack spacing={10}>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>E-mail</FormLabel>
                <Input type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} onKeyDown={handleKeyDown}/>
              </FormControl>
              <FormControl >
                <FormLabel>Senha</FormLabel>
                <Stack direction={'row'}>
                  <Input autoComplete={'no'} type={ver_senha === false ? 'password' : 'text'} name="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={handleKeyDown}/>
                  <Button variant={'ghost'} w={'50px'} onClick={e => setVer_senha(true)} display={ver_senha === false ? 'block' : 'none'}><ViewIcon color={'gray.500'} boxSize={5} /></Button>
                  <Button variant={'ghost'} w={'50px'} onClick={e => setVer_senha(false)} display={ver_senha === true ? 'block' : 'none'}><ViewOffIcon color={'gray.500'} boxSize={5} /></Button>
                </Stack>
              </FormControl>
            </Stack>
            <Stack spacing={5} h={'100px'}>
              <Button type="submit" onClick={handleSubmit}
                bg={'#292661'}
                color={'white'}
                _hover={{
                  bg: 'gray.500',
                }}>
                Entrar
              </Button>
              <Text display={'none'}>{String(authenticated)}</Text>
              <Text textAlign={'center'} fontSize='lg' color={'red'}>{msg_login}</Text>
            </Stack>
          </Stack>
        </Box>
      </Box>

    </div>
  );
}

export default LoginPage;