import { createIcon } from '@chakra-ui/icons'

// using `path`
export const BarCodeIcon = createIcon({
    displayName: 'BarCodeIcon',
    viewBox: '0 0 16 16',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path
            fill='currentColor'
            stroke='none'            
            d="M0 2.53h2.49v10.95H0zm11 0h2.49v10.95H11zm-6.02 0h1.24v10.95H4.98zm2.49 0h1.24v10.95H7.47zm7.29 0H16v10.95h-1.24z"
        />
    )
});