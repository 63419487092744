import React from "react";
import { useContext, useState, useEffect } from "react";
import { Image, Center, Container, Heading } from '@chakra-ui/react';

import NavSuperior from "../../components/NavBar/navsuperior";

import { api } from "../../../services/api";
import { AuthContext } from "../../contexts/auth";

const HomePage = () => {
  const { logout, user } = useContext(AuthContext);

  function testarToken() {
    api.post('/navbar/TesteToken.php')
      .then(function (rp) {
        console.log('TOKEN '+ rp.data.token);
      })
      .catch(function (error) {
        const errors = error.response?.data;
        if (errors === undefined || errors === "EXPIRED") {
          console.log("Sessão Expirou: "+ errors);
          logout();
        }
      });
  }

  return (
    <div id="home">
      <NavSuperior />
      <Center>
        <Container maxH={'400px'} maxW={'400px'} mt={"100px"}>
          <Image src={'https://www.unirio.br/propg/diretoria-de-inovacao-tecnologica-cultural-e-social/imagens/em-construcao/image'} onClick={e => testarToken()} />
          <Heading textAlign={'center'}>HOME</Heading>
        </Container>
      </Center>
    </div>
  );
}

export default HomePage;