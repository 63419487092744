import React from "react";
import { useContext, useState, useEffect } from "react";
import { Divider, Heading } from "@chakra-ui/react";

import NavSuperior from "../../components/NavBar/navsuperior";

import { AuthContext } from "../../contexts/auth";

import ExibirPerfil from "./exibirperfil";
import EditarPerfil from "./editarperfil";

const PerfilPage = () => {
  const { user, editar_perfil, setEditar_perfil, usuario_selecionado, setUsuario_selecionado } = useContext(AuthContext);

  const [loaded, setLoaded] = useState(0);

  //Ler dados
  useEffect(() => {
    if(usuario_selecionado===0){
      setUsuario_selecionado(user);
    }
    setEditar_perfil(0);
    /*if(user===usuario_selecionado){
      setEditar_perfil(0);
    }else{
      setEditar_perfil(1);
    } */  
    setLoaded(1);
  }, [usuario_selecionado]);

  function renderizar() {
    if (loaded === 1) {
      if (editar_perfil === 0) {
        return (
          <ExibirPerfil />
        );
      }
      else {
        return (
          <EditarPerfil />
        );
      }
    } else {
      return null;
    }
  }

  return (
    <div id="processo">
      <NavSuperior />
      <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
      <Heading as='h1' size='xl' color={'gray.600'} textAlign={'center'}>
        PERFIL
      </Heading>
      <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
      {renderizar()}
    </div>
  );
}

export default PerfilPage;