import React from "react";
import { useContext, useState, useEffect } from "react";
import { Input, Divider, Container, Heading, Tabs, TabList, Tab, TabPanels, TabPanel, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Spacer, Text, Stack, Select, Textarea, SimpleGrid, Box, Flex, Center, Grid, GridItem, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Badge, Tag } from '@chakra-ui/react';
import ResizeTextarea from "react-textarea-autosize";
import { ChevronLeftIcon, ChevronRightIcon, PlusSquareIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { SaveIcon } from "../../icons/saveicon";

import NavSuperior from "../../components/NavBar/navsuperior";

const MetasPage = () => {

  //Variáveis Chakra UI
  const { isOpen: isNovaOpen, onOpen: onNovaOpen, onClose: onNovaClose } = useDisclosure();

  //Variáveis
  const [met_ano, setMet_ano] = useState("2024");
  const [met_mes, setMet_mes] = useState("Março");
  const [met_semana, setMet_semana] = useState("Semana 01");
  const [met_setor, setMet_setor] = useState("Gestão");

  const [usu_responsavel, setUsu_responsavel] = useState(0);
  const [met_meta, setMet_meta] = useState("");
  const [met_descricao, setMet_descricao] = useState("");
  const [lista_anos, setLista_anos] = useState(['2024', '2025', '2026', '2027', '2028']);
  const [lista_semanas, setLista_semanas] = useState(['Semana 01', 'Semana 02', 'Semana 03', 'Semana 04', 'Semana 05']);
  const [lista_usuarios, setLista_usuarios] = useState([{ id: 0, nome: 'Guilherme Censi' }]);
  const [lista_metas, setLista_metas] = useState([{ id: 0, prioridade: 1, meta: 'Projeto Itapeva 05', descricao: 'Fazer o projeto do NUI Teste 01 de Itapeva/SP da Remessa 05....', comentario: 'Não é possível fazer o projeto pois falta matrícula...', responsavel: 0, situacao: 'Pendente', atrasado: 0 }]);

  //Salvar dados
  async function salvarMeta() {
    alert("Salvar Meta");
  }

  //Anterior
  function anterior() {
    if (met_semana === 'Semana 01') {
      if (met_mes === 'Janeiro') {
        if (lista_anos.includes(String(parseInt(met_ano) - 1))) {
          setMet_ano(String(parseInt(met_ano) - 1));
          setMet_mes('Dezembro');
          if (lista_semanas.includes('Semana 05')) {
            setMet_semana('Semana 05');
          } else {
            setMet_semana('Semana 04');
          }
        }
      } else if (met_mes === 'Fevereiro') {
        setMet_mes('Janeiro');
        if (lista_semanas.includes('Semana 05')) {
          setMet_semana('Semana 05');
        } else {
          setMet_semana('Semana 04');
        }
      } else if (met_mes === 'Março') {
        setMet_mes('Fevereiro');
        if (lista_semanas.includes('Semana 05')) {
          setMet_semana('Semana 05');
        } else {
          setMet_semana('Semana 04');
        }
      } else if (met_mes === 'Abril') {
        setMet_mes('Março');
        if (lista_semanas.includes('Semana 05')) {
          setMet_semana('Semana 05');
        } else {
          setMet_semana('Semana 04');
        }
      } else if (met_mes === 'Maio') {
        setMet_mes('Abril');
        if (lista_semanas.includes('Semana 05')) {
          setMet_semana('Semana 05');
        } else {
          setMet_semana('Semana 04');
        }
      } else if (met_mes === 'Junho') {
        setMet_mes('Maio');
        if (lista_semanas.includes('Semana 05')) {
          setMet_semana('Semana 05');
        } else {
          setMet_semana('Semana 04');
        }
      } else if (met_mes === 'Julho') {
        setMet_mes('Junho');
        if (lista_semanas.includes('Semana 05')) {
          setMet_semana('Semana 05');
        } else {
          setMet_semana('Semana 04');
        }
      } else if (met_mes === 'Agosto') {
        setMet_mes('Julho');
        if (lista_semanas.includes('Semana 05')) {
          setMet_semana('Semana 05');
        } else {
          setMet_semana('Semana 04');
        }
      } else if (met_mes === 'Setembro') {
        setMet_mes('Agosto');
        if (lista_semanas.includes('Semana 05')) {
          setMet_semana('Semana 05');
        } else {
          setMet_semana('Semana 04');
        }
      } else if (met_mes === 'Outubro') {
        setMet_mes('Setembro');
        if (lista_semanas.includes('Semana 05')) {
          setMet_semana('Semana 05');
        } else {
          setMet_semana('Semana 04');
        }
      } else if (met_mes === 'Novembro') {
        setMet_mes('Outubro');
        if (lista_semanas.includes('Semana 05')) {
          setMet_semana('Semana 05');
        } else {
          setMet_semana('Semana 04');
        }
      } else if (met_mes === 'Dezembro') {
        setMet_mes('Novembro');
        if (lista_semanas.includes('Semana 05')) {
          setMet_semana('Semana 05');
        } else {
          setMet_semana('Semana 04');
        }
      }
    } else if (met_semana === 'Semana 02') {
      setMet_semana('Semana 01');
    } else if (met_semana === 'Semana 03') {
      setMet_semana('Semana 02');
    } else if (met_semana === 'Semana 04') {
      setMet_semana('Semana 03');
    } else if (met_semana === 'Semana 05') {
      setMet_semana('Semana 04');
    }
  }

  //Próximo
  function proximo() {
    if (met_semana === 'Semana 01') {
      setMet_semana('Semana 02');
    } else if (met_semana === 'Semana 02') {
      setMet_semana('Semana 03');
    } else if (met_semana === 'Semana 03') {
      setMet_semana('Semana 04');
    } else if (met_semana === 'Semana 04') {
      if (lista_semanas.includes('Semana 05')) {
        setMet_semana('Semana 05');
      } else {
        if (met_mes === 'Janeiro') {
          setMet_mes('Fevereiro');
          setMet_semana('Semana 01');
        } else if (met_mes === 'Fevereiro') {
          setMet_mes('Março');
          setMet_semana('Semana 01');
        } else if (met_mes === 'Março') {
          setMet_mes('Abril');
          setMet_semana('Semana 01');
        } else if (met_mes === 'Abril') {
          setMet_mes('Maio');
          setMet_semana('Semana 01');
        } else if (met_mes === 'Maio') {
          setMet_mes('Junho');
          setMet_semana('Semana 01');
        } else if (met_mes === 'Junho') {
          setMet_mes('Julho');
          setMet_semana('Semana 01');
        } else if (met_mes === 'Julho') {
          setMet_mes('Agosto');
          setMet_semana('Semana 01');
        } else if (met_mes === 'Agosto') {
          setMet_mes('Setembro');
          setMet_semana('Semana 01');
        } else if (met_mes === 'Setembro') {
          setMet_mes('Outubro');
          setMet_semana('Semana 01');
        } else if (met_mes === 'Outubro') {
          setMet_mes('Novembro');
          setMet_semana('Semana 01');
        } else if (met_mes === 'Novembro') {
          setMet_mes('Dezembro');
          setMet_semana('Semana 01');
        } else if (met_mes === 'Dezembro') {
          if (lista_anos.includes(String(parseInt(met_ano) + 1))) {
            setMet_ano(String(parseInt(met_ano) + 1));
            setMet_mes('Janeiro');
            setMet_semana('Semana 01');
          }
        }
      }
    } else if (met_semana === 'Semana 05') {
      if (met_mes === 'Janeiro') {
        setMet_mes('Fevereiro');
        setMet_semana('Semana 01');
      } else if (met_mes === 'Fevereiro') {
        setMet_mes('Março');
        setMet_semana('Semana 01');
      } else if (met_mes === 'Março') {
        setMet_mes('Abril');
        setMet_semana('Semana 01');
      } else if (met_mes === 'Abril') {
        setMet_mes('Maio');
        setMet_semana('Semana 01');
      } else if (met_mes === 'Maio') {
        setMet_mes('Junho');
        setMet_semana('Semana 01');
      } else if (met_mes === 'Junho') {
        setMet_mes('Julho');
        setMet_semana('Semana 01');
      } else if (met_mes === 'Julho') {
        setMet_mes('Agosto');
        setMet_semana('Semana 01');
      } else if (met_mes === 'Agosto') {
        setMet_mes('Setembro');
        setMet_semana('Semana 01');
      } else if (met_mes === 'Setembro') {
        setMet_mes('Outubro');
        setMet_semana('Semana 01');
      } else if (met_mes === 'Outubro') {
        setMet_mes('Novembro');
        setMet_semana('Semana 01');
      } else if (met_mes === 'Novembro') {
        setMet_mes('Dezembro');
        setMet_semana('Semana 01');
      } else if (met_mes === 'Dezembro') {
        if (lista_anos.includes(String(parseInt(met_ano) + 1))) {
          setMet_ano(String(parseInt(met_ano) + 1));
          setMet_mes('Janeiro');
          setMet_semana('Semana 01');
        }
      }
    }
  }

  return (
    <div id="metas">
      <NavSuperior />
      <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
      <Heading as='h1' size='xl' color={'gray.600'} textAlign={'center'}>
        METAS
      </Heading>
      <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
      <Container maxW={'95%'} mb={20}>

        <Grid
          templateAreas={`"header semana"
            "nav main"
            "nav footer"`}
          gridTemplateRows={'1fr'}
          gridTemplateColumns={'150px 1fr'}
          gap='1'
        >
          <GridItem pl='2' bg='gray.100' area={'header'}>
            <Center h={10}>SETOR</Center>
          </GridItem>
          <GridItem pl='2' pr='2' bg='gray.100' area={'semana'}>
            <Flex>
              <Center w='150px' mr={1}>
                <Select textAlign={'center'} variant='flushed' borderColor={'gray'} size='sm' value={met_ano} onChange={e => setMet_ano(e.target.value)}>
                  {lista_anos.map((item) => {
                    return (
                      <option value={item}>{item}</option>
                    );
                  })}
                </Select>
              </Center>
              <Center w='200px' mr={2}>
                <Select textAlign={'center'} variant='flushed' borderColor={'gray'} size='sm' value={met_mes} onChange={e => setMet_mes(e.target.value)}>
                  <option value={'Janeiro'}>Janeiro</option>
                  <option value={'Fevereiro'}>Fevereiro</option>
                  <option value={'Março'}>Março</option>
                  <option value={'Abril'}>Abril</option>
                  <option value={'Maio'}>Maio</option>
                  <option value={'Junho'}>Junho</option>
                  <option value={'Julho'}>Julho</option>
                  <option value={'Agosto'}>Agosto</option>
                  <option value={'Setembro'}>Setembro</option>
                  <option value={'Outubro'}>Outubro</option>
                  <option value={'Novembro'}>Novembro</option>
                  <option value={'Dezembro'}>Dezembro</option>
                </Select>
              </Center>
              <Center w='80px' _hover={{ bg: 'gray.400' }} cursor={'pointer'} onClick={e => anterior()}>
                <ChevronLeftIcon boxSize={10} />
              </Center>
              <Box flex='1'>
                <SimpleGrid columns={5}>
                  {lista_semanas.map((item) => {
                    return (
                      <Button fontWeight={'normal'} borderRadius={0} variant={'unstyled'} h='10' bg={met_semana === item ? 'white' : 'gray.100'} onClick={e => setMet_semana(item)}>{item}</Button>
                    );
                  })}
                </SimpleGrid>
              </Box>
              <Center w='80px' _hover={{ bg: 'gray.400' }} cursor={'pointer'} onClick={e => proximo()}>
                <ChevronRightIcon boxSize={10} />
              </Center>
            </Flex>
          </GridItem>
          <GridItem pl='2' bg='gray.100' area={'nav'}>
            <Button fontWeight={'normal'} borderRadius={0} variant={'unstyled'} w={'100%'} h='10' textAlign={'right'} pr={5} cursor={'pointer'} bg={met_setor === 'Gestão' ? 'white' : 'gray.100'} onClick={e => setMet_setor('Gestão')}>Gestão</Button>
            <Button fontWeight={'normal'} borderRadius={0} variant={'unstyled'} w={'100%'} h='10' textAlign={'right'} pr={5} cursor={'pointer'} bg={met_setor === 'Jurídico' ? 'white' : 'gray.100'} onClick={e => setMet_setor('Jurídico')}>Jurídico</Button>
            <Button fontWeight={'normal'} borderRadius={0} variant={'unstyled'} w={'100%'} h='10' textAlign={'right'} pr={5} cursor={'pointer'} bg={met_setor === 'Financeiro' ? 'white' : 'gray.100'} onClick={e => setMet_setor('Financeiro')}>Financeiro</Button>
            <Button fontWeight={'normal'} borderRadius={0} variant={'unstyled'} w={'100%'} h='10' textAlign={'right'} pr={5} cursor={'pointer'} bg={met_setor === 'Comercial' ? 'white' : 'gray.100'} onClick={e => setMet_setor('Comercial')}>Comercial</Button>
            <Button fontWeight={'normal'} borderRadius={0} variant={'unstyled'} w={'100%'} h='10' textAlign={'right'} pr={5} cursor={'pointer'} bg={met_setor === 'Documental' ? 'white' : 'gray.100'} onClick={e => setMet_setor('Documental')}>Documental</Button>
            <Button fontWeight={'normal'} borderRadius={0} variant={'unstyled'} w={'100%'} h='10' textAlign={'right'} pr={5} cursor={'pointer'} bg={met_setor === 'Topografia' ? 'white' : 'gray.100'} onClick={e => setMet_setor('Topografia')}>Topografia</Button>
            <Button fontWeight={'normal'} borderRadius={0} variant={'unstyled'} w={'100%'} h='10' textAlign={'right'} pr={5} cursor={'pointer'} bg={met_setor === 'Projeto' ? 'white' : 'gray.100'} onClick={e => setMet_setor('Projeto')}>Projeto</Button>
            <Button fontWeight={'normal'} borderRadius={0} variant={'unstyled'} w={'100%'} h='10' textAlign={'right'} pr={5} cursor={'pointer'} bg={met_setor === 'Pós-Protocolo' ? 'white' : 'gray.100'} onClick={e => setMet_setor('Pós-Protocolo')}>Pós-Protocolo</Button>
          </GridItem>
          <GridItem pl='2' area={'main'}>
            <Text textAlign={'center'} m={2}>De 20/03/2023 a 27/03/2023</Text>
            <Box flex={1} mr={10} ml={10}>
              <Accordion allowMultiple>
                {lista_metas.map((item, i) => {
                  return (
                    <>
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box as="span" flex='1'>
                              <Flex>
                                <Center w='150px'>
                                  <Tag colorScheme='yellow' display={'none'}>Pendente</Tag>
                                  <Tag colorScheme='cyan' >Em Andamento</Tag>
                                  <Tag colorScheme='pink' display={'none'}>Aguardando</Tag>
                                  <Tag colorScheme='green' display={'none'}>Concluído</Tag>
                                </Center>
                                <Box flex='1'>
                                  <Text textAlign={'left'}>{item.meta}</Text>
                                </Box>
                              </Flex>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel mr={4} ml={4} mb={4}>
                          <Flex>
                            <Box flex='1'>
                              <Flex>
                                <Box w={'400px'}>
                                  <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Responsável</Text>
                                    <Select variant='flushed' borderColor={'gray'} size='sm' value={usu_responsavel} onChange={e => setUsu_responsavel(e.target.value)}>
                                      <option id={0} value={0}></option>
                                      {lista_usuarios.map((item, i) => {
                                        return (
                                          <option id={i + 1} value={item.id}>{item.nome}</option>
                                        );
                                      })}
                                    </Select>
                                  </Stack>
                                </Box>
                                <Box flex='1'>
                                  <Stack spacing={3} direction='row' ml={4}>
                                    <Text whiteSpace='nowrap'>Proponente</Text>
                                    <Text whiteSpace='nowrap' flex='1' border={'gray.200'} borderBottomWidth={1}>Guilherme Censi</Text>
                                  </Stack>
                                </Box>
                              </Flex>
                              <Box flex='1' border={'gray.200'} borderWidth={1} mt={4}>
                                <Text p={3}>{item.descricao}</Text>
                              </Box>
                            </Box>
                            <Center w='100px' alignItems={'end'} mb={3}>
                              <Stack spacing={3} direction='column'>
                                <Button colorScheme="teal" variant={'outline'}><DeleteIcon /></Button>
                                <Button colorScheme="teal" variant={'outline'}><EditIcon /></Button></Stack>
                            </Center>
                          </Flex>
                          <Flex>
                            <Box flex='1' p={3}>
                              <Text w={'100%'}>{item.comentario}</Text>
                            </Box>
                            <Center w='100px' alignItems={'end'} mb={3}>
                              <Stack spacing={3} direction='column'>
                                <Button colorScheme="teal" variant={'outline'}><EditIcon /></Button></Stack>
                            </Center>
                          </Flex>
                        </AccordionPanel>
                      </AccordionItem>
                    </>
                  );
                })}
              </Accordion>
              <Button colorScheme='gray' variant={'ghost'} w={'100%'} mt={3} onClick={onNovaOpen}><PlusSquareIcon mr={5} />Adicionar Meta</Button>
            </Box>
          </GridItem >

          <GridItem pl='2' area={'footer'} h='10' bg={'gray'}>
            metas atrasadas
          </GridItem>
        </Grid>

      </Container>

      {/*MODAL NOVA META*/}
      <Modal isOpen={isNovaOpen} onClose={onNovaClose} isCentered size='6xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center'>ADICIONAR META</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box p={8}>
              <Stack spacing={3} direction='row'><Text>Meta</Text><Input autoComplete="no" borderColor={'gray'} size='sm' value={met_meta} onChange={e => setMet_meta(e.target.value)} /></Stack>
              <Box pl={12} mt={4} mb={4}>
                <Textarea borderColor={'gray'} bg={'white'}
                  p={3}
                  borderRadius={0}
                  minH="unset"
                  overflow="hidden"
                  resize="none"
                  minRows={3}
                  as={ResizeTextarea}
                  placeholder="Descrição da meta..."
                  value={met_descricao}
                  onChange={e => setMet_descricao(e.target.value)}
                  textAlign={'justify'}
                />
              </Box>

              <SimpleGrid columns={2} >
                <Stack spacing={3} direction='row' w={'90%'}><Text whiteSpace='nowrap'>Setor</Text>
                  <Select borderColor={'gray'} size='sm' value={met_setor} onChange={e => setMet_setor(e.target.value)}>
                    <option value={''}>-</option>
                    <option value={'Gestão'}>Gestão</option>
                    <option value={'Jurídico'}>Jurídico</option>
                    <option value={'Financeiro'}>Financeiro</option>
                    <option value={'Comercial'}>Comercial</option>
                    <option value={'Documental'}>Documental</option>
                    <option value={'Topografia'}>Topografia</option>
                    <option value={'Projeto'}>Projeto</option>
                    <option value={'Pós-Protocolo'}>Pós-Protocolo</option>
                  </Select>
                </Stack>
                <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Responsável</Text>
                  <Select borderColor={'gray'} size='sm' value={usu_responsavel} onChange={e => setUsu_responsavel(e.target.value)}>
                    <option value={''}>-</option>
                    <option value={'Gestão'}>Guilherme</option>
                    <option value={'Jurídico'}>Adangelo</option>
                  </Select>
                </Stack>
              </SimpleGrid>
            </Box>

          </ModalBody>
          <ModalFooter>
            <Spacer />
            <Button colorScheme="teal" variant={'outline'} onClick={e => salvarMeta()}>Concluir <SaveIcon ml={2} /></Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </div>
  );
}

export default MetasPage;