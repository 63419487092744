import { createIcon } from '@chakra-ui/icons'

// using `path`
export const SaveIcon = createIcon({
	displayName: 'SaveIcon',
	viewBox: '0 0 24 24',
	// path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
	path: [
		<path
			fill='none'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
			d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"
		/>,
		<polyline
			fill='none'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
			points="17 21 17 13 7 13 7 21"
		/>,
		<polyline
			fill='none'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
			points="7 3 7 8 15 8"
		/>
	]
});