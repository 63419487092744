import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { api } from "../../../services/api";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function SigiloPDF(unid_id, setMsg_imprimir) {

	//Variáveis
	const data = { unid_id };

	var titulo;
	var identificacao;
	var codigo;
	var texto;
	var local_data;
	var assinatura;
	var timbrado;
	var erro;
	var mensagem;

	//Pegar dados
	pegarDados(data);
	async function pegarDados(data) {
		await api.post('/processo/SigiloPDF.php', JSON.stringify(data)).then(rp => {
			titulo = rp.data.titulo;
			codigo = rp.data.codigo;
			identificacao = rp.data.identificacao;
			texto = rp.data.texto;
			local_data = rp.data.local_data;
			assinatura = rp.data.assinatura;
			timbrado = rp.data.timbrado;
			erro = rp.data.erro;
			mensagem = rp.data.mensagem;
		});
		gerarPDF();
	}

	//Gerar PDF
	function gerarPDF() {

		if (erro === false) {

			//Conteúdo do documento
			const conteudo = [
				{
					text: titulo + '\n\n', style: 'titulo'
				},
				{
					text: identificacao + '\n\n', style: 'identificacao'
				},
				texto.map((item) => {
					return [
						{ text: item.titulo, style: 'clausula' },
						{ text: item.corpo + '\n\n', style: 'corpo' }
					]
				}),
				{
					text: local_data + '\n\n', style: 'data'
				},
				assinatura.map((item) => {
					return [{ text: item, style: 'assinatura' },]
				})
			];

			//Rodapé
			const rodape = function (currentPage, pageCount) { return ({ text: '\nPágina ' + currentPage.toString() + ' de ' + pageCount, style: 'rodape' }); };

			//Estilos
			const estilos = {
				titulo: {
					fontSize: 12,
					alignment: 'center',
					bold: true
				},
				identificacao: {
					fontSize: 10,
					alignment: 'right',
					bold: false
				},
				clausula: {
					fontSize: 10,
					alignment: 'justify',
					bold: true
				},
				corpo: {
					fontSize: 10,
					alignment: 'justify'
				},
				data: {
					fontSize: 10,
					alignment: 'right'
				},
				assinatura: {
					fontSize: 10,
					alignment: 'center'
				},
				rodape: {
					fontSize: 10,
					alignment: 'center'
				}
			};

			//Imagem de fundo
			var imagem;
			if (timbrado !== "") {
				imagem = { image: timbrado, width: 610 };
			} else {
				imagem = "";
			}

			//Definições
			var docDefinition = {
				pageSize: 'A4',
				pageMargins: [70, 80, 50, 70],
				info: { title: 'Termo de Sigilo ' + codigo },
				background: imagem,
				content: conteudo,
				styles: estilos,
				footer: rodape
			};

			pdfMake.createPdf(docDefinition).open();
			//pdfMake.createPdf(docDefinition).download();    
			//pdfMake.createPdf(docDefinition).open({}, window);

		} else {
			setMsg_imprimir(mensagem.replaceAll(";", "; "));
		}
	}

}

export default SigiloPDF;