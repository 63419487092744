import { createIcon } from '@chakra-ui/icons'

// using `path`
export const NavigateIcon = createIcon({
    displayName: 'NavigateIcon',
    viewBox: '0 0 24 24',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <polyline
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            points="3 11 22 2 13 21 11 13 3 11"
        />
    )
});