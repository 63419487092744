import React, { useRef } from "react";
import { useContext, useState, useEffect, useCallback } from "react";
import { useDropzone } from 'react-dropzone';
import { Text, Button, Flex, Spacer, Select, TableContainer, Table, Thead, Tr, Th, Td, Tbody, Container, ButtonGroup, Heading, Tabs, TabList, Tab, TabPanels, TabPanel, Center, Input, Box, Stack, ListItem } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { CheckIcon, ChevronLeftIcon, CloseIcon } from "@chakra-ui/icons";
import ExportExcel from "../../components/Excel/excelexport";
import NavSuperior from "../../components/NavBar/navsuperior";
import DadosRemessa from "../../components/Remessa/dadosremessa";

import { api } from "../../../services/api";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";
import { UploadIcon } from "../../icons/uploadicon";

const TopografiaPage = () => {
	//Variáveis do Contexto
	const { logout } = useContext(AuthContext);
	const { rem_id, rem_municipio, rem_remessa, setUnid_id } = useContext(ReurbContext);

	//Navegação
	const navigate = useNavigate();

	//Variáveis
	const [controle, setControle] = useState(false);
	const [qtd_processos, setQtd_processos] = useState(0);
	const [lista_topografia, setLista_topografia] = useState([{ unid_id: 0, unid_cod: '-', nui_id: 0, nui_cod: '-', beneficiarios: '-', contato: '-', localizacao: '-', objeto: '-', posse: '-', area: '-', medicao: 0, lepac: 0, conferencia: 0, situacao: '-' }]);
	const [excel_topografia, setExcel_topografia] = useState([{ CodigoProcesso: '-', CodigoNUI: '-', Beneficiarios: '-', Contato: '-', Localizacao: '-', Objeto: '-', Posse: '-', AreaPosse: '-', Situacao: '-' }]);
	const [lista_nucleo, setLista_nucleo] = useState([{ nui_id: 0, nui_cod: '-', nui_nome: '-' }]);
	const [lista_situacao, setLista_situacao] = useState([{ sit_id: 0, situacao: '-' }]);
	const [vetor_selecionados, setVetor_selecionados] = useState([]);
	var arquivo;

	//Filtros
	const [nucleo_selecionado, setNucleo_selecionado] = useState(0);
	const [situacao_selecionada, setSituacao_selecionada] = useState(0);

	//Ler dados
	useEffect(() => {
		const data = {
			rem_id,
			nucleo_selecionado,
			situacao_selecionada
		}
		//Lista topografia
		api.post('/topografia/LerTopografia.php', JSON.stringify(data))
			.then(function (rp) {
				setQtd_processos(rp.data.qtd_processos);
				setLista_topografia(rp.data.lista_topografia);
				setExcel_topografia(rp.data.excel_topografia);
				setLista_nucleo(rp.data.lista_nucleo);
				setLista_situacao(rp.data.lista_situacao);
			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				} else {
					console.log(error);
				}
			});
	}, [nucleo_selecionado, situacao_selecionada, controle]);

	//Dropzone
	function Basic(props) {
		const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

		const files = acceptedFiles.map(file => (
			<li key={file.path} style={{ listStyle: 'none' }}>
				{file.path} - {file.size} bytes
			</li>
		));

		arquivo = files[0];

		return (
			<section className="container">
				<div {...getRootProps({ className: 'dropzone' })}>
					<Center w={'500px'} h={'150px'} border={'2px dashed gray'} cursor='pointer' _hover={{ border: '2px dashed black' }} display={arquivo === undefined ? 'flex' : 'none'}>
						<Input {...getInputProps()} />
						<Stack dir="row" alignItems={'center'}>
							<Text>Arraste um arquivo ou clique para selecionar</Text>
							<UploadIcon boxSize={10} color={'gray.500'} />
						</Stack>
					</Center>
				</div>
				<div>
					<Center w={'500px'} h={'150px'} border={'2px dashed gray'} display={arquivo === undefined ? 'none' : 'flex'}>
						<Stack dir="row" align={'center'}>
						<Text>{arquivo}</Text>
						<ButtonGroup spacing={3}>
						<Button variant={'outline'} colorScheme='red' w={'100px'}>Descartar</Button>
							<Button variant={'outline'} colorScheme='teal' w={'100px'}>Enviar</Button>							
						</ButtonGroup>
						</Stack>
					</Center>
				</div>
			</section>
		);
	}

	//Selecionar processo
	function selecionarProcesso(id) {
		if (id > 0) {
			//Se o vetor inclui o id, remover
			if (vetor_selecionados.indexOf(id) > -1) {
				const indice = vetor_selecionados.indexOf(id);
				const temp = [...vetor_selecionados];
				temp.splice(indice, 1);
				setVetor_selecionados(temp);

				//Se o vetor não inclui o id, adicionar
			} else {
				setVetor_selecionados(vetor_selecionados => [...vetor_selecionados, id]);
			}
		}
	}

	//Selecionar todos
	function selecionarTodos() {
		const temp = [];
		lista_topografia.map((item, i) => {
			temp.push(item.unid_id);
		});
		setVetor_selecionados(temp);
	}

	//Salvar medição
	async function salvarMedicao() {
		if (vetor_selecionados.length > 0) {
			const data = {
				rem_id,
				vetor_selecionados
			}
			await api.post('/topografia/SalvarMedicao.php', JSON.stringify(data))
				.then(function (rp) {
					setControle(!controle);
				})
				.catch(function (error) {
					if (error.response.data === "EXPIRED") {
						logout();
					} else {
						console.log(error);
					}
				});
		}
	}

	//Salvar LEPAC
	async function salvarLepac() {
		if (vetor_selecionados.length > 0) {
			const data = {
				rem_id,
				vetor_selecionados
			}
			await api.post('/topografia/SalvarLepac.php', JSON.stringify(data))
				.then(function (rp) {
					setControle(!controle);
				})
				.catch(function (error) {
					if (error.response.data === "EXPIRED") {
						logout();
					} else {
						console.log(error);
					}
				});
		}
	}

	//Salvar conferência
	async function salvarConferencia() {
		if (vetor_selecionados.length > 0) {
			const data = {
				rem_id,
				vetor_selecionados
			}
			await api.post('/topografia/SalvarConferencia.php', JSON.stringify(data))
				.then(function (rp) {
					setControle(!controle);
				})
				.catch(function (error) {
					if (error.response.data === "EXPIRED") {
						logout();
					} else {
						console.log(error);
					}
				});
		}
	}

	//Limpar seleção
	function limparSelecao() {
		setVetor_selecionados([]);
	}

	//Ir para remessa
	function goToRemessa() {
		navigate('/remessa');
	}

	//Ir para processo
	function goToProcesso() {
		navigate('/processo');
	}

	return (
		<div id="topografia">
			<NavSuperior />
			<DadosRemessa titulo="Análise Topografia" />

			<Container maxW='95%' bg='white' mb={20}>
				<Flex direction='row' mb={5}>
					<Button colorScheme="teal" w={'5%'} rounded={'none'} h={'58px'} onClick={e => goToRemessa()}><ChevronLeftIcon boxSize={8} /></Button>
					<Flex background={'white'} border={'1px'} p={2} w={'95%'}>
						<Heading size={'md'} color={'gray.700'} mt={2} ml={3}>Processos Cadastrados ({qtd_processos})</Heading>
						<Spacer />
						<Text mr={2} pt={2}>Núcleo</Text>
						<Select borderColor={'teal'} size='sm' w='200px' pt={1} mr={8} value={nucleo_selecionado} onChange={e => { setNucleo_selecionado(e.target.value) }}>
							<option key={0} value={0}>{'-'}</option>
							{lista_nucleo.map((item, i) => {
								return (<option key={i + 1} value={item.nui_id}>{item.nui_cod}</option>);
							})}
						</Select>
						<Text mr={2} pt={2}>Situação</Text>
						<Select borderColor={'teal'} size='sm' w='200px' pt={1} mr={8} value={situacao_selecionada} onChange={e => { setSituacao_selecionada(e.target.value) }}>
							<option key={0} value={0}>{'-'}</option>
							{lista_situacao.map((item, i) => {
								return (<option key={i + 1} value={item.sit_id}>{item.situacao}</option>);
							})}
						</Select>
						<ButtonGroup gap='4' ml={5}>
							<ExportExcel excelData={excel_topografia} fileName={"Dados Topografia"} />
						</ButtonGroup>
					</Flex>
				</Flex>

				<Tabs isFitted>
					<TabList backgroundColor={'gray.200'} h={'50px'}>
						<Tab className="dados" _selected={{ color: 'white', bg: 'gray' }}><Heading as='h5' size='sm'>DADOS</Heading></Tab>
						{/*<Tab className="medicao" _selected={{ color: 'white', bg: 'gray' }}><Heading as='h5' size='sm'>MEDIÇÃO</Heading></Tab>*/}
						<Tab className="lepac" _selected={{ color: 'white', bg: 'gray' }}><Heading as='h5' size='sm'>CONTROLE LEPAC</Heading></Tab>
						<Tab className="area" _selected={{ color: 'white', bg: 'gray' }}><Heading as='h5' size='sm'>ANÁLISE DE ÁREA</Heading></Tab>
					</TabList>
					<TabPanels>
						<TabPanel className="dados">
							<TableContainer mt={5}>
								<Table size='sm'>
									<Thead>
										<Tr>
											<Th textAlign={'center'} pl='1px' pr='1px'>NUI</Th>
											<Th textAlign={'center'} pl='1px' pr='1px'>CÓDIGO</Th>
											<Th textAlign={'center'} pl='1px' pr='1px'>BENEFICIÁRIOS</Th>
											<Th textAlign={'center'} pl='1px' pr='1px'>CONTATO</Th>
											<Th textAlign={'center'} pl='1px' pr='1px'>LOCAL</Th>
											<Th textAlign={'center'} pl='1px' pr='1px'>TIPO</Th>
											<Th textAlign={'center'} pl='1px' pr='1px'>COMPROVANTE DE POSSE</Th>
											<Th textAlign={'center'} pl='1px' pr='1px'>ÁREA (m²)</Th>
											<Th textAlign={'center'} pl='1px' pr='1px'>SITUAÇÃO</Th>
										</Tr>
									</Thead>
									<Tbody>
										{lista_topografia.map((item, i) => {
											return (
												<Tr key={i} cursor='pointer' _hover={{ bg: 'gray.200' }} onClick={e => { setUnid_id(item.unid_id); goToProcesso(); }}>
													<Td textAlign={'center'}>{item.nui_cod}</Td>
													<Td textAlign={'center'}>{item.unid_cod}</Td>
													<Td whiteSpace={'pre-wrap'} maxW={'20vw'}>{item.beneficiarios}</Td>
													<Td whiteSpace={'pre-wrap'} maxW={'10vw'} textAlign={'center'}>{item.contato}</Td>
													<Td whiteSpace={'pre-wrap'} maxW={'20vw'}>{item.localizacao}</Td>
													<Td whiteSpace={'pre-wrap'} maxW={'10vw'} textAlign={'center'}>{item.objeto}</Td>
													<Td whiteSpace={'pre-wrap'} maxW={'20vw'} textAlign={'center'}>{item.posse}</Td>
													<Td textAlign={'center'}>{item.area}</Td>
													<Td textAlign={'center'}>{item.situacao}</Td>
												</Tr>
											);
										})}
									</Tbody>
								</Table>
							</TableContainer>
						</TabPanel>
						{/*<TabPanel className="medicao">
							<Center mt={10}>
								<Basic />

							</Center>
									</TabPanel>*/}
						<TabPanel className="lepac">
							<Flex direction={'row'}>
								<Flex minW={'250px'}>
									<Text>{vetor_selecionados.length} Processos Selecionados</Text>
								</Flex>
								<ButtonGroup size={'sm'} variant={'outline'}>
									<Button onClick={e => selecionarTodos()}>Selecionar Todos</Button>
									<Button onClick={e => limparSelecao()}>Limpar Seleção</Button>
								</ButtonGroup>
								<Spacer />
								<ButtonGroup size={'sm'} variant={'outline'} colorScheme='yellow'>
									<Button onClick={e => salvarMedicao()}>Medição Feito / Não Feito</Button>
									<Button onClick={e => salvarLepac()}>LEPAC Feito / Não Feito</Button>
									<Button onClick={e => salvarConferencia()}>Conferência Feito / Não Feito</Button>
								</ButtonGroup>
							</Flex>
							<TableContainer mt={5}>
								<Table size='sm'>
									<Thead>
										<Tr>
											<Th textAlign={'center'} pl='1px' pr='1px'>NUI</Th>
											<Th textAlign={'center'} pl='1px' pr='1px'>CÓDIGO</Th>
											<Th textAlign={'center'} pl='1px' pr='1px'>BENEFICIÁRIOS</Th>
											<Th textAlign={'center'} pl='1px' pr='1px'>MEDIÇÃO</Th>
											<Th textAlign={'center'} pl='1px' pr='1px'>LEPAC</Th>
											<Th textAlign={'center'} pl='1px' pr='1px'>CONFERÊNCIA</Th>
											<Th textAlign={'center'} pl='1px' pr='1px'>SITUAÇÃO</Th>
										</Tr>
									</Thead>
									<Tbody>
										{lista_topografia.map((item, i) => {
											return (
												<Tr key={i} cursor='pointer' onClick={e => selecionarProcesso(item.unid_id)} bg={vetor_selecionados.includes(item.unid_id) ? 'yellow.100' : 'white'}>
													<Td textAlign={'center'}>{item.nui_cod}</Td>
													<Td textAlign={'center'}>{item.unid_cod}</Td>
													<Td whiteSpace={'pre-wrap'} maxW={'500px'}>{item.beneficiarios}</Td>
													<Td textAlign={'center'}>
														<CheckIcon display={item.medicao === 1 ? 'inline' : 'none'} color={'green.500'} />
														<CloseIcon display={item.medicao === 0 ? 'inline' : 'none'} color={'red'} />
													</Td>
													<Td textAlign={'center'}>
														<CheckIcon display={item.lepac === 1 ? 'inline' : 'none'} color={'green.500'} />
														<CloseIcon display={item.lepac === 0 ? 'inline' : 'none'} color={'red'} />
													</Td>
													<Td textAlign={'center'}>
														<CheckIcon display={item.conferencia === 1 ? 'inline' : 'none'} color={'green.500'} />
														<CloseIcon display={item.conferencia === 0 ? 'inline' : 'none'} color={'red'} />
													</Td>
													<Td textAlign={'center'}>{item.situacao}</Td>
												</Tr>
											);
										})}
									</Tbody>
								</Table>
							</TableContainer>
						</TabPanel>
						<TabPanel className="area">
							<Center mt={20}>
								<Heading size={'lg'} textAlign={'center'} color={'gray.400'}>Em Breve (?)</Heading>
							</Center>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Container>
		</div>
	);
}

export default TopografiaPage;