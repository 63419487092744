import { createIcon } from '@chakra-ui/icons'

// using `path`
export const AlertIcon = createIcon({
	displayName: 'AlertIcon',
	viewBox: '0 0 24 24',
	// path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
	path: [
		<path
			fill='none'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
			d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
		/>,
		<polyline
			fill='none'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
			points="12 9 12 13"
		/>,
		<polyline
			fill='none'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
			points="12 17 12.01 17"
		/>
	]
});