import React from "react";
import { useContext, useState, useEffect } from "react";

import NavSuperior from "../../components/NavBar/navsuperior";
import DadosRemessa from "../../components/Remessa/dadosremessa";

import { ReurbContext } from "../../contexts/reurb";

import ExibirRequerente from "./exibirrequerente";
import EditarRequerente from "./editarrequerente";

const RequerentePage = () => {
    const { editar_requerente, setEditar_requerente } = useContext(ReurbContext);

    const [loaded, setLoaded] = useState(0);

    //Ler dados
    useEffect(() => {
        setEditar_requerente(0);
        setLoaded(1);
    }, []);

    function renderizar() {
        if (loaded === 1) {
            if (editar_requerente === 0) {
                return (
                    <ExibirRequerente />
                );
            }
            else {
                return (
                    <EditarRequerente />
                );
            }
        } else {
            return null;
        }
    }

    return (
        <div id="requerente">
            <NavSuperior />
            <DadosRemessa titulo="Painel do Requerente" />
            {renderizar()}
        </div>
    );
}

export default RequerentePage;