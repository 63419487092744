import React from "react";
import { useContext, useState, useEffect } from "react";
import { Button, Box, TableContainer, Table, Thead, Tr, Th, Td, Tbody, Divider, Heading, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";

import { api } from "../../../services/api";

import NavSuperior from "../../components/NavBar/navsuperior";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

const PesquisarPage = () => {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  const { setRem_id, setUnid_id, setRem_municipio, setRem_remessa, pesquisar, setPesquisar, botao_pesquisar } = useContext(ReurbContext);
  

  //Variáveis Chakra UI
  const { isOpen: isPesquisarOpen, onOpen: onPesquisarOpen, onClose: onPesquisarClose } = useDisclosure();
  const cancelPesquisarRef = React.useRef();

  //Variáveis    
  const [lista_requerentes, setLista_requerentes] = useState([{ rem_id: 0, rem_municipio: '-', rem_remessa: '-', rem_uf: '-', unid_id: 0, unid_cod: '-', req_nome: '-', conj_nome: '-', nucleo: '-', reurb_situacao: '-' }]);

  //Ler dados
  useEffect(() => {
    setPesquisar(pesquisar.trim());
    if (pesquisar.length>2) {
      const data = {
        pesquisar
      };
      api.post('/pesquisar/LerPesquisar.php', JSON.stringify(data))
      .then(function (rp) {
				setLista_requerentes(rp.data.lista_requerentes);
			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				}else{
          console.log(error);
        }
			});
    }else{
      onPesquisarOpen();
    }
  }, [botao_pesquisar]);

  //Ir para processo
  function goToProcesso(id_remessa, id_processo, municipio, remessa) {
    setRem_id(id_remessa);
    setUnid_id(id_processo);
    setRem_municipio(municipio);
    setRem_remessa(remessa);
    if (id_remessa !== 0 && id_processo !== 0) {
      navigate('/processo');
    }
  }

  return (
    <div id="pesquisar">
      <NavSuperior />
      <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
      <Heading as='h1' size='xl' color={'gray.600'} textAlign={'center'}>
        RESULTADO DA PESQUISA
      </Heading>
      <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
      <Box>
        <TableContainer m={5}>
          <Table size='sm'>
            <Thead>
              <Tr>
                <Th textAlign={'center'}>PROCESSO</Th>
                <Th textAlign={'center'}>REQUERENTE</Th>
                <Th textAlign={'center'}>CÔNJUGE</Th>
                <Th textAlign={'center'}>REMESSA</Th>
                <Th textAlign={'center'}>UF</Th>
                <Th textAlign={'center'}>NÚCLEO</Th>
                <Th textAlign={'center'}>SITUAÇÃO</Th>
              </Tr>
            </Thead>
            <Tbody>
              {lista_requerentes.map((item, i) => {
                return (
                  <Tr key={i} cursor='pointer' _hover={{ bg: 'gray.200' }} onClick={e => goToProcesso(item.rem_id, item.unid_id, item.rem_municipio, item.rem_remessa)} >
                    <Td textAlign={'center'}>{item.unid_cod}</Td>
                    <Td>{item.req_nome}</Td>
                    <Td>{item.conj_nome}</Td>
                    <Td textAlign={'center'}>{item.rem_municipio + ' ' + item.rem_remessa}</Td>
                    <Td textAlign={'center'}>{item.rem_uf}</Td>
                    <Td textAlign={'center'}>{item.nucleo}</Td>
                    <Td textAlign={'center'}>{item.reurb_situacao}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      {/*Alert caracteres mínimos pesquisar*/}
      <AlertDialog
          isOpen={isPesquisarOpen}
          leastDestructiveRef={cancelPesquisarRef}
          onClose={onPesquisarClose}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Buscar Requerente
              </AlertDialogHeader>
              <AlertDialogBody>
                Digite três caracteres ou mais para realizar a pesquisa
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelPesquisarRef} onClick={onPesquisarClose}>
                  OK
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
    </div>
  );
}

export default PesquisarPage;