import React from "react";
import { useContext, useState, useEffect } from "react";
import { Image, Center, Box, Container, Heading } from '@chakra-ui/react';
import NavSuperior from "../../components/NavBar/navsuperior";

import { MapContainer, Polygon, TileLayer, Tooltip } from "react-leaflet";

const OficiosPage = () => {

  const jakarta = [
    [ -48.432977450366153, -27.579840783659751 ], [ -48.432593646071552, -27.580306547915782 ], [ -48.431813158143626, -27.579806536209929 ], [ -48.432166052025238, -27.57931108857289 ], [ -48.432977450366153, -27.579840783659751 ] 
  ];

  const [coordinates, setCoordinates] = useState([]);

  useEffect(() => {
    setCoordinates(jakarta.map((row) => [row[1], row[0]]));
  }, []);

  return (
    <div id="oficios">
      <NavSuperior />
      <Center>


        {/*<Container maxH={'400px'} maxW={'400px'} mt={"100px"}>
          <Image src={'https://www.unirio.br/propg/diretoria-de-inovacao-tecnologica-cultural-e-social/imagens/em-construcao/image'} />
          <Heading textAlign={'center'}>OFÍCIOS</Heading>
  </Container>*/}
      </Center>
      <div style={{ width: 30 + "vw", height: 30 + "vh" }}>
        {coordinates.length && (
          
        
      <MapContainer
            style={{ width: 100 + "%", height: 100 + "%" }}
            bounds={coordinates}
            boundsOptions={{ padding: [1, 1] }}
            scrollWheelZoom={true}
          >
            <TileLayer
              url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
            />
            <Polygon positions={coordinates}>
            <Tooltip direction="center" background-color="transparent" permanent>
        AGM01
      </Tooltip>
            </Polygon>
          </MapContainer>
)}
</div>
      

    </div>
  );
}

export default OficiosPage;