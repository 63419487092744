import React, { useRef } from "react";
import { useContext, useState, useEffect } from "react";
import { Text, Button, Flex, Spacer, Select, TableContainer, Table, Thead, Tr, Th, Td, Tbody, Container, ButtonGroup, Heading } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import ExportExcel from "../../components/Excel/excelexport";
import { ChevronLeftIcon, DownloadIcon } from "@chakra-ui/icons";

import NavSuperior from "../../components/NavBar/navsuperior";
import DadosRemessa from "../../components/Remessa/dadosremessa";

import { api } from "../../../services/api";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

const FinanceiroPage = () => {
  //Variáveis do Contexto
  const { logout } = useContext(AuthContext);
  const { rem_id, rem_municipio, rem_remessa, setUnid_id } = useContext(ReurbContext);

  //Navegação
  const navigate = useNavigate();

  //Variáveis
  const [qtd_processos, setQtd_processos] = useState(0);
  const [lista_financeiro, setLista_financeiro] = useState([{ unid_id: 0, unid_cod: '-', requerente: '-', req_cpfcnpj: '-', conjuge: '-', conj_cpf: '-', representante: '-', cep: '-', bairro: '-', cidade: '-', estado: '-', endereco: '-', numero: '-', contato: '-', situacao: '-' }]);
  const [excel_financeiro, setExcel_financeiro] = useState([{ CodigoProcesso: '-', Requerente: '-', Requerente_CPF_CNPJ: '-', Conjuge: '-', Conjuge_CPF: '-', Representante: '-', CEP: '-', Bairro: '-', Cidade: '-', Estado: '-', Endereco: '-', Numero: '-', Contato: '-', Situacao: '-' }]);

  //Ler dados
  useEffect(() => {
    const data = {
      rem_id
    }
    //Lista topografia
    api.post('/financeiro/LerFinanceiro.php', JSON.stringify(data))
      .then(function (rp) {
        setQtd_processos(rp.data.qtd_processos);
        setLista_financeiro(rp.data.lista_financeiro);
        setExcel_financeiro(rp.data.excel_financeiro);
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }, []);

  //Ir para remessa
  function goToRemessa() {
    navigate('/remessa');
  }

  //Ir para processo
  function goToProcesso(id) {
    if (id > 0) {
      setUnid_id(id);
      navigate('/processo');
    }
  }

  return (
    <div id="financeiro">
      <NavSuperior />
      <DadosRemessa titulo="Controle Financeiro" />

      <Container maxW='95%' bg='white' mb={20}>
        <Flex direction='row' mb={5}>
          <Button colorScheme="teal" w={'5%'} rounded={'none'} h={'58px'} onClick={e => goToRemessa()}><ChevronLeftIcon boxSize={8} /></Button>
          <Flex background={'white'} border={'1px'} p={2} w={'95%'}>
            <Heading size={'md'} color={'gray.700'} mt={2} ml={3}>Processos Cadastrados ({qtd_processos})</Heading>
            <Spacer />
            <ButtonGroup gap='4' ml={5}>
              <ExportExcel excelData={excel_financeiro} fileName={"Dados Financeiro"} />
            </ButtonGroup>
          </Flex>
        </Flex>

        <TableContainer mt={5}>
          <Table size='sm'>
            <Thead>
              <Tr>
                <Th textAlign={'center'} pl='1px' pr='1px'>CÓDIGO</Th>
                <Th textAlign={'center'} pl='1px' pr='1px'>REQUERENTE</Th>
                <Th textAlign={'center'} pl='1px' pr='1px'>CPF/CNPJ</Th>
                <Th textAlign={'center'} pl='1px' pr='1px'>CÔNJUGE</Th>
                <Th textAlign={'center'} pl='1px' pr='1px'>CPF</Th>
                <Th textAlign={'center'} pl='1px' pr='1px'>REPRESENTAÇÃO</Th>
                <Th textAlign={'center'} pl='1px' pr='1px'>CEP</Th>
                <Th textAlign={'center'} pl='1px' pr='1px'>BAIRRO</Th>
                <Th textAlign={'center'} pl='1px' pr='1px'>CIDADE</Th>
                <Th textAlign={'center'} pl='1px' pr='1px'>ESTADO</Th>
                <Th textAlign={'center'} pl='1px' pr='1px'>ENDEREÇO</Th>
                <Th textAlign={'center'} pl='1px' pr='1px'>NÚMERO</Th>
                <Th textAlign={'center'} pl='1px' pr='1px'>CONTATO</Th>
                <Th textAlign={'center'} pl='1px' pr='1px' display={'none'}>SITUAÇÃO</Th>
              </Tr>
            </Thead>
            <Tbody>
              {lista_financeiro.map((item, i) => {
                return (
                  <Tr key={i} cursor='pointer' _hover={{ bg: 'gray.200' }} onClick={e => { goToProcesso(item.unid_id); }} bg={item.situacao === 'Cancelado' ? 'red.100' : 'white'}>
                    <Td textAlign={'center'}>{item.unid_cod}</Td>
                    <Td whiteSpace={'pre-wrap'} maxW={'20vw'}>{item.requerente}</Td>
                    <Td textAlign={'center'}>{item.req_cpfcnpj}</Td>
                    <Td whiteSpace={'pre-wrap'} maxW={'20vw'}>{item.conjuge}</Td>
                    <Td textAlign={'center'}>{item.conj_cpf}</Td>
                    <Td whiteSpace={'pre-wrap'} maxW={'20vw'}>{item.representante}</Td>
                    <Td textAlign={'center'}>{item.cep}</Td>
                    <Td whiteSpace={'pre-wrap'} maxW={'10vw'}>{item.bairro}</Td>
                    <Td whiteSpace={'pre-wrap'} maxW={'10vw'}>{item.cidade}</Td>
                    <Td textAlign={'center'}>{item.estado}</Td>
                    <Td whiteSpace={'pre-wrap'} maxW={'10vw'}>{item.endereco}</Td>
                    <Td textAlign={'center'}>{item.numero}</Td>
                    <Td whiteSpace={'pre-wrap'} maxW={'10vw'} textAlign={'center'}>{item.contato}</Td>
                    <Td textAlign={'center'} display={'none'}>{item.situacao}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>

      </Container>
    </div>
  );
}

export default FinanceiroPage;