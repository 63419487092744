import React from "react";
import ResizeTextarea from "react-textarea-autosize";
import { useContext, useState, useEffect } from "react";
import { Grid, GridItem, Text, Input, Button, Select, Stack, Checkbox, Box, Heading, Flex, SimpleGrid, Divider, useToast, useDisclosure, Textarea, Center, Container, Spacer, Collapse } from '@chakra-ui/react';
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { SaveIcon } from "../../icons/saveicon";

import ReactInputMask from "react-input-mask";

import { api } from "../../../services/api";
import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

const EditarNucleo = () => {

	//Contexto
	const { logout } = useContext(AuthContext);
	const { rem_id, nui_id, setEditar_nucleo } = useContext(ReurbContext);

	//Variáveis Chakra UI
	const toast = useToast();
	const { isOpen: isMatriculaOpen, onToggle: onMatriculaToggle } = useDisclosure();
	const { isOpen: isAppOpen, onToggle: onAppToggle } = useDisclosure();
	const { isOpen: isRiscoOpen, onToggle: onRiscoToggle } = useDisclosure();

	//Variáveis núcleo
	const [nui_cod, setNui_cod] = useState("");
	const [nui_nome, setNui_nome] = useState("");

	const [responsavel, setResponsavel] = useState(0);
	const [nui_modalidade, setNui_modalidade] = useState("");

	const [terreno, setTerreno] = useState(0);
	const [condominio_simples, setCondominio_simples] = useState(0);
	const [condominio_lotes, setCondominio_lotes] = useState(0);
	const [condominio_edilicio, setCondominio_edilicio] = useState(0);
	const [conjunto_habitacional, setConjunto_habitacional] = useState(0);
	const [infraparticao, setInfraparticao] = useState(0);
	const [construcao_base, setConstrucao_base] = useState(0);
	const [sobrelevacao, setSobrelevacao] = useState(0);

	const [legitimacao_fundiaria, setLegitimacao_fundiaria] = useState(0);
	const [legitimacao_posse, setLegitimacao_posse] = useState(0);
	const [usucapiao, setUsucapiao] = useState(0);
	const [desapropriacao_possuidores, setDesapropriacao_possuidores] = useState(0);
	const [arrecadacao, setArrecadacao] = useState(0);
	const [consorcio_imobiliario, setConsorcio_imobiliario] = useState(0);
	const [desapropriacao_social, setDesapropriacao_social] = useState(0);
	const [preempcao, setPreempcao] = useState(0);
	const [transferencia, setTransferencia] = useState(0);
	const [requisicao, setRequisicao] = useState(0);
	const [intervencao, setIntervencao] = useState(0);
	const [alienacao, setAlienacao] = useState(0);
	const [concessao_especial, setConcessao_especial] = useState(0);
	const [concessao_real, setConcessao_real] = useState(0);
	const [doacao, setDoacao] = useState(0);
	const [compra_venda, setCompra_venda] = useState(0);
	const [especializacao, setEspecializacao] = useState(0);

	const [nui_logradouro, setNui_logradouro] = useState("");
	const [nui_complemento, setNui_complemento] = useState("");
	const [nui_bairro, setNui_bairro] = useState("");
	const [nui_localidade, setNui_localidade] = useState("");
	const [nui_municipio, setNui_municipio] = useState("");
	const [nui_uf, setNui_uf] = useState("");
	const [nui_cep, setNui_cep] = useState("");

	const [nui_matricula, setNui_matricula] = useState(0);
	const [nui_textomatricula, setNui_textomatricula] = useState("");

	const [nui_app, setNui_app] = useState(0);
	const [nui_textoapp, setNui_textoapp] = useState("");

	const [nui_risco, setNui_risco] = useState(0);
	const [nui_textorisco, setNui_textorisco] = useState("");

	const [nui_observacao, setNui_observacao] = useState("");

	const [lista_usuarios, setLista_usuarios] = useState([{ usu_id: 0, usu_nome: '-' }]);

	//Ler dados núcleo
	useEffect(() => {
		//Dados do núcleo
		api.post('/nucleo/LerEditarNucleo.php', JSON.stringify({ nui_id }))
			.then(function (rp) {

				setNui_cod(rp.data.nui_cod);
				setNui_nome(rp.data.nui_nome);

				setResponsavel(rp.data.responsavel);
				setNui_modalidade(rp.data.nui_modalidade);

				setTerreno(rp.data.terreno);
				setCondominio_simples(rp.data.condominio_simples);
				setCondominio_lotes(rp.data.condominio_lotes);
				setCondominio_edilicio(rp.data.condominio_edilicio);
				setConjunto_habitacional(rp.data.conjunto_habitacional);
				setInfraparticao(rp.data.infraparticao);
				setConstrucao_base(rp.data.construcao_base);
				setSobrelevacao(rp.data.sobrelevacao);

				setLegitimacao_fundiaria(rp.data.legitimacao_fundiaria);
				setLegitimacao_posse(rp.data.legitimacao_posse);
				setUsucapiao(rp.data.usucapiao);
				setDesapropriacao_possuidores(rp.data.desapropriacao_possuidores);
				setArrecadacao(rp.data.arrecadacao);
				setConsorcio_imobiliario(rp.data.consorcio_imobiliario);
				setDesapropriacao_social(rp.data.desapropriacao_social);
				setPreempcao(rp.data.preempcao);
				setTransferencia(rp.data.transferencia);
				setRequisicao(rp.data.requisicao);
				setIntervencao(rp.data.intervencao);
				setAlienacao(rp.data.alienacao);
				setConcessao_especial(rp.data.concessao_especial);
				setConcessao_real(rp.data.concessao_real);
				setDoacao(rp.data.doacao);
				setCompra_venda(rp.data.compra_venda);
				setEspecializacao(rp.data.especializacao);

				setNui_logradouro(rp.data.nui_logradouro);
				setNui_complemento(rp.data.nui_complemento);
				setNui_bairro(rp.data.nui_bairro);
				setNui_localidade(rp.data.nui_localidade);
				setNui_municipio(rp.data.nui_municipio);
				setNui_uf(rp.data.nui_uf);
				setNui_cep(rp.data.nui_cep);

				setNui_matricula(rp.data.nui_matricula);
				setNui_textomatricula(rp.data.nui_textomatricula);

				setNui_app(rp.data.nui_app);
				setNui_textoapp(rp.data.nui_textoapp);

				setNui_risco(rp.data.nui_risco);
				setNui_textorisco(rp.data.nui_textorisco);

				setNui_observacao(rp.data.nui_observacao);

				setLista_usuarios(rp.data.lista_usuarios);

			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				} else {
					console.log(error);
				}
			});
	}, []);

	//Verificar campo textual
	useEffect(() => {

		handleMatricula(nui_matricula);
		handleApp(nui_app);
		handleRisco(nui_risco);

	}, [nui_matricula, nui_app, nui_risco]);

	//Salvar dados nucleo
	async function salvarNucleo() {
		const data = {

			rem_id,
			nui_id,

			responsavel,
			nui_modalidade,

			terreno,
			condominio_simples,
			condominio_lotes,
			condominio_edilicio,
			conjunto_habitacional,
			infraparticao,
			construcao_base,
			sobrelevacao,

			legitimacao_fundiaria,
			legitimacao_posse,
			usucapiao,
			desapropriacao_possuidores,
			arrecadacao,
			consorcio_imobiliario,
			desapropriacao_social,
			preempcao,
			transferencia,
			requisicao,
			intervencao,
			alienacao,
			concessao_especial,
			concessao_real,
			doacao,
			compra_venda,
			especializacao,

			nui_logradouro,
			nui_complemento,
			nui_bairro,
			nui_localidade,
			nui_municipio,
			nui_uf,
			nui_cep,

			nui_matricula,
			nui_textomatricula,

			nui_app,
			nui_textoapp,

			nui_risco,
			nui_textorisco,

			nui_observacao

		}
		api.post('/nucleo/SalvarNucleo.php', JSON.stringify(data))
			.then(function (rp) {
				return (toast({
					position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
				})
				);
			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				} else {
					console.log(error);
				}
			});
	}

	//Matrícula
	function handleMatricula(valor) {
		if (valor === 1) {
			if (!isMatriculaOpen) {
				onMatriculaToggle();
			}
		} else {
			if (isMatriculaOpen) {
				onMatriculaToggle();
			}
		}
	}

	//APP
	function handleApp(valor) {
		if (valor === 1) {
			if (!isAppOpen) {
				onAppToggle();
			}
		} else {
			if (isAppOpen) {
				onAppToggle();
			}
		}
	}

	//Risco
	function handleRisco(valor) {
		if (valor === 1) {
			if (!isRiscoOpen) {
				onRiscoToggle();
			}
		} else {
			if (isRiscoOpen) {
				onRiscoToggle();
			}
		}
	}

	//Sair da edição
	function sairEditarNucleo() {
		setEditar_nucleo(0);
	}

	return (
		<div id="editar_nucleo">
			<Container maxW='95%' bg='white'>
				<Flex direction='row' mb={8}>
					<Button colorScheme="teal" w={'5%'} rounded={'none'} h={'58px'} onClick={e => sairEditarNucleo()}><ChevronLeftIcon boxSize={8} /></Button>
					<Flex background={'white'} border={'1px'} p={2} w={'95%'}>
						<Heading size={'md'} color={'gray.700'} mt={2} ml={3}>Núcleo {nui_nome + ' (' + nui_cod + ')'}</Heading>
					</Flex>
				</Flex>

				<Heading as='h4' size='md' textAlign={'left'}>
					Dados Gerais
				</Heading>
				<Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
				<SimpleGrid columns={2} spacing={5}>
					<Stack spacing={3} direction='row' w={'90%'}><Text whiteSpace='nowrap'>Responsável do Projeto</Text>
						<Select borderColor={'gray'} size='sm' value={responsavel} onChange={e => setResponsavel(e.target.value)}>
							<option key={0} value={0}></option>
							{lista_usuarios.map(item => {
								return (<option key={item.usu_id} value={item.usu_id}>{item.usu_nome}</option>);
							})}
						</Select>
					</Stack>
					<Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Modalidade Predominante</Text>
						<Select borderColor={'gray'} size='sm' value={nui_modalidade} onChange={e => setNui_modalidade(e.target.value)}>
							<option key={0} value={'S'}>Social (Reurb-S)</option>
							<option key={1} value={'E'}>Específico (Reurb-E)</option>
							<option key={2} value={'I'}>Indefinida</option>
						</Select>
					</Stack>
				</SimpleGrid>

				<Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
				<Grid
					h='300px'
					templateRows='repeat(1, 1fr)'
					templateColumns='repeat(12, 1fr)'
					gap={4}
				>
					<GridItem colSpan={5}>
						<Text mb={3}>Objeto da Regularização</Text>
						<Stack spacing={2} direction='column'>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={terreno} onChange={e => setTerreno(e.target.checked ? 1 : 0)} isChecked={terreno === 1}>Terreno</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={condominio_simples} onChange={e => setCondominio_simples(e.target.checked ? 1 : 0)} isChecked={condominio_simples === 1}>Condomínio Simples (Divisão de Edificações)</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={condominio_lotes} onChange={e => setCondominio_lotes(e.target.checked ? 1 : 0)} isChecked={condominio_lotes === 1}>Condomínio de Lotes</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={condominio_edilicio} onChange={e => setCondominio_edilicio(e.target.checked ? 1 : 0)} isChecked={condominio_edilicio === 1}>Condomínio Edilício</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={conjunto_habitacional} onChange={e => setConjunto_habitacional(e.target.checked ? 1 : 0)} isChecked={conjunto_habitacional === 1}>Conjunto Habitacional (Terreno e Edificação)</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={infraparticao} onChange={e => setInfraparticao(e.target.checked ? 1 : 0)} isChecked={infraparticao === 1}>Laje (Infrapartição)</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={construcao_base} onChange={e => setConstrucao_base(e.target.checked ? 1 : 0)} isChecked={construcao_base === 1}>Laje (Construção Base)</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={sobrelevacao} onChange={e => setSobrelevacao(e.target.checked ? 1 : 0)} isChecked={sobrelevacao === 1}>Laje (Sobrelevação)</Checkbox>
						</Stack>
					</GridItem>
					<GridItem colSpan={3}>
						<Text mb={3}>Instrumento da Regularização</Text>
						<Stack spacing={2} direction='column'>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={legitimacao_fundiaria} onChange={e => setLegitimacao_fundiaria(e.target.checked ? 1 : 0)} isChecked={legitimacao_fundiaria === 1}>Legitimação Fundiária</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={legitimacao_posse} onChange={e => setLegitimacao_posse(e.target.checked ? 1 : 0)} isChecked={legitimacao_posse === 1}>Legitimação de Posse</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={usucapiao} onChange={e => setUsucapiao(e.target.checked ? 1 : 0)} isChecked={usucapiao === 1}>Usucapião</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={desapropriacao_possuidores} onChange={e => setDesapropriacao_possuidores(e.target.checked ? 1 : 0)} isChecked={desapropriacao_possuidores === 1}>Desapropriação em Favor dos Possuidores</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={arrecadacao} onChange={e => setArrecadacao(e.target.checked ? 1 : 0)} isChecked={arrecadacao === 1}>Arrecadação de Bem Vago</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={consorcio_imobiliario} onChange={e => setConsorcio_imobiliario(e.target.checked ? 1 : 0)} isChecked={consorcio_imobiliario === 1}>Consórcio Imobiliário</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={desapropriacao_social} onChange={e => setDesapropriacao_social(e.target.checked ? 1 : 0)} isChecked={desapropriacao_social === 1}>Desapropriação por Interesse Social</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={preempcao} onChange={e => setPreempcao(e.target.checked ? 1 : 0)} isChecked={preempcao === 1}>Direito de Preempção</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={transferencia} onChange={e => setTransferencia(e.target.checked ? 1 : 0)} isChecked={transferencia === 1}>Transferência do Direito de Construir</Checkbox>
						</Stack>
					</GridItem>
					<GridItem colSpan={3}>
						<Stack spacing={2} direction='column' mt={8}>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={requisicao} onChange={e => setRequisicao(e.target.checked ? 1 : 0)} isChecked={requisicao === 1}>Requisição por Perigo Iminente</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={intervencao} onChange={e => setIntervencao(e.target.checked ? 1 : 0)} isChecked={intervencao === 1}>Intervenção do Poder Público</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={alienacao} onChange={e => setAlienacao(e.target.checked ? 1 : 0)} isChecked={alienacao === 1}>Alienação</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={concessao_especial} onChange={e => setConcessao_especial(e.target.checked ? 1 : 0)} isChecked={concessao_especial === 1}>Concessão de Uso Especial</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={concessao_real} onChange={e => setConcessao_real(e.target.checked ? 1 : 0)} isChecked={concessao_real === 1}>Concessão de Direito Real de Uso</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={doacao} onChange={e => setDoacao(e.target.checked ? 1 : 0)} isChecked={doacao === 1}>Doação</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={compra_venda} onChange={e => setCompra_venda(e.target.checked ? 1 : 0)} isChecked={compra_venda === 1}>Compra e Venda</Checkbox>
							<Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={especializacao} onChange={e => setEspecializacao(e.target.checked ? 1 : 0)} isChecked={especializacao === 1}>Especialização</Checkbox>
						</Stack>
					</GridItem>
				</Grid>

				<Heading as='h4' size='md' textAlign={'left'} mt={6}>
					Dados de Localização
				</Heading>
				<Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
				<Grid
					h='140px'
					templateRows='repeat(3, 1fr)'
					templateColumns='repeat(12, 1fr)'
					gap={4}
				>
					<GridItem colSpan={6}>
						<Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Logradouro Principal</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} placeholder='Rua, Avenida, Beco, Rodovia...' size='sm' value={nui_logradouro} onChange={e => setNui_logradouro(e.target.value)} /></Stack>
					</GridItem>
					<GridItem colSpan={6}>
						<Stack spacing={3} direction='row'><Text>Complemento</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} placeholder='Loteamento, condomínio, residencial, chácara, conjunto habitacional...' size='sm' value={nui_complemento} onChange={e => setNui_complemento(e.target.value)} /></Stack>
					</GridItem>

					<GridItem colSpan={6}>
						<Stack spacing={3} direction='row'><Text>Bairro</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} size='sm' value={nui_bairro} onChange={e => setNui_bairro(e.target.value)} /></Stack>
					</GridItem>
					<GridItem colSpan={6}>
						<Stack spacing={3} direction='row'><Text>Localidade</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} size='sm' value={nui_localidade} onChange={e => setNui_localidade(e.target.value)} /></Stack>
					</GridItem>

					<GridItem colSpan={7}>
						<Stack spacing={3} direction='row'><Text>Município</Text><Input autoComplete="no" borderColor={'gray'} maxLength={250} size='sm' value={nui_municipio} onChange={e => setNui_municipio(e.target.value)} /></Stack>
					</GridItem>
					<GridItem colSpan={2}>
						<Stack spacing={3} direction='row'><Text>UF</Text><Input autoComplete="no" borderColor={'gray'} maxLength={2} size='sm' value={nui_uf} onChange={e => setNui_uf(e.target.value)} /></Stack>
					</GridItem>
					<GridItem colSpan={3}>
						<Stack spacing={3} direction='row'><Text>CEP</Text><Input autoComplete="no" as={ReactInputMask} mask="99.999-999" maskChar={null} borderColor={'gray'} size='sm' value={nui_cep} onChange={e => setNui_cep(e.target.value)} /></Stack>
					</GridItem>
				</Grid>

				<Heading as='h4' size='md' textAlign={'left'} mt={6}>
					Descrição
				</Heading>
				<Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />

				<Flex direction="column">
					A análise de matrícula/transcrição foi concluída?
					<Stack direction={'row'} m={5} spacing={5}>
						<Center cursor='pointer' borderRadius={'10px'} bg={nui_matricula === 1 ? 'teal.200' : 'gray.200'} w={'100px'} h={'40px'} onClick={e => setNui_matricula(1)}>SIM</Center>
						<Center cursor='pointer' borderRadius={'10px'} bg={nui_matricula === 1 ? 'gray.200' : 'teal.200'} w={'100px'} h={'40px'} onClick={e => setNui_matricula(0)}>NÃO</Center>
					</Stack>
					<Collapse in={isMatriculaOpen} animateOpacity>
						<Textarea borderColor={'gray'} bg={'white'}
							p={4}
							minH="unset"
							overflow="hidden"
							w="100%"
							resize="none"
							minRows={3}
							as={ResizeTextarea}
							placeholder="Dados das matrículas e/ou transcrições atingidas pelo núcleo urbano..."
							value={nui_textomatricula}
							onChange={e => setNui_textomatricula(e.target.value)}
							textAlign={'justify'}
						/>
					</Collapse>
				</Flex>

				<Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
				<Flex direction="column">
					O núcleo urbano abrange área de preservação permanente, unidade de conservação ou manancial de abastecimento de água?
					<Stack direction={'row'} m={5} spacing={5}>
						<Center cursor='pointer' borderRadius={'10px'} bg={nui_app === 1 ? 'teal.200' : 'gray.200'} w={'100px'} h={'40px'} onClick={e => setNui_app(1)}>SIM</Center>
						<Center cursor='pointer' borderRadius={'10px'} bg={nui_app === 1 ? 'gray.200' : 'teal.200'} w={'100px'} h={'40px'} onClick={e => setNui_app(0)}>NÃO</Center>
					</Stack>
					<Collapse in={isAppOpen} animateOpacity>
						<Textarea borderColor={'gray'} bg={'white'}
							p={4}
							minH="unset"
							overflow="hidden"
							w="100%"
							resize="none"
							minRows={3}
							as={ResizeTextarea}
							placeholder="Características dos elementos de relevância ambiental..."
							value={nui_textoapp}
							onChange={e => setNui_textoapp(e.target.value)}
							textAlign={'justify'}
						/>
					</Collapse>
				</Flex>

				<Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
				<Flex direction="column">
					O núcleo urbano abrange área de risco?
					<Stack direction={'row'} m={5} spacing={5}>
						<Center cursor='pointer' borderRadius={'10px'} bg={nui_risco === 1 ? 'teal.200' : 'gray.200'} w={'100px'} h={'40px'} onClick={e => setNui_risco(1)}>SIM</Center>
						<Center cursor='pointer' borderRadius={'10px'} bg={nui_risco === 1 ? 'gray.200' : 'teal.200'} w={'100px'} h={'40px'} onClick={e => setNui_risco(0)}>NÃO</Center>
					</Stack>
					<Collapse in={isRiscoOpen} animateOpacity>
						<Textarea borderColor={'gray'} bg={'white'}
							p={4}
							minH="unset"
							overflow="hidden"
							w="100%"
							resize="none"
							minRows={3}
							as={ResizeTextarea}
							placeholder="Características do risco..."
							value={nui_textorisco}
							onChange={e => setNui_textorisco(e.target.value)}
							textAlign={'justify'}
						/>
					</Collapse>
				</Flex>

				<Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
				<Flex direction="column">
					Observação
					<Textarea borderColor={'gray'} bg={'white'}
						p={4}
						minH="unset"
						overflow="hidden"
						w="100%"
						resize="none"
						minRows={5}
						as={ResizeTextarea}
						placeholder="Descrever as particularidades do núcleo urbano e do projeto..."
						value={nui_observacao}
						onChange={e => setNui_observacao(e.target.value)}
						textAlign={'justify'}
					/>
				</Flex>

				<Flex direction='row' p={5} mt={4} mb={4}>
					<Spacer />
					<Button colorScheme="teal" variant={'outline'} onClick={e => salvarNucleo()}>Salvar Núcleo <SaveIcon ml={2} /></Button>
				</Flex>

			</Container>
		</div>
	);
}

export default EditarNucleo;