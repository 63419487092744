import React, { useState, useEffect, createContext} from "react";
import { useNavigate } from "react-router-dom";

import { api, createSession } from "../../services/api";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [credencial, setCredencial] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const recoveredUser = sessionStorage.getItem("user");
        const recoveredCredencial = sessionStorage.getItem("credencial");

        if(recoveredUser) {
            setUser(JSON.parse(recoveredUser));
            setCredencial(recoveredCredencial);
        }
        
        setLoading(false);
    }, []);

    const login = async (email, password) => {
        //Ir no servidor e criar uma session        
        const response = await createSession(email, password);
        console.log("login", response);
                
        const loggedUser = response.data.user;
        const credencialUser = response.data.credencial;
        const token = response.data.token;

        if (typeof loggedUser !== "undefined") {
            sessionStorage.setItem("user", JSON.stringify(loggedUser));
            sessionStorage.setItem("credencial", credencialUser);
        }
        if (typeof token !== "undefined") {
            sessionStorage.setItem("token", token);
        }

        //VERIFICAR
        api.defaults.headers.Authorization = `Bearer ${token}`;

        setUser(loggedUser);
        setCredencial(credencialUser);
        navigate("/reurb");        
    };

    const logout = () => {
        console.log("logout");

        sessionStorage.removeItem("user");
        sessionStorage.removeItem("credencial");
        sessionStorage.removeItem("token");

        //Remover Variáves da Reurb
        sessionStorage.removeItem("rem_uf");
        sessionStorage.removeItem("rem_id");
        sessionStorage.removeItem("rem_municipio");
        sessionStorage.removeItem("rem_remessa");
        sessionStorage.removeItem("unid_id");
        sessionStorage.removeItem("req_id");
        sessionStorage.removeItem("ocup_id");
        sessionStorage.removeItem("nui_id");

        //VERIFICAR
        api.defaults.headers.Authorization = null;

        setUser(null);
        setCredencial(null);
        navigate("/login");
    };

    //Outras variáveis
    const [editar_perfil, setEditar_perfil] = useState(0);
    const [usuario_selecionado, setUsuario_selecionado] = useState(0);

    return (
        <AuthContext.Provider
        value={{ authenticated: !!user, user, credencial, loading, login, logout, editar_perfil, setEditar_perfil, usuario_selecionado, setUsuario_selecionado }}
        >
            {children}
        </AuthContext.Provider>
    );
};