import React, { useRef } from "react";
import { useContext, useState, useEffect } from "react";
import { Text, Button, Flex, Spacer, Select, TableContainer, Table, Thead, Tr, Th, Td, Tbody, Container, ButtonGroup, Heading, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, ModalFooter, Box, Input, useToast, SimpleGrid, Divider, Center, Stack, Grid, GridItem, Checkbox, Textarea, Accordion, AccordionButton, AccordionItem, AccordionIcon, AccordionPanel } from '@chakra-ui/react';
import ResizeTextarea from "react-textarea-autosize";
import { useNavigate } from "react-router-dom";
import ExportExcel from "../../components/Excel/excelexport";
import { ChevronLeftIcon, ChevronRightIcon, DownloadIcon, SettingsIcon } from "@chakra-ui/icons";
import { SaveIcon } from "../../icons/saveicon";
import { NavigateIcon } from "../../icons/navigateicon";
import NavSuperior from "../../components/NavBar/navsuperior";
import DadosRemessa from "../../components/Remessa/dadosremessa";

import { api } from "../../../services/api";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

const ModalidadePage = () => {
  //Variáveis do Contexto
  const { rem_id, rem_municipio, rem_remessa, unid_id, setUnid_id } = useContext(ReurbContext);
  const { logout, user } = useContext(AuthContext);

  //Navegação
  const navigate = useNavigate();

  //Variáveis Chakra UI
  const toast = useToast();
  const { isOpen: isModalidadeOpen, onOpen: onModalidadeOpen, onClose: onModalidadeClose } = useDisclosure();
  const { isOpen: isConfigurarOpen, onOpen: onConfigurarOpen, onClose: onConfigurarClose } = useDisclosure();

  //Variáveis
  const [unid_cod, setUnid_cod] = useState('');
  const [anterior, setAnterior] = useState(0);
  const [proximo, setProximo] = useState(0);
  const [quantidade, setQuantidade] = useState(0);
  const [renda_familiar, setRenda_familiar] = useState('');
  const [renda_percapita, setRenda_percapita] = useState('');
  const [mod_cadunico, setMod_cadunico] = useState(0);
  const [mod_imovel, setMod_imovel] = useState(0);
  const [mod_parecer, setMod_parecer] = useState(0);
  const [reurb_modalidade, setReurb_modalidade] = useState('');
  const [modalidade_adotada, setModalidade_adotada] = useState('');
  const [modalidade_sugerida, setModalidade_sugerida] = useState('');
  const [reurb_situacao, setReurb_situacao] = useState('');
  const [coment_vulnerabilidade, setComent_vulnerabilidade] = useState('');
  const [coment_familiar, setComent_familiar] = useState('');
  const [coment_percapita, setComent_percapita] = useState('');
  const [mod_observacao, setMod_observacao] = useState('');
  const [mod_salariominimo, setMod_salariominimo] = useState("");
  const [mod_rendafamiliar, setMod_rendafamiliar] = useState("");
  const [valor_familiar, setValor_familiar] = useState("");
  const [lista_requerentes, setLista_requerentes] = useState([{ nome: '-', parentesco: '-', sexo: '-', idade: '-', deficiencia: '-', renda: '0,00', profissao: '-' }]);
  const [lista_processos, setLista_processos] = useState([{ unid_id: 0, unid_cod: '-', nui_id: 0, nui_cod: '-', principal: '-', ocupantes: 0, renda_familiar: 0, possui_imovel: '-', modalidade_adotada: '-', parecer_social: '-', situacao: '-' }]);
  const [excel_modalidade, setExcel_modalidade] = useState([{ CodigoProcesso: '-', CodigoNucleo: '-', Principal: '-', Ocupantes: 0, RendaFamiliar: 0, PossuiImovel: '-', MOdalidadeAdotada: '-', ParecerSocial: '-', Situacao: '-' }]);
  const [lista_nucleo, setLista_nucleo] = useState([{ nui_id: 0, nui_cod: '-', nui_nome: '-' }]);
  const [lista_modalidade, setLista_modalidade] = useState([{ mod_id: 0, modalidade: '-' }]);
  const [lista_situacao, setLista_situacao] = useState([{ sit_id: 0, situacao: '-' }]);

  const [controle, setControle] = useState(false);

  //Filtros
  const [nucleo_selecionado, setNucleo_selecionado] = useState(0);
  const [modalidade_selecionada, setModalidade_selecionada] = useState(0);
  const [situacao_selecionada, setSituacao_selecionada] = useState(0);

  //Ler dados
  useEffect(() => {
    const data = {
      rem_id,
      nucleo_selecionado,
      modalidade_selecionada,
      situacao_selecionada
    }
    //Lista modalidade
    api.post('/modalidade/LerModalidade.php', JSON.stringify(data))
      .then(function (rp) {
        setMod_salariominimo(rp.data.mod_salariominimo);
        setMod_rendafamiliar(rp.data.mod_rendafamiliar);
        setLista_processos(rp.data.lista_processos);
        setExcel_modalidade(rp.data.excel_modalidade);
        setLista_nucleo(rp.data.lista_nucleo);
        setLista_modalidade(rp.data.lista_modalidade);
        setLista_situacao(rp.data.lista_situacao);
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }, [nucleo_selecionado, modalidade_selecionada, situacao_selecionada, controle]);

  //Carregar modalidade
  function carregarModalidade(id) {
    if (id !== 0) {
      setUnid_id(id);
      const data = {
        rem_id,
        unid_id: id,
        nucleo_selecionado,
        modalidade_selecionada,
        situacao_selecionada
      }
      api.post('/modalidade/LerAnaliseModalidade.php', JSON.stringify(data))
        .then(function (rp) {

          setLista_requerentes(rp.data.lista_requerentes);

          setAnterior(rp.data.anterior);
          setProximo(rp.data.proximo);

          setMod_salariominimo(rp.data.mod_salariominimo);
          setMod_rendafamiliar(rp.data.mod_rendafamiliar);

          setUnid_cod(rp.data.unid_cod);
          setQuantidade(rp.data.quantidade);
          setRenda_familiar(rp.data.renda_familiar);
          setRenda_percapita(rp.data.renda_percapita);
          setMod_cadunico(rp.data.mod_cadunico);
          setMod_imovel(rp.data.mod_imovel);
          setMod_parecer(rp.data.mod_parecer);
          setReurb_modalidade(rp.data.reurb_modalidade);
          setReurb_situacao(rp.data.reurb_situacao);

          setModalidade_adotada(rp.data.modalidade_adotada);
          setModalidade_sugerida(rp.data.modalidade_sugerida);
          setComent_vulnerabilidade(rp.data.coment_vulnerabilidade);
          setComent_familiar(rp.data.coment_familiar);
          setComent_percapita(rp.data.coment_percapita);
          setMod_observacao(rp.data.mod_observacao);

        })
        .catch(function (error) {
          if (error.response.data === "EXPIRED") {
            logout();
          } else {
            console.log(error);
          }
        });
    }
  }

  //Calcular valor de corte da renda familiar
  useEffect(() => {

    var salario;
    var multiplicador;
    var valor;

    if (mod_salariominimo !== "" && mod_rendafamiliar !== "") {
      salario = String(mod_salariominimo).replace('.', '').replace(',', '.');
      multiplicador = String(mod_rendafamiliar).replace('.', '').replace(',', '.');
      if (parseFloat(salario) > 0 && parseFloat(multiplicador) > 0) {
        valor = parseFloat(multiplicador) * parseFloat(salario);
        setValor_familiar(valor.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));

      } else {
        setValor_familiar("0,00");
      }
    } else {
      setValor_familiar("0,00");
    }

  }, [mod_salariominimo, mod_rendafamiliar]);

  //Verificar se há imóvel
  useEffect(() => {

    if (mod_imovel === 1) {
      setModalidade_sugerida('Específico');
    }

  }, [mod_imovel]);

  //Salvar configurações
  function salvarConfigurar() {
    const data = {
      rem_id,
      mod_salariominimo,
      mod_rendafamiliar
    }
    api.post('/modalidade/SalvarConfigurar.php', JSON.stringify(data))
      .then(function (rp) {
        onConfigurarClose();
        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
        })
        );
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Salvar modalidade
  function salvarModalidade() {
    const data = {
      user,
      rem_id,
      unid_id,
      mod_imovel,
      mod_cadunico,
      mod_parecer,
      mod_observacao,
      modalidade_adotada
    }
    api.post('/modalidade/SalvarModalidade.php', JSON.stringify(data))
      .then(function (rp) {

        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
        })
        );

      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Adotar modalidade
  async function handleModalidade(modalidade) {
    if (modalidade === 'Específico') {
      if (modalidade_adotada === 'Específico') {
        setModalidade_adotada('Indefinido');
      } else {
        setModalidade_adotada('Específico');
      }
    }
    if (modalidade === 'Social') {
      if (modalidade_adotada === 'Social') {
        setModalidade_adotada('Indefinido');
      } else {
        setModalidade_adotada('Social');
      }
    }
  }

  //Abrir modal de análise
  function handleAnalise(id) {
    if (id > 0) {
      carregarModalidade(id);
      onModalidadeOpen();
    }
  }

  //Ir para remessa
  function goToRemessa() {
    navigate('/remessa');
  }

  //Ir para processo
  function goToProcesso() {
    navigate('/processo');
  }

  return (
    <div id="modalidade">
      <NavSuperior />
      <DadosRemessa titulo="Análise da Modalidade" />

      <Container maxW='95%' bg='white'>
        <Flex direction='row'>
          <Button colorScheme="teal" w={'5%'} rounded={'none'} h={'58px'} onClick={e => goToRemessa()}><ChevronLeftIcon boxSize={8} /></Button>
          <Flex background={'white'} border={'1px'} p={2} w={'95%'}>
            <Heading size={'md'} color={'gray.700'} mt={2} ml={3}>{''}</Heading>
            <Spacer />
            <Text mr={2} pt={2}>Núcleo</Text>
            <Select borderColor={'teal'} size='sm' w='200px' pt={1} mr={8} value={nucleo_selecionado} onChange={e => { setNucleo_selecionado(e.target.value) }}>
              <option key={0} value={0}>{'-'}</option>
              {lista_nucleo.map((item, i) => {
                return (<option key={i + 1} value={item.nui_id}>{item.nui_cod}</option>);
              })}
            </Select>
            <Text mr={2} pt={2}>Modalidade</Text>
            <Select borderColor={'teal'} size='sm' w='200px' pt={1} mr={8} value={modalidade_selecionada} onChange={e => { setModalidade_selecionada(e.target.value) }}>
              <option key={0} value={0}>{'-'}</option>
              {lista_modalidade.map((item, i) => {
                return (<option key={i + 1} value={item.mod_id}>{item.modalidade}</option>);
              })}
            </Select>
            <Text mr={2} pt={2}>Situação</Text>
            <Select borderColor={'teal'} size='sm' w='200px' pt={1} mr={8} value={situacao_selecionada} onChange={e => { setSituacao_selecionada(e.target.value) }}>
              <option key={0} value={0}>{'-'}</option>
              {lista_situacao.map((item, i) => {
                return (<option key={i + 1} value={item.sit_id}>{item.situacao}</option>);
              })}
            </Select>
            <ButtonGroup gap='4' ml={5}>
              <ExportExcel excelData={excel_modalidade} fileName={"Dados Modalidade"}/>
              <Button colorScheme="teal" variant='outline' onClick={e => onConfigurarOpen()}>Configurar<SettingsIcon ml={2} /></Button>
            </ButtonGroup>
          </Flex>
        </Flex>

        <TableContainer mt={5} mb={20}>
          <Table size='sm'>
            <Thead>
              <Tr>
                <Th textAlign={'center'} pl='1px' pr='1px'>NUI</Th>
                <Th textAlign={'center'} pl='1px' pr='1px'>CÓDIGO</Th>
                <Th textAlign={'center'} pl='1px' pr='1px'>PRINCIPAL</Th>
                <Th textAlign={'center'} pl='1px' pr='1px'>OCUPANTES</Th>
                <Th textAlign={'center'} pl='1px' pr='1px'>RENDA FAMILIAR (R$)</Th>
                <Th textAlign={'center'} pl='1px' pr='1px'>POSSUI IMÓVEL</Th>
                <Th textAlign={'center'} pl='1px' pr='1px'>MODALIDADE</Th>
                <Th textAlign={'center'} pl='1px' pr='1px'>PARECER SOCIAL</Th>
                <Th textAlign={'center'} pl='1px' pr='1px'>SITUAÇÃO</Th>
              </Tr>
            </Thead>
            <Tbody>
              {lista_processos.map((item, i) => {
                return (
                  <Tr key={i} cursor='pointer' _hover={{ bg: 'gray.200' }} onClick={e => handleAnalise(item.unid_id)}>
                    <Td textAlign={'center'}>{item.nui_cod}</Td>
                    <Td textAlign={'center'}>{item.unid_cod}</Td>
                    <Td whiteSpace={'pre-wrap'} maxW={'20vw'}>{item.principal}</Td>
                    <Td textAlign={'center'}>{item.ocupantes}</Td>
                    <Td textAlign={'center'}>{item.renda_familiar}</Td>
                    <Td textAlign={'center'}>{item.possui_imovel}</Td>
                    <Td textAlign={'center'}>{item.modalidade_adotada}</Td>
                    <Td textAlign={'center'}>{item.parecer_social}</Td>
                    <Td textAlign={'center'}>{item.situacao}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>

        {/*MODAL CONFIGURAR*/}
        <Modal isOpen={isConfigurarOpen} onClose={onConfigurarClose} isCentered size='2xl'>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader textAlign='center'>CONFIGURAÇÕES</ModalHeader>
            <ModalCloseButton />
            <ModalBody p={5}>

              <SimpleGrid columns={2} spacing={5} mb={5}>
                <Box>
                  <Text whiteSpace={'nowrap'} textAlign={'right'}>Salário Mínimo (R$)</Text>
                </Box>
                <Box>
                  <Input borderColor={'gray'} maxLength={10} size='sm' w='200px' value={mod_salariominimo} onChange={e => setMod_salariominimo(e.target.value)} />
                </Box>
              </SimpleGrid>

              <SimpleGrid columns={2} spacing={5} mb={5}>
                <Box>
                  <Text whiteSpace={'nowrap'} textAlign={'right'}>Fator Multiplicador</Text>
                </Box>
                <Box>
                  <Input borderColor={'gray'} maxLength={10} size='sm' w='200px' value={mod_rendafamiliar} onChange={e => setMod_rendafamiliar(e.target.value)} />
                </Box>
              </SimpleGrid>

              <Center><Divider borderColor={'gray'} w={'80%'} mb={5} /></Center>

              <Center direction={'row'}>
                <Text whiteSpace={'nowrap'} textAlign={'center'}>Renda de Corte (R$):</Text>
                <Text fontSize={'2xl'} ml={5}>{valor_familiar}</Text>
              </Center>

            </ModalBody>
            <ModalFooter>
              <Button colorScheme='teal' variant={'outline'} onClick={e => salvarConfigurar()}>Alterar</Button>
            </ModalFooter>

          </ModalContent>
        </Modal>

        {/*MODAL ANALISAR MODALIDADE*/}
        <Modal closeOnOverlayClick={false} isOpen={isModalidadeOpen} onClose={e=> {setControle(!controle); onModalidadeClose();}} size={'6xl'}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader mt={5} mb={-5}>
              <Center direction={'row'}>
                <Button colorScheme='teal' variant={'outline'} w={'100px'} h={'40px'} mr='20px' onClick={e => carregarModalidade(anterior)}><ChevronLeftIcon boxSize={'30px'} /></Button>
                <Heading size='lg' w={'200px'} textAlign={'center'}>{unid_cod}</Heading>
                <Button colorScheme='teal' variant={'outline'} w={'100px'} h={'40px'} ml='20px' onClick={e => carregarModalidade(proximo)}><ChevronRightIcon boxSize={'30px'} /></Button>
              </Center>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody p={5}>
              <Stack direction={'row'} spacing={5} pl={3} pr={3}>
                {lista_nucleo.map((item) => {
                  if (parseInt(item.nui_id) === parseInt(nucleo_selecionado)) {
                    return (<Text as={'i'} fontSize={'sm'}>{'Núcleo ' + item.nui_nome + ' (' + item.nui_cod + ')'}</Text>);
                  }
                })}
                {lista_modalidade.map((item) => {
                  if (parseInt(item.mod_id) === parseInt(modalidade_selecionada)) {
                    return (<Text as={'i'} fontSize={'sm'}>{'Modalidade ' + item.modalidade}</Text>);
                  }
                })}
                {lista_situacao.map((item) => {
                  if (parseInt(item.sit_id) === parseInt(situacao_selecionada)) {
                    return (<Text as={'i'} fontSize={'sm'}>{'Situação ' + item.situacao}</Text>);
                  }
                })}
                <Spacer />
                <Text as={'i'} fontSize={'sm'} color={mod_salariominimo === '0,00' ? 'red' : 'black'}>{'Salário Mínimo: R$' + mod_salariominimo}</Text>
                <Text as={'i'} fontSize={'sm'} color={mod_rendafamiliar === '0,00' ? 'red' : 'black'}>{'Fator Multiplicador: ' + mod_rendafamiliar}</Text>
              </Stack>

              <Divider borderColor={'gray'} mb={5} />

              <Flex direction={'column'}>

                <Grid
                  templateRows='repeat(1, 1fr)'
                  templateColumns='repeat(12, 1fr)'
                  gap={4}
                >
                  <GridItem colSpan={6} pl={4} pr={4}>

                    <Heading as='h4' size='md' color={'gray.600'}>Renda</Heading>
                    <Text display={mod_rendafamiliar === '0,00' || mod_salariominimo === '0,00' ? 'block' : 'none'}>Salário mínimo e/ou fator multiplicador não informado</Text>
                    <Text color={coment_familiar === 'Renda familiar não informada' ? 'red' : 'black'}>{coment_familiar}</Text>
                    <Text>{coment_percapita}</Text>

                    <Divider borderColor={'gray'} mb={2} mt={2} />

                    <Heading as='h4' size='md' color={'gray.600'}>Vulnerabilidade</Heading>
                    <Text>{coment_vulnerabilidade}</Text>

                    <Divider borderColor={'gray'} mb={2} mt={2} />

                    <Heading as='h4' size='md' color={'gray.600'}>Imóvel</Heading>
                    <Checkbox size='md' colorScheme='teal' onChange={e => setMod_imovel(e.target.checked ? 1 : 0)} isChecked={mod_imovel === 1}>Um ou mais beneficiários já possui imóvel registrado</Checkbox>

                    <Divider borderColor={'gray'} mb={2} mt={2} />

                    <Heading as='h4' size='md' color={'gray.600'}>Cadastro Único</Heading>
                    <Checkbox size='md' colorScheme='teal' onChange={e => setMod_cadunico(e.target.checked ? 1 : 0)} isChecked={mod_cadunico === 1}>Os beneficiários possuem Cadastro Único (CadÚnico)</Checkbox>

                    <Divider borderColor={'gray'} mb={2} mt={2} />

                    <Heading as='h4' size='md' color={'gray.600'}>Parecer Social</Heading>
                    <Checkbox size='md' colorScheme='teal' onChange={e => setMod_parecer(e.target.checked ? 1 : 0)} isChecked={mod_parecer === 1}>O parecer social foi emitido pela prefeitura</Checkbox>

                  </GridItem>
                  <GridItem colSpan={6}>
                    <Flex borderColor={'gray'} borderWidth={'thin'} p={4} h={'100%'} direction={'column'}>
                      <Heading as='h4' size='md' color={'gray.600'}>Modalidade Sugerida</Heading>
                      <Text>{modalidade_sugerida}</Text>
                      <Heading as='h4' size='md' color={'gray.600'} mt={5}>Modalidade Adotada</Heading>
                      <Center>
                        <ButtonGroup gap={5} m={5}>
                          <Button colorScheme={modalidade_adotada === "Específico" ? 'teal' : 'gray'} size='sm' w={'130px'} h='50px' onClick={e => handleModalidade('Específico')}>Específico</Button>
                          <Button colorScheme={modalidade_adotada === "Social" ? 'teal' : 'gray'} size='sm' w={'130px'} h='50px' onClick={e => handleModalidade('Social')}>Social</Button>
                        </ButtonGroup>
                      </Center>
                      <Flex direction={'column'} m={3}>
                        <Text display={mod_parecer === 1 ? 'block' : 'none'}>- A modalidade adotada deve corresponder ao parecer social</Text>
                        <Text color={'red'} display={modalidade_adotada === 'Indefinido' ? 'block' : 'none'}>- Nenhuma modalidade adotada!</Text>
                        <Text display={reurb_modalidade !== modalidade_adotada ? 'block' : 'none'}>- Alterar a modalidade de {reurb_modalidade} para {modalidade_adotada}</Text>
                        <Text display={modalidade_sugerida !== modalidade_adotada && modalidade_adotada !== 'Indefinido' ? 'block' : 'none'}>- A modalidade adotada é diferente da modalidade sugerida</Text>
                      </Flex>
                    </Flex>

                  </GridItem>
                </Grid>

                <Textarea borderColor={'gray.400'} bg={'white'} mt={5} mb={5}
                  p={3}
                  borderRadius={0}
                  minH="unset"
                  overflow="hidden"
                  w="100%"
                  resize="none"
                  minRows={2}
                  as={ResizeTextarea}
                  placeholder="Observações..."
                  value={mod_observacao}
                  onChange={e => setMod_observacao(e.target.value)}
                  textAlign={'justify'}
                />
              </Flex>

              <Accordion allowToggle bg={'gray.100'}>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left'>
                        Lista de Requerentes e Ocupantes ({quantidade})
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel >
                    <Box w='100%'>
                      <TableContainer>
                        <Table size='sm'>
                          <Thead>
                            <Tr>
                              <Th borderBottomColor={'gray.300'} textAlign={'center'}>NOME</Th>
                              <Th borderBottomColor={'gray.300'} textAlign={'center'}>PARENTESCO</Th>
                              <Th borderBottomColor={'gray.300'} textAlign={'center'}>SEXO</Th>
                              <Th borderBottomColor={'gray.300'} textAlign={'center'}>IDADE</Th>
                              <Th borderBottomColor={'gray.300'} textAlign={'center'}>DEFICIÊNCIA</Th>
                              <Th borderBottomColor={'gray.300'} textAlign={'center'}>RENDA (R$)</Th>
                              <Th borderBottomColor={'gray.300'} textAlign={'center'}>PROFISSÃO</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {lista_requerentes.map((item, i) => {
                              return (
                                <Tr key={i}>
                                  <Td whiteSpace={'pre-wrap'} borderBottomColor={'gray.300'} pb='5px' pt='5px'>{item.nome}</Td>
                                  <Td borderBottomColor={'gray.300'} textAlign={'center'} pb='5px' pt='5px'>{item.parentesco}</Td>
                                  <Td borderBottomColor={'gray.300'} textAlign={'center'} pb='5px' pt='5px'>{item.sexo}</Td>
                                  <Td borderBottomColor={'gray.300'} textAlign={'center'} pb='5px' pt='5px'>{item.idade}</Td>
                                  <Td borderBottomColor={'gray.300'} textAlign={'center'} pb='5px' pt='5px'>{item.deficiencia}</Td>
                                  <Td borderBottomColor={'gray.300'} textAlign={'center'} pb='5px' pt='5px'>{item.renda}</Td>
                                  <Td borderBottomColor={'gray.300'} pb='5px' pt='5px'>{item.profissao}</Td>
                                </Tr>
                              );
                            })}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </ModalBody>

            <ModalFooter>
              <ButtonGroup gap='4'>
                <Button colorScheme="teal" variant='outline' w={'150px'} onClick={e => goToProcesso()}>Processo<NavigateIcon ml={2} /></Button>
                <Button colorScheme='teal' variant={'outline'} onClick={e => salvarModalidade()}>Salvar <SaveIcon ml={2} /></Button>
              </ButtonGroup>
            </ModalFooter>

          </ModalContent>
        </Modal>

      </Container>
    </div>
  );
}

export default ModalidadePage;