import React from "react";
import { useContext, useState, useEffect } from "react";
import Chart from "react-google-charts";
import ResizeTextarea from "react-textarea-autosize";
import { Grid, GridItem, Text, Input, Button, Select, Stack, Box, Heading, Flex, SimpleGrid, Divider, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Textarea, Center, ModalFooter, Container, Spacer, ButtonGroup, Accordion, AccordionButton, AccordionItem, AccordionPanel, AccordionIcon, Collapse, Tabs, Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon, DeleteIcon, EditIcon, NotAllowedIcon, LinkIcon, LockIcon, UnlockIcon } from "@chakra-ui/icons";
import { BarCodeIcon } from "../../icons/barcodeicon";
import { StepIcon } from "../../icons/stepicon";
import { AlertIcon } from "../../icons/alerticon";

import Comentarios from "../../components/Comentario/comentarios";

import { api } from "../../../services/api";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";
import { ProtocoloIcon } from "../../icons/protocoloicon";

const ExibirNucleo = () => {

  //Contexto
  const { logout, user } = useContext(AuthContext);
  const { rem_id, nui_id, setUnid_id, setEditar_nucleo, comentario_alterado, setComentario_alterado } = useContext(ReurbContext);

  //Variáveis Chakra UI
  const navigate = useNavigate();
  const toast = useToast();
  const cancelRef = React.useRef();
  const { isOpen: isTextoProtocoloOpen, onToggle: onTextoProtocoloToggle } = useDisclosure();
  const { isOpen: isVincularOpen, onOpen: onVincularOpen, onClose: onVincularClose } = useDisclosure();
  const { isOpen: isSituacaoOpen, onOpen: onSituacaoOpen, onClose: onSituacaoClose } = useDisclosure();
  const { isOpen: isCodigoOpen, onOpen: onCodigoOpen, onClose: onCodigoClose } = useDisclosure();
  const { isOpen: isExcluirOpen, onOpen: onExcluirOpen, onClose: onExcluirClose } = useDisclosure();
  const { isOpen: isEtapaOpen, onOpen: onEtapaOpen, onClose: onEtapaClose } = useDisclosure();
  const { isOpen: isDiligenciaOpen, onOpen: onDiligenciaOpen, onClose: onDiligenciaClose } = useDisclosure();
  const { isOpen: isProtocoloOpen, onOpen: onProtocoloOpen, onClose: onProtocoloClose } = useDisclosure();
  const { isOpen: isSocioeconomicoOpen, onOpen: onSocioeconomicoOpen, onClose: onSocioeconomicoClose } = useDisclosure();

  //Variáveis Controle
  const [bloquear, setBloquear] = useState(1);

  //Núcleo
  const [nui_cod, setNui_cod] = useState("");
  const [nui_nome, setNui_nome] = useState("");

  //Projeto
  const [matricula, setMatricula] = useState("");
  const [app, setApp] = useState("");
  const [risco, setRisco] = useState("");
  const [nui_etapa, setNui_etapa] = useState("");
  const [texto_etapa, setTexto_etapa] = useState("");
  const [nova_etapa, setNova_etapa] = useState("");
  const [responsavel, setResponsavel] = useState("");

  //Localização
  const [localizacao, setLocalizacao] = useState("");

  //Reurb
  const [nui_situacao, setNui_situacao] = useState("");
  const [nui_modalidade, setNui_modalidade] = useState("");
  const [nui_instrumento, setNui_instrumento] = useState("");
  const [nui_objeto, setNui_objeto] = useState("");
  const [nui_protocolo_prefeitura, setNui_protocolo_prefeitura] = useState(0);
  const [nui_protocolo_registro, setNui_protocolo_registro] = useState(0);
  const [texto_protocolo, setTexto_protocolo] = useState("");
  const [nui_diligencia_orgao, setNui_diligencia_orgao] = useState("");
  const [nui_diligencia_aberta, setNui_diligencia_aberta] = useState(0);
  const [nui_diligencia_respondida, setNui_diligencia_respondida] = useState(0);
  const [texto_diligencia, setTexto_diligencia] = useState("");

  const [total, setTotal] = useState(0);

  //Situação
  const [ativo, setAtivo] = useState(0);
  const [pendente, setPendente] = useState(0);
  const [cancelado, setCancelado] = useState(0);

  const dataSituacao = [
    ["Situação", "Quantidade"],
    ["Ativo", ativo],
    ["Pendente", pendente],
    ["Cancelado", cancelado]
  ];

  const optionsSituacao = {
    chartArea: { left: 20, top: 20, width: '100%', height: '80%' },
    height: '200px',
    slices: {
      0: { color: "#8FBC8F" },
      1: { color: "#A9A9A9" },
      2: { color: "#BC8F8F" },
    }
  };

  //Modalidade
  const [social, setSocial] = useState(0);
  const [especifico, setEspecifico] = useState(0);
  const [indefinido, setIndefinido] = useState(0);

  const dataModalidade = [
    ["Modalidade", "Quantidade"],
    ["Social", social],
    ["Específico", especifico],
    ["Indefinido", indefinido]
  ];

  const optionsModalidade = {
    chartArea: { left: 20, top: 20, width: '100%', height: '80%' },
    height: '200px',
    slices: {
      0: { color: "#6495ED" },
      1: { color: "#5F9EA0" },
      2: { color: "#708090" },
    }
  };

  //Descrição
  const [texto_matricula, setTexto_matricula] = useState("");
  const [texto_app, setTexto_app] = useState("");
  const [texto_risco, setTexto_risco] = useState("");
  const [texto_observacao, setTexto_observacao] = useState("");

  //Processos vinculados
  const [lista_processos, setLista_processos] = useState([{ unid_id: 0, unid_cod: '-', beneficiarios: '-', reurb_modalidade: '-', reurb_objeto: '-', reurb_instrumento: '-', dados: '-', lepac: '-', reurb_situacao: '-' }]);

  //Vincular
  const [cont_semnucleo, setCont_semnucleo] = useState(0);
  const [cont_vinculados, setCont_vinculados] = useState(0);
  const [lista_semnucleo, setLista_semnucleo] = useState([{ unid_id: 0, unid_cod: '-', principal: '-' }]);
  const [lista_vinculados, setLista_vinculados] = useState([{ unid_id: 0, unid_cod: '-', principal: '-' }]);

  //Lista de nomes usados
  const [lista_nomes, setLista_nomes] = useState([{ municipio: '-', remessa: '-', codigo: '-', nome: '-' }]);

  //Variáveis alterar código e nome
  const [mensagem_identificacao, setMensagem_identificacao] = useState("");
  const [novo_cod, setNovo_cod] = useState("");
  const [novo_nome, setNovo_nome] = useState("");

  //Variáveis alterar situação
  const [novasit, setNovasit] = useState("");
  const [motivo_situacao, setMotivo_situacao] = useState("");

  //Dados socioeconômicos
  const [lista_socioeconomico, setLista_socioeconomico] = useState([{ unid_id: 0, req_tipo: 0, unid_cod: '-', ocupantes: 0, renda_familiar: '-', renda_percapita: '-', idoso: 0, deficiente: 0, mulher_chefe: false }]);
  const [lista_profissoes, setLista_profissoes] = useState([{ profissao: '-', frequencia: 0 }]);

  //Ler dados núcleo
  useEffect(() => {
    //Dados do núcleo
    api.post('/nucleo/LerExibirNucleo.php', JSON.stringify({ nui_id }))
      .then(function (rp) {

        setNui_cod(rp.data.nui_cod);
        setNui_nome(rp.data.nui_nome);

        setNovo_cod(rp.data.nui_cod);
        setNovo_nome(rp.data.nui_nome);

        setMatricula(rp.data.matricula);
        setApp(rp.data.app);
        setRisco(rp.data.risco);
        setResponsavel(rp.data.responsavel);

        setLocalizacao(rp.data.localizacao);

        setNui_situacao(rp.data.nui_situacao);
        setNovasit(rp.data.nui_situacao);
        setNui_etapa(rp.data.nui_etapa);
        setNova_etapa(rp.data.nui_etapa);
        setNui_protocolo_prefeitura(rp.data.nui_protocolo_prefeitura);
        setNui_protocolo_registro(rp.data.nui_protocolo_registro);
        setNui_modalidade(rp.data.nui_modalidade);
        setNui_instrumento(rp.data.nui_instrumento);
        setNui_objeto(rp.data.nui_objeto);
        setNui_diligencia_orgao(rp.data.nui_diligencia_orgao);
        setNui_diligencia_aberta(rp.data.nui_diligencia_aberta);
        setNui_diligencia_respondida(rp.data.nui_diligencia_respondida);

        setTotal(rp.data.total);

        setAtivo(rp.data.ativo);
        setPendente(rp.data.pendente);
        setCancelado(rp.data.cancelado);

        setSocial(rp.data.social);
        setEspecifico(rp.data.especifico);
        setIndefinido(rp.data.indefinido);

        setTexto_matricula(rp.data.texto_matricula);
        setTexto_app(rp.data.texto_app);
        setTexto_risco(rp.data.texto_risco);
        setTexto_observacao(rp.data.texto_observacao);

        setLista_processos(rp.data.lista_processos);

      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }, [cont_vinculados]);

  //Ler dados socioeconômicos
  function carregarSocioeconomico() {
    const data = {
      rem_id,
      nui_id
    }
    api.post('/nucleo/LerSocioeconomico.php', JSON.stringify(data))
      .then(function (rp) {
        setLista_socioeconomico(rp.data.lista_socioeconomico);
        setLista_profissoes(rp.data.lista_profissoes);
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Carregar Vincular
  async function lerVincular() {
    const data = {
      rem_id,
      nui_id
    }
    await api.post('/nucleo/LerVincular.php', JSON.stringify(data))
      .then(function (rp) {
        setLista_semnucleo(rp.data.lista_semnucleo);
        setLista_vinculados(rp.data.lista_vinculados);
        setCont_semnucleo(rp.data.cont_semnucleo);
        setCont_vinculados(rp.data.cont_vinculados);
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Vincular Processo
  async function vincularProcesso(id) {
    if (bloquear === 0) {
      if (id !== 0) {
        const data = {
          rem_id,
          nui_id,
          unid_id: id
        }
        await api.post('/nucleo/VincularProcesso.php', JSON.stringify(data))
          .then(function (rp) {
            lerVincular();
          })
          .catch(function (error) {
            if (error.response.data === "EXPIRED") {
              logout();
            } else {
              console.log(error);
            }
          });
      }
    }
  }

  //Desvincular Processo
  async function desvincularProcesso(id) {
    if (bloquear === 0) {
      if (id !== 0) {
        const data = {
          rem_id,
          nui_id,
          unid_id: id
        }
        await api.post('/nucleo/DesvincularProcesso.php', JSON.stringify(data))
          .then(function (rp) {
            lerVincular();
          })
          .catch(function (error) {
            if (error.response.data === "EXPIRED") {
              logout();
            } else {
              console.log(error);
            }
          });
      }
    }
  }

  //Alterar situação
  async function alterarSituacao() {
    if (novasit !== nui_situacao) {
      const data = {
        user,
        rem_id,
        nui_id,
        nui_situacao,
        novasit,
        motivo_situacao
      }
      await api.post('/nucleo/AlterarSituacao.php', JSON.stringify(data))
        .then(function (rp) {
          if (rp.data.sucesso === true) {
            setNui_situacao(novasit);
            onSituacaoClose();
            if (comentario_alterado === 0) {
              setComentario_alterado(1);
            } else {
              setComentario_alterado(0);
            }
          }
          return (toast({
            position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
          })
          );
        })
        .catch(function (error) {
          if (error.response.data === "EXPIRED") {
            logout();
          } else {
            console.log(error);
            return (toast({
              position: 'bottom-left', render: () => (<Box color='white' p={3} bg='red'>Erro: não foi possível registrar os dados!</Box>),
            })
            );
          }
        });
    }
  }

  //Carregar nomes usados
  async function carregarNomesUsados() {
    const data = {
      rem_id
    }
    await api.post('/nucleo/LerNomesUsados.php', JSON.stringify(data))
      .then(function (rp) {
        setLista_nomes(rp.data.lista_nomes);
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Alterar código do núcleo
  async function alterarCodigo() {
    const data = {
      rem_id,
      nui_id,
      user,
      nui_cod,
      novo_cod
    }
    await api.post('/nucleo/AlterarCodigo.php', JSON.stringify(data))
      .then(function (rp) {
        setMensagem_identificacao(rp.data.msg);
        if (rp.data.sucesso === true) {
          setNui_cod(novo_cod.trim());
          if (comentario_alterado === 0) {
            setComentario_alterado(1);
          } else {
            setComentario_alterado(0);
          }
        }
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Alterar nome do núcleo
  async function alterarNome() {
    const data = {
      rem_id,
      nui_id,
      user,
      nui_nome,
      novo_nome
    }
    await api.post('/nucleo/AlterarNome.php', JSON.stringify(data))
      .then(function (rp) {
        setMensagem_identificacao(rp.data.msg);
        if (rp.data.sucesso === true) {
          setNui_nome(novo_nome.trim());
          if (comentario_alterado === 0) {
            setComentario_alterado(1);
          } else {
            setComentario_alterado(0);
          }
        }
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Texto projeto concluído
  async function textoConcluido() {
    var texto = '';
    var requerentes = '';
    var contador = 0;
    lista_processos.map((item) => {
      if (item.reurb_situacao === 'Ativo') {
        requerentes = requerentes + '\n' + item.unid_cod + ' - ' + item.beneficiarios;
        contador++;
      }
    });
    texto = 'O projeto de regularização fundiária do núcleo ' + nui_nome + ' (' + nui_cod + ') foi concluído.\n\nLista de beneficiários (' + contador + '):' + requerentes;
    if (texto_observacao !== "") {
      texto = texto + '\n\nObservações:\n' + texto_observacao;
    }
    setTexto_etapa(texto);
  }

  //Alterar etapa
  async function alterarEtapa() {
    const data = {
      user,
      rem_id,
      nui_id,
      texto_etapa,
      nova_etapa
    }
    await api.post('/nucleo/AlterarEtapa.php', JSON.stringify(data))
      .then(function (rp) {
        if (rp.data.sucesso === true) {
          onEtapaClose();
          setNui_etapa(nova_etapa);
          if (comentario_alterado === 0) {
            setComentario_alterado(1);
          } else {
            setComentario_alterado(0);
          }
        }
        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
        })
        );
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
          return (toast({
            position: 'bottom-left', render: () => (<Box color='white' p={3} bg='red'>Erro: não foi possível registrar os dados!</Box>),
          })
          );
        }
      });
  }

  //Texto do protocolo
  function handleProtocolo() {
    if (!isTextoProtocoloOpen) {
      onTextoProtocoloToggle();
    }
  }

  //Salvar protocolo
  async function salvarProtocolo() {
    const data = {
      user,
      rem_id,
      nui_id,
      nui_protocolo_prefeitura,
      nui_protocolo_registro,
      texto_protocolo
    }
    await api.post('/nucleo/SalvarProtocolo.php', JSON.stringify(data))
      .then(function (rp) {
        if (rp.data.sucesso === true) {
          onProtocoloClose();
          if (comentario_alterado === 0) {
            setComentario_alterado(1);
          } else {
            setComentario_alterado(0);
          }
        }
        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
        })
        );
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
          return (toast({
            position: 'bottom-left', render: () => (<Box color='white' p={3} bg='red'>Erro: não foi possível registrar os dados!</Box>),
          })
          );
        }
      });
  }

  //Salvar diligência
  async function salvarDiligencia() {
    const data = {
      user,
      rem_id,
      nui_id,
      nui_diligencia_orgao,
      texto_diligencia
    }
    await api.post('/nucleo/SalvarDiligencia.php', JSON.stringify(data))
      .then(function (rp) {
        if (rp.data.sucesso === true) {
          if (nui_diligencia_respondida === 1) {
            setNui_diligencia_respondida(0);
          } else {
            setNui_diligencia_respondida(1);
          }
          setTexto_diligencia('');
          onDiligenciaClose();
          if (comentario_alterado === 0) {
            setComentario_alterado(1);
          } else {
            setComentario_alterado(0);
          }
        }
        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
        })
        );
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
          return (toast({
            position: 'bottom-left', render: () => (<Box color='white' p={3} bg='red'>Erro: não foi possível registrar os dados!</Box>),
          })
          );
        }
      });
  }

  //Excluir núcleo
  async function excluirNucleo() {
    const data = {
      rem_id,
      nui_id
    }
    await api.post('/nucleo/ExcluirNucleo.php', JSON.stringify(data))
      .then(function (rp) {
        //Ir para núcleos
        navigate('/nucleos');
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Ir para núcleos
  function irParaNucleos() {
    navigate('/nucleos');
  }

  //Ir para processo
  function irParaProcesso(id) {
    setUnid_id(id);
    if (id !== 0) {
      navigate('/Processo', { replace: true });
    }
  }

  return (
    <div id="exibir_nucleo">
      <Container maxW={'95%'} mb={20}>
        <Flex direction='row'>
          <Button colorScheme="teal" w={'5%'} rounded={'none'} h={'58px'} onClick={e => irParaNucleos()}><ChevronLeftIcon boxSize={8} /></Button>
          <Flex background={'white'} border={'1px'} p={2} w={'95%'}>
            <Heading size={'md'} color={'gray.700'} mt={2} ml={3}>Núcleo {nui_nome + " (" + nui_cod + ")"}</Heading>
            <Spacer />
            <ButtonGroup gap='4'>
              <Button colorScheme="teal" variant='outline' onClick={e => setEditar_nucleo(1)}>Editar<EditIcon ml={2} /></Button>
              <Button colorScheme="teal" variant='outline' onClick={e => onExcluirOpen()}>Excluir<DeleteIcon ml={2} /></Button>
            </ButtonGroup>
          </Flex>
        </Flex>

        <SimpleGrid columns={2} spacing={5} mt={5}>
          <Flex direction="column" background={'gray.200'} p={4}>
            <Heading as='h4' size='md' color={'gray.600'}>Núcleo</Heading>
            <Grid
              templateRows='repeat(1, 1fr)'
              templateColumns='repeat(12, 1fr)'
              gap={10}
            >
              <GridItem colSpan={8}>
                <Text color={'nui_nome' === "Nome Não Informado" ? 'red' : 'black'}>{nui_nome + " (" + nui_cod + ")"}</Text>
                <Text display={nui_situacao === "Situação Não Informada" ? 'none' : 'block'}>{"Situação " + nui_situacao}</Text>
                <Text display={nui_situacao === "Situação Não Informada" ? 'block' : 'none'} color={'red'}>{nui_situacao}</Text>
                <Text color={total === 0 ? 'red' : 'black'}>Processos Vinculados: {total}</Text>
                <Text display={cancelado > 0 ? 'block' : 'none'}>Processos Cancelados: {cancelado}</Text>
                <Text display={pendente > 0 ? 'block' : 'none'} color={'red'}>Processos Pendentes: {pendente}</Text>

              </GridItem>
              <GridItem colSpan={4} textAlign={'right'} pr={2}>
                <Flex direction={'column'} alignItems={'end'}>
                  <Button colorScheme="teal" variant='outline' w={'150px'} mb={3} onClick={e => { setMensagem_identificacao(""); carregarNomesUsados(); onCodigoOpen(); }}>Identificação<BarCodeIcon ml={2} /></Button>
                  <Button colorScheme="teal" variant='outline' w={'150px'} mb={3} onClick={e => onSituacaoOpen()}>Situação<NotAllowedIcon ml={2} /></Button>
                </Flex>
              </GridItem>
            </Grid>
          </Flex>

          <Flex direction="column" background={'gray.200'} p={4}>
            <Heading as='h4' size='md' color={'gray.600'}>Reurb</Heading>
            <Grid
              templateRows='repeat(1, 1fr)'
              templateColumns='repeat(12, 1fr)'
              gap={10}
            >
              <GridItem colSpan={8}>
                <Text>{nui_etapa}</Text>
                <Text color={nui_modalidade === "Sem Modalidade" ? 'red' : 'black'}>{nui_modalidade}</Text>
                <Text color={nui_objeto === "Objeto Não Informado" ? 'red' : 'black'}>{nui_objeto}</Text>
                <Text color={nui_instrumento === "Instrumento Não Informado" ? 'red' : 'black'}>{nui_instrumento}</Text>
              </GridItem>

              <GridItem colSpan={4} textAlign={'right'} pr={2}>
                <Flex direction={'column'} alignItems={'end'}>
                  <Button colorScheme="teal" variant='outline' w={'150px'} mb={3} onClick={e => onProtocoloOpen()}>Protocolo<ProtocoloIcon ml={2} /></Button>
                  <Button colorScheme={nui_diligencia_aberta === 1 && nui_diligencia_respondida === 0 ? 'orange' : 'teal'} variant='outline' w={'150px'} mb={3} onClick={e => onDiligenciaOpen()}>Diligência<AlertIcon ml={2} /></Button>
                </Flex>
              </GridItem>

            </Grid>
          </Flex>

          <Flex direction="column" gap={5}>
            <Flex direction="column" background={'gray.200'} p={4} minH={'110px'}>
              <Heading as='h4' size='md' color={'gray.600'}>Localização</Heading>
              <Text color={localizacao === "Dados Incompletos" ? 'red' : 'black'} pr={'200px'}>{localizacao}</Text>
            </Flex>

            <Flex direction="column" background={'gray.200'} p={4}>
              <Heading as='h4' size='md' color={'gray.600'}>Projeto</Heading>
              <Grid
                templateRows='repeat(1, 1fr)'
                templateColumns='repeat(12, 1fr)'
                gap={10}
              >
                <GridItem colSpan={7}>
                  <Text color={matricula === "Falta Análise das Matrículas" ? 'red' : 'black'}>{matricula}</Text>
                  <Text>{app}</Text>
                  <Text>{risco}</Text>
                  <Text>Etapa Pendente</Text>
                  <Text color={matricula === "Sem Responsável" ? 'red' : 'black'}>{responsavel}</Text>
                </GridItem>
                <GridItem colSpan={5} textAlign={'right'} pr={2}>
                  <Flex direction={'column'} alignItems={'end'}>
                    <Button colorScheme="teal" variant='outline' w={'150px'} mb={3} onClick={e => onEtapaOpen()}>Etapa<StepIcon ml={2} /></Button>
                  </Flex>
                </GridItem>
              </Grid>
            </Flex>
          </Flex>

          <Flex direction="column" borderColor={'gray'} borderWidth={'thin'} p={4}>
            <SimpleGrid columns={2} spacing={5}>
              <Stack direction='column'>
                <Text textAlign={'center'}>Situação dos Processos</Text>
                <Center display={total === 0 ? 'flex' : 'none'} h={'180px'}>
                  <Text as={'b'} color={'gray.400'} fontSize='20px' w={'50%'} textAlign={'center'}>Não Há Processos Vinculados</Text>
                </Center>
                <Flex display={total > 0 ? 'block' : 'none'}>
                  <Chart
                    chartType="PieChart"
                    data={dataSituacao}
                    options={optionsSituacao}
                  />
                </Flex>
              </Stack>
              <Stack direction='column'>
                <Text textAlign={'center'}>Modalidade dos Processos Ativos</Text>
                <Center display={ativo === 0 ? 'flex' : 'none'} h={'180px'}>
                  <Text as={'b'} color={'gray.400'} fontSize='20px' w={'50%'} textAlign={'center'}>Não Há Processos Ativos</Text>
                </Center>
                <Flex display={ativo > 0 ? 'block' : 'none'}>
                  <Chart
                    chartType="PieChart"
                    data={dataModalidade}
                    options={optionsModalidade}
                  />
                </Flex>
              </Stack>
            </SimpleGrid>
            <Button onClick={e => { carregarSocioeconomico(); onSocioeconomicoOpen(); }}>Dados Socioeconômicos</Button>
          </Flex>

        </SimpleGrid>


        <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
        <Heading as='h4' size='md' color={'gray.600'}>Descrição</Heading>

        <Text as={'b'} mt={5} display={texto_matricula !== "" ? 'block' : 'none'}>Análise de Matrículas</Text>
        <Divider mt={1} mb={2} borderColor={'gray.400'} w={'30%'} display={texto_matricula !== "" ? 'block' : 'none'} />
        <Text display={texto_matricula !== "" ? 'block' : 'none'}>{texto_matricula}</Text>

        <Text as={'b'} mt={5} display={texto_app !== "" ? 'block' : 'none'}>Dados da Área de Relevância Ambiental</Text>
        <Divider mt={1} mb={2} borderColor={'gray.400'} w={'30%'} display={texto_app !== "" ? 'block' : 'none'} />
        <Text display={texto_app !== "" ? 'block' : 'none'}>{texto_app}</Text>

        <Text as={'b'} mt={5} display={texto_risco !== "" ? 'block' : 'none'}>Dados da Área de Risco</Text>
        <Divider mt={1} mb={2} borderColor={'gray.400'} w={'30%'} display={texto_risco !== "" ? 'block' : 'none'} />
        <Text display={texto_risco !== "" ? 'block' : 'none'}>{texto_risco}</Text>

        <Text as={'b'} mt={5} display={texto_observacao !== "" && texto_matricula + texto_app + texto_risco != '' ? 'block' : 'none'}>Observações</Text>
        <Divider mt={1} mb={2} borderColor={'gray.400'} w={'30%'} display={texto_observacao !== "" && texto_matricula + texto_app + texto_risco != '' ? 'block' : 'none'} />
        <Text display={texto_observacao !== "" ? 'block' : 'none'}>{texto_observacao}</Text>

        <Text mt={3} display={texto_matricula + texto_app + texto_risco + texto_observacao === "" ? 'block' : 'none'}>Sem Informações</Text>

        <Divider borderColor={'gray.400'} orientation='horizontal' mt={8} mb={4} />
        <Flex direction={'row'}>
          <Heading as='h4' size='md' color={'gray.600'}>Processos Vinculados ({total})</Heading>
          <Spacer />
          <Button colorScheme="teal" variant='outline' w={'150px'} mr={15} onClick={e => { lerVincular(); onVincularOpen(); }}>Vincular<LinkIcon ml={2} /></Button>
        </Flex>
        <Box w='100%' p='5px' mb='20px'>
          <TableContainer m={5}>
            <Table size='sm'>
              <Thead>
                <Tr>
                  <Th borderBottomColor={'gray.300'} textAlign={'center'}>CÓDIGO</Th>
                  <Th borderBottomColor={'gray.300'} textAlign={'center'}>BENEFICIÁRIOS</Th>
                  <Th borderBottomColor={'gray.300'} textAlign={'center'}>MODALIDADE</Th>
                  <Th borderBottomColor={'gray.300'} textAlign={'center'}>OBJETO</Th>
                  <Th borderBottomColor={'gray.300'} textAlign={'center'}>INSTRUMENTO</Th>
                  <Th borderBottomColor={'gray.300'} textAlign={'center'}>DADOS</Th>
                  <Th borderBottomColor={'gray.300'} textAlign={'center'}>LEPAC</Th>
                  <Th borderBottomColor={'gray.300'} textAlign={'center'}>SITUAÇÃO</Th>
                </Tr>
              </Thead>
              <Tbody>
                {lista_processos.map((item, i) => {
                  return (
                    <Tr key={i} cursor='pointer' _hover={{ bg: 'gray.100' }} onClick={e => irParaProcesso(item.unid_id)} bg={item.reurb_situacao === 'Cancelado' ? 'red.100' : 'white'}>
                      <Td borderBottomColor={'gray.300'} textAlign={'center'}>{item.unid_cod}</Td>
                      <Td borderBottomColor={'gray.300'} whiteSpace={'pre-wrap'} maxW={'300px'}>{item.beneficiarios}</Td>
                      <Td borderBottomColor={'gray.300'} textAlign={'center'}>{item.reurb_modalidade}</Td>
                      <Td borderBottomColor={'gray.300'} whiteSpace={'pre-wrap'} maxW={'200px'}>{item.reurb_objeto}</Td>
                      <Td borderBottomColor={'gray.300'}>{item.reurb_instrumento}</Td>
                      <Td borderBottomColor={'gray.300'} textAlign={'center'} color={item.dados === 'Incompleto' ? 'red' : 'black'}>{item.dados}</Td>
                      <Td borderBottomColor={'gray.300'} textAlign={'center'} color={item.lepac === 'Pendente' ? 'red' : 'black'}>{item.lepac}</Td>
                      <Td borderBottomColor={'gray.300'} textAlign={'center'}>{item.reurb_situacao}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>

        <Comentarios painel="nucleo" />

      </Container>

      {/*MODAL VINCULAR*/}
      <Modal isOpen={isVincularOpen} onClose={onVincularClose} isCentered size='6xl'>
        <ModalOverlay />
        <ModalContent bg='gray.100'>
          <ModalHeader textAlign='center' p={5}>
            VINCULAR PROCESSOS
          </ModalHeader>
          <Flex w={'200px'} mt={-10} ml={5} mb={5}>
            <Button w={'100%'} display={bloquear === 1 ? 'content' : 'none'} bg='none' p='auto' _hover={{ bg: 'white' }} onClick={e => setBloquear(0)}><Stack direction={'row'}><LockIcon ml={2} boxSize={5} color={'red.500'} /><Text textAlign={'center'}>Bloqueado</Text></Stack></Button>
            <Button w={'100%'} display={bloquear === 1 ? 'none' : 'content'} bg='none' p='auto' _hover={{ bg: 'white' }} onClick={e => setBloquear(1)}><Stack direction={'row'}><UnlockIcon ml={2} boxSize={5} color={'green.500'} /><Text textAlign={'center'}>Desbloqueado</Text></Stack></Button>
          </Flex>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={2} gap={5} mb={5}>
              <Flex direction={'column'}>
                <Center borderColor={'gray.400'} borderWidth={'thin'} borderRadius={10} p={2} mb={2}>
                  <Text textAlign='center'>Processos Sem Núcleo ({cont_semnucleo})</Text>
                </Center>
                <Box
                  overflowX="auto"
                  whiteSpace="nowrap"
                  pb="17px"
                  sx={'::-webkit-scrollbar'}
                  px="32px"
                  w='100%' bg="white" p='5px' height='400px'>
                  <TableContainer m={1} >
                    <Table size='sm'>
                      <Tbody>
                        {lista_semnucleo.map((item, i) => {
                          return (
                            <Tr key={i} cursor={bloquear === 0 ? 'pointer' : 'not-allowed'} _hover={{ bg: bloquear === 0 ? 'gray.100' : 'white' }} onClick={e => vincularProcesso(item.unid_id)}>
                              <Td maxW={'50px'}>{item.unid_cod}</Td>
                              <Td maxW={'200px'}><Text isTruncated>{item.principal}</Text></Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              </Flex>
              <Flex direction={'column'}>
                <Center borderColor={'gray.400'} borderWidth={'thin'} borderRadius={10} p={2} mb={2}>
                  <Text textAlign='center'>Processos Vinculados ao Núcleo ({cont_vinculados})</Text>
                </Center>
                <Box
                  overflowX="auto"
                  whiteSpace="nowrap"
                  pb="17px"
                  sx={'::-webkit-scrollbar'}
                  px="32px"
                  w='100%' bg="white" p='5px' height='400px'>
                  <TableContainer m={1}>
                    <Table size='sm'>
                      <Tbody>
                        {lista_vinculados.map((item, i) => {
                          return (
                            <Tr key={i} cursor={bloquear === 0 ? 'pointer' : 'not-allowed'} _hover={{ bg: bloquear === 0 ? 'gray.100' : 'white' }} onClick={e => desvincularProcesso(item.unid_id)}>
                              <Td maxW={'50px'}>{item.unid_cod}</Td>
                              <Td maxW={'200px'}><Text isTruncated>{item.principal}</Text></Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              </Flex>
            </SimpleGrid>

          </ModalBody>
        </ModalContent>
      </Modal>

      {/*MODAL ALTERAR SITUAÇÃO*/}
      <Modal isOpen={isSituacaoOpen} onClose={onSituacaoClose} isCentered size='2xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center'>ALTERAR SITUAÇÃO DO NÚCLEO</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid rows={3} spacing={10} m={5}>
              <Box>
                <Center>
                  <Stack spacing={6} direction='row'>
                    <Button colorScheme={novasit === "Ativo" ? 'green' : 'blackAlpha'} w={'150px'} h={'50px'} onClick={e => setNovasit("Ativo")}>Ativo</Button>
                    <Button colorScheme={novasit === "Pendente" ? 'green' : 'blackAlpha'} w={'150px'} h={'50px'} onClick={e => setNovasit("Pendente")}><Text>Pendente</Text></Button>
                    <Button colorScheme={novasit === "Cancelado" ? 'green' : 'blackAlpha'} w={'150px'} h={'50px'} onClick={e => setNovasit("Cancelado")}><Text>Cancelado</Text></Button>
                  </Stack>
                </Center>
              </Box>
              <Box>
                <Center>
                  <Text display={nui_situacao === novasit ? 'block' : 'none'}>A situação do núcleo {nui_cod} está definida como {nui_situacao}.</Text>
                  <Text display={nui_situacao === novasit ? 'none' : 'block'}>Tem certeza que deseja alterar a situação do núcleo {nui_cod} para {novasit}?</Text>
                </Center>
              </Box>
              <Box>
                <Textarea display={nui_situacao === novasit ? 'none' : 'block'} borderColor={'gray'} w="100%" minRows={3} placeholder={'Informe o motivo da alteração da situação (obrigatório)'} value={motivo_situacao} onChange={e => setMotivo_situacao(e.target.value)} />
              </Box>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button display={nui_situacao === novasit ? 'none' : 'block'} colorScheme="teal" variant='outline' onClick={e => alterarSituacao()}>Alterar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/*MODAL CÓDIGO*/}
      <Modal isOpen={isCodigoOpen} onClose={onCodigoClose} isCentered size='6xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center'>IDENTIFICAÇÃO DO NÚCLEO</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid
              h='120px'
              templateRows='repeat(3, 1fr)'
              templateColumns='repeat(12, 1fr)'
              gap={4}
              m={8}
            >
              <GridItem colSpan={9} >
                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'} mt={2}>Código</Text><Input maxLength={15} size='md' value={novo_cod} onChange={e => setNovo_cod(e.target.value)} /></Stack>
              </GridItem>
              <GridItem colSpan={3}>
                <Button w={'100%'} colorScheme="teal" variant='outline' onClick={e => alterarCodigo()}>Alterar Código</Button>
              </GridItem>
              <GridItem colSpan={9} >
                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'} mt={2}>Nome</Text><Input maxLength={150} size='md' value={novo_nome} onChange={e => setNovo_nome(e.target.value)} /></Stack>
              </GridItem>
              <GridItem colSpan={3}>
                <Button w={'100%'} colorScheme="teal" variant='outline' onClick={e => alterarNome()}>Alterar Nome</Button>
              </GridItem>
              <GridItem colSpan={12}>
                <Text textAlign={'center'} color={'red.700'} display={mensagem_identificacao === "" ? 'none' : 'block'}>{mensagem_identificacao}</Text>
              </GridItem>
            </Grid>

            <Accordion allowToggle m={5} mb={10}>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex='1' textAlign='left'>
                      Lista de Códigos e Nomes Usados
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel >
                  <Box w='100%' bg="gray.100" p='5px' mt='20px' mb='20px'>
                    <TableContainer m={5}>
                      <Table size='sm'>
                        <Thead>
                          <Tr>
                            <Th borderBottomColor={'gray.300'} textAlign={'center'}>CÓDIGO</Th>
                            <Th borderBottomColor={'gray.300'} textAlign={'center'}>NOME</Th>
                            <Th borderBottomColor={'gray.300'} textAlign={'center'}>MUNICÍPIO</Th>
                            <Th borderBottomColor={'gray.300'} textAlign={'center'}>REMESSA</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {lista_nomes.map((item, i) => {
                            return (
                              <Tr key={i}>
                                <Td borderBottomColor={'gray.300'} textAlign={'center'}>{item.codigo}</Td>
                                <Td borderBottomColor={'gray.300'}>{item.nome}</Td>
                                <Td borderBottomColor={'gray.300'} textAlign={'center'}>{item.municipio}</Td>
                                <Td borderBottomColor={'gray.300'} textAlign={'center'}>{item.remessa}</Td>
                              </Tr>
                            );
                          })}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>

          </ModalBody>
        </ModalContent>
      </Modal>

      {/*MODAL ETAPA*/}
      <Modal isOpen={isEtapaOpen} onClose={onEtapaClose} isCentered size='5xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center'>ALTERAR ETAPA DO PROJETO</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction={'column'} p={5} gap={5}>
              <Flex direction={'row'}>
                <Stack spacing={3} direction='row'><Text mt={1} fontSize={'lg'} whiteSpace='nowrap'>Etapa do Projeto</Text>
                  <Select w={'300px'} borderColor={'gray'} size='md' value={nova_etapa} onChange={e => setNova_etapa(e.target.value)}>
                    <option key={0} value={'Pendente'}>Pendente</option>
                    <option key={1} value={'Aguardando'}>Aguardando</option>
                    <option key={2} value={'Em Andamento'}>Em Andamento</option>
                    <option key={3} value={'Concluído'}>Concluído</option>
                    <option key={4} value={'Em Revisão'}>Em Revisão</option>
                  </Select>
                </Stack>
                <Spacer />
                <Button display={nova_etapa === 'Concluído' ? 'block' : 'none'} onClick={e => textoConcluido()}>Descrição Sugerida</Button>
              </Flex>
              <Textarea borderColor={'gray'} bg={'white'}
                p={4}
                minH="unset"
                overflow="hidden"
                w="100%"
                resize="none"
                minRows={3}
                as={ResizeTextarea}
                placeholder="Observação sobre a alteração de etapa..."
                value={texto_etapa}
                onChange={e => setTexto_etapa(e.target.value)}
                textAlign={'justify'}
              />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" variant='outline' onClick={e => alterarEtapa()}>Concluir</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/*MODAL DILIGÊNCIA*/}
      <Modal isOpen={isDiligenciaOpen} onClose={onDiligenciaClose} isCentered size='5xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center'>CONTROLE DE DILIGÊNCIA</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction={'column'} m={5}>
              <Stack spacing={3} direction='row'>
                <Text mt={1} fontSize={'lg'} whiteSpace='nowrap'>{nui_diligencia_respondida === 0 ? 'Responder a diligência:' : 'Registrar nova diligência:'}</Text>
                <Spacer />
                <Text fontSize={'lg'} whiteSpace='nowrap'>Órgão</Text>
                <Select w={'300px'} borderColor={'gray'} size='md' value={nui_diligencia_orgao} onChange={e => setNui_diligencia_orgao(e.target.value)} disabled={nui_diligencia_respondida === 0 ? true : false}>
                  <option key={0} value={''}></option>
                  <option key={1} value={'Prefeitura'}>Prefeitura</option>
                  <option key={2} value={'Registro de Imóveis'}>Registro de Imóveis</option>
                </Select>
              </Stack>
              <Textarea borderColor={'gray'} bg={'white'}
                mt={5}
                p={4}
                minH="unset"
                overflow="hidden"
                w="100%"
                resize="none"
                minRows={3}
                as={ResizeTextarea}
                placeholder={nui_diligencia_respondida === 0 ? "Descrição da resposta da diligência..." : "Descrição dos dados da diligência..."}
                value={texto_diligencia}
                onChange={e => setTexto_diligencia(e.target.value)}
                textAlign={'justify'}
              />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" variant='outline' onClick={e => salvarDiligencia()}>{nui_diligencia_respondida === 0 ? 'Encerrar Diligência' : 'Registrar Diligência'}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/*MODAL PROTOCOLO*/}
      <Modal isOpen={isProtocoloOpen} onClose={onProtocoloClose} isCentered size='5xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center'>CONTROLE DE PROTOCOLO</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" m={5}>
              <SimpleGrid columns={2} m={5}>
                <Stack direction="column">
                  <Text>O projeto já foi protocolado na Prefeitura?</Text>
                  <Stack direction={'row'} m={5} spacing={5}>
                    <Center cursor='pointer' borderRadius={'10px'} bg={nui_protocolo_prefeitura === 1 ? 'gray.200' : 'teal.200'} w={'100px'} h={'40px'} onClick={e => { setNui_protocolo_prefeitura(0); handleProtocolo(); }}>NÃO</Center>
                    <Center cursor='pointer' borderRadius={'10px'} bg={nui_protocolo_prefeitura === 1 ? 'teal.200' : 'gray.200'} w={'100px'} h={'40px'} onClick={e => { setNui_protocolo_prefeitura(1); handleProtocolo(); }}>SIM</Center>
                  </Stack>
                </Stack>
                <Stack direction="column">
                  <Text>O projeto já foi protocolado no Registro de Imóveis?</Text>
                  <Stack direction={'row'} m={5} spacing={5}>
                    <Center cursor='pointer' borderRadius={'10px'} bg={nui_protocolo_registro === 1 ? 'gray.200' : 'teal.200'} w={'100px'} h={'40px'} onClick={e => { setNui_protocolo_registro(0); handleProtocolo(); }}>NÃO</Center>
                    <Center cursor='pointer' borderRadius={'10px'} bg={nui_protocolo_registro === 1 ? 'teal.200' : 'gray.200'} w={'100px'} h={'40px'} onClick={e => { setNui_protocolo_registro(1); handleProtocolo(); }}>SIM</Center>
                  </Stack>
                </Stack>
              </SimpleGrid>
              <Collapse in={isTextoProtocoloOpen} animateOpacity>
                <Textarea borderColor={'gray'} bg={'white'}
                  mt={5}
                  p={4}
                  minH="unset"
                  overflow="hidden"
                  w="100%"
                  resize="none"
                  minRows={3}
                  as={ResizeTextarea}
                  placeholder="Observação sobre o protocolo..."
                  value={texto_protocolo}
                  onChange={e => setTexto_protocolo(e.target.value)}
                  textAlign={'justify'}
                />
              </Collapse>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" variant='outline' onClick={e => salvarProtocolo()}>Concluir</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/*MODAL SOCIOECONOMICO*/}
      <Modal isOpen={isSocioeconomicoOpen} onClose={onSocioeconomicoClose} isCentered size='4xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center' p={5}>
            DADOS SOCIOECONÔMICOS
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs isFitted mb={10} ml={5} mr={5}>
              <TabList backgroundColor={'gray.200'} h={'50px'}>
                <Tab className="dados" _selected={{ color: 'white', bg: 'gray' }}><Heading as='h5' size='sm'>PROFISSÕES</Heading></Tab>
                <Tab className="lepac" _selected={{ color: 'white', bg: 'gray' }}><Heading as='h5' size='sm'>RENDA FAMILIAR</Heading></Tab>
                <Tab className="area" _selected={{ color: 'white', bg: 'gray' }}><Heading as='h5' size='sm'>VULNERABILIDADE SOCIAL</Heading></Tab>
              </TabList>
              <TabPanels>

                <TabPanel className="profissoes">
                  <TableContainer m={1} >
                    <Table size='sm'>
                      <Thead>
                        <Tr>
                          <Th textAlign={'center'}>PROFISSÃO</Th>
                          <Th textAlign={'center'}>FREQUÊNCIA</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {lista_profissoes.map((item, i) => {
                          return (
                            <Tr key={i}>
                              <Td textAlign={'center'}>{item.profissao}</Td>
                              <Td textAlign={'center'}>{item.frequencia}</Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </TabPanel>

                <TabPanel className="renda">
                  <TableContainer m={1} >
                    <Table size='sm'>
                      <Thead>
                        <Tr>
                          <Th textAlign={'center'}>CÓDIGO</Th>
                          <Th textAlign={'center'}>OCUPANTES</Th>
                          <Th textAlign={'center'}>RENDA FAMILIAR</Th>
                          <Th textAlign={'center'}>RENDA PER CAPITA</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {lista_socioeconomico.map((item, i) => {
                          return (
                            <Tr key={i}>
                              <Td textAlign={'center'}>{item.req_tipo === 4 ? item.unid_cod + ' *' : item.unid_cod}</Td>
                              <Td textAlign={'center'}>{item.ocupantes}</Td>
                              <Td textAlign={'center'}>{item.renda_familiar}</Td>
                              <Td textAlign={'center'}>{item.renda_percapita}</Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                  <Text m={5}>* Processo com beneficiário do tipo pessoa jurídica</Text>
                </TabPanel>

                <TabPanel className="vulnerabilidade">
                  <TableContainer m={1} >
                    <Table size='sm'>
                      <Thead>
                        <Tr>
                          <Th textAlign={'center'}>CÓDIGO</Th>
                          <Th textAlign={'center'}>OCUPANTE IDOSO</Th>
                          <Th textAlign={'center'}>OCUPANTE COM DEFICIÊNCIA</Th>
                          <Th textAlign={'center'}>MULHER CHEFE DE FAMÍLIA</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {lista_socioeconomico.map((item, i) => {
                          return (
                            <Tr key={i}>
                              <Td textAlign={'center'}>{item.req_tipo === 4 ? item.unid_cod + ' *' : item.unid_cod}</Td>
                              <Td textAlign={'center'}>{item.idoso === 0 ? '-' : 'Ocorre ' + item.idoso}</Td>
                              <Td textAlign={'center'}>{item.deficiente === 0 ? '-' : 'Ocorre ' + item.deficiente}</Td>
                              <Td textAlign={'center'}>{item.mulher_chefe === true ? 'SIM' : '-'}</Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                  <Text m={5}>* Processo com beneficiário do tipo pessoa jurídica</Text>
                </TabPanel>

              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/*ALERT EXCLUIR*/}
      <AlertDialog isOpen={isExcluirOpen} leastDestructiveRef={cancelRef.current} onClose={onExcluirClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Excluir Núcleo
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza que deseja excluir o núcleo {nui_cod}?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef.current} onClick={onExcluirClose}>
                Cancelar
              </Button>
              <Button colorScheme='red' onClick={() => { excluirNucleo(); onExcluirClose(); }} ml={3}>
                Excluir
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

    </div>
  );
}

export default ExibirNucleo;