import React, { useEffect } from "react";
import { useContext, useState } from "react";
import ResizeTextarea from "react-textarea-autosize";
import { Text, Button, Container, Heading, Input, Flex, Spacer, ButtonGroup, Box, Textarea, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Divider, Grid, GridItem, useToast, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, useDisclosure, CircularProgress } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon, DeleteIcon, DownloadIcon, SpinnerIcon } from "@chakra-ui/icons";
import { UploadIcon } from "../../icons/uploadicon";

import { api } from "../../../services/api";
import DadosRemessa from "../../components/Remessa/dadosremessa";

import NavSuperior from "../../components/NavBar/navsuperior";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

const ImportarPage = () => {

  //Variáveis do contexto
  const { logout } = useContext(AuthContext);
  const { rem_id } = useContext(ReurbContext);

  //Variáveis Chakra UI
  const cancelRef = React.useRef();
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen: isExcluirOpen, onOpen: onExcluirOpen, onClose: onExcluirClose } = useDisclosure();
  const { isOpen: isProcessarOpen, onOpen: onProcessarOpen, onClose: onProcessarClose } = useDisclosure();
  const { isOpen: isAguardeOpen, onOpen: onAguardeOpen, onClose: onAguardeClose } = useDisclosure();

  //Variáveis locais
  const [id_selecionado, setId_selecionado] = useState(0);
  const [controle, setControle] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [qtd_formularios, setQtd_formularios] = useState(0);
  const [lista_formularios, setLista_formularios] = useState([{ id: 0, nome: '-', nucleos: 0, processos: 0, requerentes: 0, ocupantes: 0, descricao: '-', processado: 0, criado: '-' }]);
  const [arquivo, setArquivo] = useState();
  const [descricao, setDescricao] = useState('');

  //Ler dados
  useEffect(() => {
    api.post('/importar/LerImportar.php', JSON.stringify({ rem_id }))
      .then(function (rp) {
        setQtd_formularios(rp.data.qtd_formularios);
        setLista_formularios(rp.data.lista_formularios);
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }, [controle]);

  //Pegar arquivo
  const onChangeArquivo = e => {
    setArquivo(e.target.files[0]);
  };

  //Enviar arquivo
  function enviarArquivo() {
    var formData = new FormData();
    formData.append('file', arquivo);
    formData.append('rem_id', rem_id);
    formData.append('descricao', descricao);
    api.post('/importar/SalvarArquivo.php', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(rp => {
      if (rp.data.sucesso) {
        setControle(!controle);
        setDescricao('');
        setMensagem('');
        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg={'teal'}>{rp.data.msg}</Box>),
        })
        );
      } else {
        setMensagem(rp.data.msg);
      }
    });
  }

  //Processar arquivo
  async function processarArquivo() {
    if (id_selecionado > 0) {
      const data = {
        rem_id,
        for_id: id_selecionado
      }
      onAguardeOpen();
      await api.post('/importar/ImportarDados.php', JSON.stringify(data))
        .then(function (rp) {
          if (rp.data.sucesso) {
            setControle(!controle);
          }
          onAguardeClose();
          return (toast({
            position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
          })
          );
        })
        .catch(function (error) {
          if (error.response.data === "EXPIRED") {
            logout();
          } else {
            console.log(error);
          }
        });
      setId_selecionado(0);
    }
  }

  //Excluir arquivo
  async function excluirArquivo() {
    if (id_selecionado > 0) {
      const data = {
        for_id: id_selecionado
      }
      await api.post('/importar/ExcluirArquivo.php', JSON.stringify(data))
        .then(function (rp) {
          if (rp.data.sucesso) {
            setControle(!controle);
          }
          return (toast({
            position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
          })
          );
        })
        .catch(function (error) {
          if (error.response.data === "EXPIRED") {
            logout();
          } else {
            console.log(error);
          }
        });
      setId_selecionado(0);
    }
  }

  //Baixar arquivo
  function baixarArquivo(id, nome) {
    if (id > 0) {
      const data = {
        for_id: id
      }
      api.post('/importar/BaixarArquivo.php', JSON.stringify(data), { responseType: 'blob' }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', nome);
        document.body.appendChild(link);
        link.click();
      });
    }
  }

  //Ir para remessa
  function goToRemessa() {
    navigate('/remessa');
  }

  return (
    <div id="importar">
      <NavSuperior />
      <DadosRemessa titulo="Importar Dados" />
      <Container maxW='95%' bg='white' mb={20}>
        <Flex direction='row' mb={5}>
          <Button colorScheme="teal" w={'5%'} rounded={'none'} h={'58px'} onClick={e => goToRemessa()}><ChevronLeftIcon boxSize={8} /></Button>
          <Flex background={'white'} border={'1px'} p={2} w={'95%'}>
            <Heading size={'md'} color={'gray.700'} mt={2} ml={3}>{'Formulários Importados (' + qtd_formularios + ')'}</Heading>
            <Spacer />
            <ButtonGroup gap='4'>
            </ButtonGroup>
          </Flex>
        </Flex>
        <Flex direction='column'>
          <Heading as='h4' size='md' textAlign={'left'} mt={6}>
            Importar Formulário
          </Heading>
          <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
          <Grid
            m={5}
            templateRows='repeat(1, 1fr)'
            templateColumns='repeat(12, 1fr)'
            gap={5}
          >
            <GridItem colSpan={5}>
              <Input borderColor={'gray'} p={5} type="file" onChange={e => onChangeArquivo(e)} h={'80px'} />
            </GridItem>
            <GridItem colSpan={7}>
              <Textarea borderColor={'gray'} bg={'white'}
                p={4}
                minH="unset"
                overflow="hidden"
                w="100%"
                resize="none"
                minRows={2}
                as={ResizeTextarea}
                placeholder="Descrição do arquivo..."
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
                textAlign={'justify'}
              />
            </GridItem>
          </Grid>
          <Flex direction={'row'} m={5} mt={0}>
            <Text color={'red'}>{mensagem}</Text>
            <Spacer />
            <Button colorScheme="teal" variant='outline' onClick={e => enviarArquivo()}>Enviar<UploadIcon ml={2} /></Button>
          </Flex>
          <Heading as='h4' size='md' textAlign={'left'} mt={6}>
            Formulários Importados
          </Heading>
          <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
          <Text size={'md'} display={parseInt(qtd_formularios) === 0 ? 'block' : 'none'}>Não há formulários importados!</Text>
          <TableContainer m={5} display={parseInt(qtd_formularios) === 0 ? 'none' : 'block'}>
            <Table size='sm'>
              <Thead>
                <Tr>
                  <Th textAlign={'center'}>N.</Th>
                  <Th textAlign={'center'}>NOME</Th>
                  <Th textAlign={'center'}>DESCRIÇÃO</Th>
                  <Th textAlign={'center'}>NÚCLEOS</Th>
                  <Th textAlign={'center'}>PROCESSOS</Th>
                  <Th textAlign={'center'}>REQUERENTES</Th>
                  <Th textAlign={'center'}>OCUPANTES</Th>
                  <Th textAlign={'center'}>DATA</Th>
                  <Th textAlign={'center'}>PROCESSAR</Th>
                  <Th textAlign={'center'}>OUTRAS AÇÕES</Th>
                </Tr>
              </Thead>
              <Tbody>
                {lista_formularios.map((item, i) => {
                  return (
                    <Tr key={i}>
                      <Td textAlign={'center'}>{i + 1}</Td>
                      <Td>{item.nome}</Td>
                      <Td whiteSpace={'pre-wrap'}>{item.descricao}</Td>
                      <Td textAlign={'center'}>{item.nucleos}</Td>
                      <Td textAlign={'center'}>{item.processos}</Td>
                      <Td textAlign={'center'}>{item.requerentes}</Td>
                      <Td textAlign={'center'}>{item.ocupantes}</Td>
                      <Td textAlign={'center'}>{item.criado}</Td>
                      <Td textAlign={'center'}>
                        <Text display={item.processado === 0 ? 'none' : 'block'}>Importado</Text>
                        <ButtonGroup gap='2' display={item.id === 0 || item.processado === 1 ? 'none' : 'block'}>
                          <Button colorScheme={'green'} variant={'outline'} onClick={e => { setId_selecionado(item.id); onProcessarOpen(); }}><SpinnerIcon /></Button>
                        </ButtonGroup>
                      </Td>
                      <Td textAlign={'center'}>
                        <ButtonGroup gap='2' display={item.id === 0 ? 'none' : 'block'}>
                          <Button colorScheme={'blue'} variant={'outline'} onClick={e => baixarArquivo(item.id, item.nome)}><DownloadIcon /></Button>
                          <Button colorScheme={'red'} variant={'outline'} onClick={e => { setId_selecionado(item.id); onExcluirOpen(); }} display={item.processado === 1 ? 'none' : 'inline'}><DeleteIcon /></Button>
                        </ButtonGroup>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Flex>
      </Container>

      {/*ALERT EXCLUIR*/}
      <AlertDialog isOpen={isExcluirOpen} leastDestructiveRef={cancelRef.current} onClose={onExcluirClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Excluir Formulário
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza que deseja excluir o formulário?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef.current} onClick={onExcluirClose}>
                Cancelar
              </Button>
              <Button colorScheme='red' onClick={e => { excluirArquivo(); onExcluirClose(); }} ml={3}>
                Excluir
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/*ALERT PROCESSAR*/}
      <AlertDialog isOpen={isProcessarOpen} leastDestructiveRef={cancelRef.current} onClose={onProcessarClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Processar Formulário
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza que deseja importar os dados do formulário para essa remessa?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef.current} onClick={onProcessarClose}>
                Cancelar
              </Button>
              <Button colorScheme='green' onClick={e => { processarArquivo(); onProcessarClose(); }} ml={3}>
                Processar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/*ALERT AGUARDE*/}
      <AlertDialog closeOnEsc={false} closeOnOverlayClick={false} isOpen={isAguardeOpen} leastDestructiveRef={cancelRef.current} onClose={onAguardeClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold' mt={2}>
              Processando Dados
            </AlertDialogHeader>

            <AlertDialogBody>
              <Flex direction={'column'} align={'center'} gap={5} mb={5}>
                <CircularProgress boxSize={8} isIndeterminate color='gray.500' />
                <Text>Importando dados para a remessa, aguarde...</Text>
              </Flex>
            </AlertDialogBody>

          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

    </div >
  );
}

export default ImportarPage;