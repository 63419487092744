import React from "react";
import { Image, Center, Container, Heading } from '@chakra-ui/react';

import NavSuperior from "../../components/NavBar/navsuperior";

const ContatoPage = () => {

  return (
    <div id="contato">
      <NavSuperior />
      <Center>
        <Container maxH={'400px'} maxW={'400px'} mt={"100px"}>
          <Image src={'https://www.unirio.br/propg/diretoria-de-inovacao-tecnologica-cultural-e-social/imagens/em-construcao/image'} />
          <Heading textAlign={'center'}>CONTATO</Heading>
        </Container>
      </Center>
    </div>
  );
}

export default ContatoPage;