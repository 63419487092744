import React from "react";
import { useContext, useState, useEffect } from "react";
import { Text, Button, Box, Container, Heading, Divider, useToast, Grid, GridItem, Stack, Input, Textarea, Select, ButtonGroup, Flex, Spacer } from '@chakra-ui/react';
import ResizeTextarea from "react-textarea-autosize";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { SaveIcon } from "../../icons/saveicon";

import { api } from "../../../services/api";

import { AuthContext } from "../../contexts/auth";

const EditarPerfil = () => {
  const { logout, setEditar_perfil, usuario_selecionado } = useContext(AuthContext);

  //Variáveis Chakra UI
  const toast = useToast();

  //Variáveis
  const [usu_nome, setUsu_nome] = useState("");
  const [usu_nascimento, setUsu_nascimento] = useState("");
  const [usu_email, setUsu_email] = useState("");
  const [usu_setor, setUsu_setor] = useState("");
  const [usu_cargo, setUsu_cargo] = useState("");
  const [usu_funcao, setUsu_funcao] = useState("");
  const [usu_formacao, setUsu_formacao] = useState("");

  //Ler dados usuário
  useEffect(() => {
    const data = {
      usu_id: usuario_selecionado
    }
    api.post('/usuario/LerEditarUsuario.php', JSON.stringify(data))
      .then(function (rp) {
        setUsu_nome(rp.data.usu_nome);
        setUsu_nascimento(rp.data.usu_nascimento);
        setUsu_email(rp.data.usu_email);
        setUsu_setor(rp.data.usu_setor);
        setUsu_cargo(rp.data.usu_cargo);
        setUsu_funcao(rp.data.usu_funcao);
        setUsu_formacao(rp.data.usu_formacao);
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }, [usuario_selecionado]);

  //Salvar dados perfil
  async function salvarPerfil() {
    const data = {
      usu_id: usuario_selecionado,
      usu_nome,
      usu_nascimento,
      usu_email,
      usu_setor,
      usu_cargo,
      usu_funcao,
      usu_formacao
    }
    api.post('/usuario/SalvarUsuario.php', JSON.stringify(data))
      .then(function (rp) {
        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
        })
        );
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  return (
    <div id="editar_perfil">
      <Container maxW={'95%'} mb={20}>
        <Flex direction='row' mb={8}>
          <Button colorScheme="teal" w={'5%'} rounded={'none'} h={'58px'} onClick={e => setEditar_perfil(0)}><ChevronLeftIcon boxSize={8} /></Button>
          <Flex background={'white'} border={'1px'} p={2} w={'95%'}>
            <Heading size={'md'} color={'gray.700'} mt={2} ml={3}>Editar Usuário</Heading>
            <Spacer />
            <ButtonGroup gap='4'>
            </ButtonGroup>
          </Flex>
        </Flex>
        <Heading as='h4' size='md' textAlign={'left'}>
          Dados Pessoais
        </Heading>
        <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
        <Grid
          h='40px'
          templateRows='repeat(1, 1fr)'
          templateColumns='repeat(12, 1fr)'
          gap={4}
        >
          <GridItem colSpan={8} >
            <Stack spacing={3} direction='row'><Text>Nome</Text><Text color={'red.600'}>*</Text><Input borderColor={'gray'} maxLength={220} placeholder='Nome Completo' size='sm' value={usu_nome} onChange={e => setUsu_nome(e.target.value)} /></Stack>
          </GridItem>

          <GridItem colSpan={4}>
            <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Data de Nascimento</Text><Input autoComplete="no" borderColor={'gray'} type='date' size='sm' value={usu_nascimento} onChange={e => setUsu_nascimento(e.target.value)} /></Stack>
          </GridItem>
        </Grid>

        <Stack spacing={1} direction='column' mb={4}><Text whiteSpace='nowrap'>Formação</Text>
          <Textarea borderColor={'gray'}
            minH="unset"
            overflow="hidden"
            w="100%"
            resize="none"
            minRows={2}
            as={ResizeTextarea}
            placeholder="Descrição da formação do usuário..."
            value={usu_formacao}
            onChange={e => setUsu_formacao(e.target.value)}
          /></Stack>

        <Heading as='h4' size='md' textAlign={'left'}>
          Atividades Desempenhadas
        </Heading>
        <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />

        <Grid
          h='40px'
          templateRows='repeat(1, 1fr)'
          templateColumns='repeat(12, 1fr)'
          gap={4}
        >
          <GridItem colSpan={4}>
            <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Setor</Text><Text color={'red.600'}>*</Text>
              <Select borderColor={'gray'} size='sm' value={usu_setor} onChange={e => setUsu_setor(e.target.value)}>
                <option key={0} value={''}>-</option>
                <option key={1} value={'Gestão'}>Gestão</option>
                <option key={2} value={'Jurídico'}>Jurídico</option>
                <option key={3} value={'Financeiro'}>Financeiro</option>
                <option key={4} value={'Comercial'}>Comercial</option>
                <option key={5} value={'Documental'}>Documental</option>
                <option key={6} value={'Topografia'}>Topografia</option>
                <option key={7} value={'Projeto'}>Projeto</option>
                <option key={8} value={'Pós-Protocolo'}>Pós-Protocolo</option>
              </Select>
            </Stack>
          </GridItem>
          <GridItem colSpan={8}>
            <Stack spacing={3} direction='row'><Text>Cargo</Text><Text color={'red.600'}>*</Text><Input borderColor={'gray'} maxLength={80} size='sm' value={usu_cargo} onChange={e => setUsu_cargo(e.target.value)} /></Stack>
          </GridItem>
        </Grid>

        <Stack spacing={1} direction='column' mb={4}><Text whiteSpace='nowrap'>Funções</Text>
          <Textarea borderColor={'gray'}
            minH="unset"
            overflow="hidden"
            w="100%"
            resize="none"
            minRows={2}
            as={ResizeTextarea}
            placeholder="Descrição das funções desempenhadas pelo usuário..."
            value={usu_funcao}
            onChange={e => setUsu_funcao(e.target.value)}
          /></Stack>

        <Heading as='h4' size='md' textAlign={'left'}>
          Dados de Acesso
        </Heading>
        <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />

        <Grid
          h='40px'
          templateRows='repeat(1, 1fr)'
          templateColumns='repeat(12, 1fr)'
          gap={4}
        >
          <GridItem colSpan={12}>
            <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>E-mail</Text><Text color={'red.600'}>*</Text><Input borderColor={'gray'} size='sm' type="email" value={usu_email} onChange={e => setUsu_email(e.target.value)} /></Stack>
          </GridItem>
        </Grid>

        <Flex direction='row' p={5} mt={4} mb={4}>
          <Spacer />
          <Button colorScheme="teal" variant={'outline'} onClick={e => salvarPerfil()}>Salvar Perfil <SaveIcon ml={2} /></Button>
        </Flex>

      </Container>

    </div>
  );
}

export default EditarPerfil;