import React from "react";
import { Button } from "@chakra-ui/react";
import XLSX from 'sheetjs-style';
import { DownloadIcon } from "@chakra-ui/icons";

const ExportExcel = ({excelData, fileName}) => {

  const fileExtension = '.xlsx';
  const exportToExcel = async () => {
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "dados");
    XLSX.writeFile(workbook, fileName + fileExtension, { compression: true });
  }

  return (
    <>
      <Button colorScheme="teal" variant='outline' onClick={e => exportToExcel(fileName)}>Excel<DownloadIcon ml={2} /></Button>
    </>
  )

}

export default ExportExcel;