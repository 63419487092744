import React from "react";
import { useContext, useState, useEffect } from "react";

import NavSuperior from "../../components/NavBar/navsuperior";
import DadosRemessa from "../../components/Remessa/dadosremessa";

import { ReurbContext } from "../../contexts/reurb";

import ExibirNucleo from "./exibirnucleo";
import EditarNucleo from "./editarnucleo";

const NucleoPage = () => {
    const { editar_nucleo, setEditar_nucleo } = useContext(ReurbContext);

    const [loaded, setLoaded] = useState(0);

    //Ler dados
    useEffect(() => {
        setEditar_nucleo(0);
        setLoaded(1);
    }, []);

    function renderizar() {
        if (loaded === 1) {
            if (editar_nucleo === 0) {
                return (
                    <ExibirNucleo />
                );
            }
            else {
                return (
                    <EditarNucleo />
                );
            }
        } else {
            return null;
        }
    }

    return (
        <div id="nucleo">
            <NavSuperior />
            <DadosRemessa titulo="Painel da Núcleo" />
            {renderizar()}
        </div>
    );
}

export default NucleoPage;