import React from "react";
import { useContext, useState, useEffect } from "react";
import { Text, Button, Center, Container, Heading, useToast, Grid, GridItem, Stack, Input, Spacer, Flex, ButtonGroup, useDisclosure, Box, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalHeader, ModalFooter, SimpleGrid, Tag } from '@chakra-ui/react';
import { ChevronLeftIcon, EditIcon, NotAllowedIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { PasswordIcon } from "../../icons/passwordicon";
import { CredencialIcon } from "../../icons/credencialicon";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";

import { api } from "../../../services/api";

const ExibirPerfil = () => {
  const { logout, setEditar_perfil, usuario_selecionado, user, credencial } = useContext(AuthContext);
  //Variáveis Chakra UI
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen: isSituacaoOpen, onOpen: onSituacaoOpen, onClose: onSituacaoClose } = useDisclosure();
  const { isOpen: isSenhaOpen, onOpen: onSenhaOpen, onClose: onSenhaClose } = useDisclosure();
  const { isOpen: isResetarOpen, onOpen: onResetarOpen, onClose: onResetarClose } = useDisclosure();
  const { isOpen: isCredencialOpen, onOpen: onCredencialOpen, onClose: onCredencialClose } = useDisclosure();

  //Variáveis
  const [usu_nome, setUsu_nome] = useState("");
  const [usu_nascimento, setUsu_nascimento] = useState("");
  const [usu_senha, setUsu_senha] = useState("");
  const [usu_email, setUsu_email] = useState("");
  const [usu_setor, setUsu_setor] = useState("");
  const [usu_cargo, setUsu_cargo] = useState("");
  const [usu_funcao, setUsu_funcao] = useState("");
  const [usu_formacao, setUsu_formacao] = useState("");
  const [usu_credencial, setUsu_credencial] = useState("");
  const [usu_situacao, setUsu_situacao] = useState("");

  //Variáveis alterar situação
  const [nova_sit, setNova_sit] = useState("");

  //Variáveis alterar senha
  const [ver_senha, setVer_senha] = useState(false);
  const [msg_senha, setMsg_senha] = useState("");
  const [nova_senha, setNova_senha] = useState("");
  const [repetir_senha, setRepetir_senha] = useState("");
  const [senha_gerada, setSenha_gerada] = useState("");
  const [msg_resetar, setMsg_resetar] = useState("");

  //Controle
  const [controle, setControle] = useState(false);

  //Ler dados usuário
  useEffect(() => {
    const data = {
      usu_id: usuario_selecionado
    }
    api.post('/usuario/LerExibirUsuario.php', JSON.stringify(data))
      .then(function (rp) {

        setUsu_nome(rp.data.usu_nome);
        setUsu_nascimento(rp.data.usu_nascimento);
        setUsu_email(rp.data.usu_email);
        setUsu_setor(rp.data.usu_setor);
        setUsu_cargo(rp.data.usu_cargo);
        setUsu_funcao(rp.data.usu_funcao);
        setUsu_formacao(rp.data.usu_formacao);
        setUsu_credencial(rp.data.usu_credencial);
        setUsu_situacao(rp.data.usu_situacao);

        setNova_sit(rp.data.usu_situacao);

      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }, [usuario_selecionado, controle]);

  //Alterar situação
  async function alterarSituacao() {
    if (nova_sit !== usu_situacao) {
      const data = {
        usu_id: usuario_selecionado,
        usu_situacao,
        nova_sit
      }
      await api.post('/usuario/AlterarSituacao.php', JSON.stringify(data))
        .then(function (rp) {
          if (rp.data.sucesso === true) {
            setUsu_situacao(nova_sit);
            onSituacaoClose();
          }
          return (toast({
            position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
          })
          );
        })
        .catch(function (error) {
          if (error.response.data === "EXPIRED") {
            logout();
          } else {
            console.log(error);
            return (toast({
              position: 'bottom-left', render: () => (<Box color='white' p={3} bg='red'>Erro: não foi possível registrar os dados!</Box>),
            })
            );
          }
        });
    }
  }

  //Alterar senha
  async function alterarSenha() {
    if (nova_senha !== "") {
      if (nova_senha === repetir_senha) {
        const data = {
          usu_id: usuario_selecionado,
          usu_senha,
          nova_senha
        }
        await api.post('/usuario/AlterarSenha.php', JSON.stringify(data))
          .then(function (rp) {
            setMsg_senha(rp.data.msg);
            if (rp.data.sucesso === true) {
              setMsg_senha("");
              setUsu_senha("");
              setNova_senha("");
              setRepetir_senha("");
              onSenhaClose();
              return (toast({
                position: 'bottom-left', render: () => (<Box color='white' p={3} bg={'teal'}>Senha do usuário alterada com sucesso!</Box>),
              }));
            }
          })
          .catch(function (error) {
            if (error.response.data === "EXPIRED") {
              logout();
            } else {
              console.log(error);
            }
          });
      } else {
        setMsg_senha("As novas senhas informadas devem ser iguais");
      }
    } else {
      setMsg_senha("Nenhuma senha informada!");
    }
  }

  //Resetar senha
  async function resetarSenha() {
    const data = {
      usu_id: usuario_selecionado
    }
    await api.post('/usuario/ResetarSenha.php', JSON.stringify(data))
      .then(function (rp) {
        setMsg_resetar(rp.data.msg);
        setSenha_gerada(rp.data.senha);
        if (rp.data.sucesso === true) {
          return (toast({
            position: 'bottom-left', render: () => (<Box color='white' p={3} bg={'teal'}>Senha do usuário alterada com sucesso!</Box>),
          }));
        }
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Alterar credencial
  async function alterarCredencial() {
    const data = {
      usu_id: usuario_selecionado
    }
    await api.post('/usuario/AlterarCredencial.php', JSON.stringify(data))
      .then(function (rp) {
        if (rp.data.sucesso === true) {
          onCredencialClose();
          setControle(!controle);
          return (toast({
            position: 'bottom-left', render: () => (<Box color='white' p={3} bg={'teal'}>Credencial de acesso alterada com sucesso!</Box>),
          }));
        }
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Ir para home
  function goToHome() {
    navigate('/');
  }

  return (
    <div id="exibir_perfil">
      <Container maxW={'95%'} mb={20}>
        <Flex direction='row' mb={16}>
          <Button colorScheme="teal" w={'5%'} rounded={'none'} h={'58px'} onClick={e => goToHome()}><ChevronLeftIcon boxSize={8} /></Button>
          <Flex background={'white'} border={'1px'} p={2} w={'95%'}>
            <Heading size={'md'} color={'gray.700'} mt={2} ml={3}>{usu_nome}</Heading>
            <Spacer />
            <ButtonGroup gap='4'>
              <Button colorScheme="teal" variant='outline' onClick={e => setEditar_perfil(1)}>Editar<EditIcon ml={2} /></Button>
              <Button display={user === usuario_selecionado ? 'block' : 'none'} colorScheme="teal" variant='outline' onClick={e => onSenhaOpen()}>Senha<PasswordIcon boxSize={5} ml={2} /></Button>
              <Button display={credencial === 'administrador' ? 'block' : 'none'} colorScheme="teal" variant='outline' onClick={e => onResetarOpen()}>Resetar<PasswordIcon boxSize={5} ml={2} /></Button>
              <Button display={credencial === 'administrador' && user !== usuario_selecionado ? 'block' : 'none'} colorScheme="teal" variant='outline' onClick={e => onCredencialOpen()}>Credencial<CredencialIcon ml={2} /></Button>
              <Button display={credencial === 'administrador' && user !== usuario_selecionado ? 'block' : 'none'} colorScheme="teal" variant='outline' onClick={e => onSituacaoOpen()}>Situação<NotAllowedIcon ml={2} /></Button>
            </ButtonGroup>
          </Flex>
        </Flex>
        <Grid
          templateRows='repeat(8, 1fr)'
          templateColumns='repeat(12, 1fr)'
          gap={4}
          pr={10}
        >
          <GridItem colSpan={6} textAlign={'right'} mr={8}>
            <Text fontSize={'xl'}>Nome</Text>
          </GridItem>
          <GridItem colSpan={6} >
            <Flex dir="row">
              <Heading size={'md'} color={'gray.700'}>{usu_nome}</Heading>
              <Tag variant='outline' colorScheme='gray' ml={5} display={usu_credencial === 'administrador' ? 'block' : 'none'}>Administrador</Tag>
            </Flex>
          </GridItem>
          <GridItem colSpan={6} textAlign={'right'} mr={8}>
            <Text fontSize={'xl'}>E-mail</Text>
          </GridItem>
          <GridItem colSpan={6} >
            <Text mt={1}>{usu_email}</Text>
          </GridItem>
          <GridItem colSpan={6} textAlign={'right'} mr={8}>
            <Text fontSize={'xl'}>Formação Profissional</Text>
          </GridItem>
          <GridItem colSpan={6} >
            <Text mt={1} display={usu_formacao === '' ? 'none' : 'block'}>{usu_formacao}</Text>
            <Text mt={1} display={usu_formacao === '' ? 'block' : 'none'} color={'red'}>Não Informado</Text>
          </GridItem>
          <GridItem colSpan={6} textAlign={'right'} mr={8}>
            <Text fontSize={'xl'}>Data de Nascimento</Text>
          </GridItem>
          <GridItem colSpan={6} >
            <Text mt={1} display={usu_nascimento === '' ? 'none' : 'block'}>{usu_nascimento}</Text>
            <Text mt={1} display={usu_nascimento === '' ? 'block' : 'none'} color={'red'}>Não Informado</Text>
          </GridItem>
          <GridItem colSpan={6} textAlign={'right'} mr={8}>
            <Text fontSize={'xl'}>Setor</Text>
          </GridItem>
          <GridItem colSpan={6} >
            <Text mt={1}>{usu_setor}</Text>
          </GridItem>
          <GridItem colSpan={6} textAlign={'right'} mr={8}>
            <Text fontSize={'xl'}>Cargo</Text>
          </GridItem>
          <GridItem colSpan={6} >
            <Text mt={1} display={usu_cargo === '' ? 'none' : 'block'}>{usu_cargo}</Text>
            <Text mt={1} display={usu_cargo === '' ? 'block' : 'none'} color={'red'}>Não Informado</Text>
          </GridItem>
          <GridItem colSpan={6} textAlign={'right'} mr={8}>
            <Text fontSize={'xl'}>Funções Desempenhadas</Text>
          </GridItem>
          <GridItem colSpan={6} >
            <Text mt={1} display={usu_funcao === '' ? 'none' : 'block'}>{usu_funcao}</Text>
            <Text mt={1} display={usu_funcao === '' ? 'block' : 'none'} color={'red'}>Não Informado</Text>
          </GridItem>
          <GridItem colSpan={6} textAlign={'right'} mr={8}>
            <Text fontSize={'xl'}>Situação</Text>
          </GridItem>
          <GridItem colSpan={6} >
            <Text mt={1}>{usu_situacao}</Text>
          </GridItem>
        </Grid>
      </Container>

      {/*MODAL CREDENCIAL DE ACESSO*/}
      <Modal isOpen={isCredencialOpen} onClose={onCredencialClose} isCentered size='2xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center'>CREDENCIAL DE ACESSO</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={3} direction='column'>
              <Flex direction='column' align='center' gap={3} m={5}>
                <Text textAlign={'center'} display={usu_credencial === 'administrador' ? 'block' : 'none'}>Tem certeza que deseja remover a credencial de acesso de administrador de {usu_nome}?</Text>
                <Text textAlign={'center'} display={usu_credencial !== 'administrador' ? 'block' : 'none'}>Tem certeza que deseja conceder a credencial de acesso de administrador a {usu_nome}?</Text>
              </Flex>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button display={usu_credencial === 'administrador' ? 'block' : 'none'} colorScheme="teal" variant='outline' onClick={e => alterarCredencial()}>Remover Credencial</Button>
            <Button display={usu_credencial !== 'administrador' ? 'block' : 'none'} colorScheme="teal" variant='outline' onClick={e => alterarCredencial()}>Conceder Credencial</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/*MODAL ALTERAR SITUAÇÃO*/}
      <Modal isOpen={isSituacaoOpen} onClose={onSituacaoClose} isCentered size='2xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center'>ALTERAR SITUAÇÃO DO USUÁRIO</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid rows={3} spacing={10} m={5}>
              <Box>
                <Center>
                  <Stack spacing={6} direction='row'>
                    <Button colorScheme={nova_sit === "Ativo" ? 'green' : 'blackAlpha'} w={'150px'} h={'50px'} onClick={e => setNova_sit("Ativo")}>Ativo</Button>
                    <Button colorScheme={nova_sit === "Cancelado" ? 'green' : 'blackAlpha'} w={'150px'} h={'50px'} onClick={e => setNova_sit("Cancelado")}><Text>Cancelado</Text></Button>
                  </Stack>
                </Center>
              </Box>
              <Box>
                <Center minH={'50px'}>
                  <Text display={usu_situacao === nova_sit ? 'block' : 'none'} textAlign={'center'}>A situação do usuário {usu_nome} está definida como {usu_situacao}</Text>
                  <Text display={usu_situacao === nova_sit ? 'none' : 'block'} textAlign={'center'}>Tem certeza que deseja alterar a situação do usuário {usu_nome} para {nova_sit}?</Text>
                </Center>
              </Box>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button display={usu_situacao === nova_sit ? 'none' : 'block'} colorScheme="teal" variant='outline' onClick={e => alterarSituacao()}>Alterar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/*MODAL ALTERAR SENHA*/}
      <Modal isOpen={isSenhaOpen} onClose={onSenhaClose} isCentered size='4xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center'>ALTERAR SENHA</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={3} direction='column'>
              <Flex direction='row' justifyContent='center'>
                <SimpleGrid columns={3} spacing={10} m={5}>
                  <Stack spacing={1} direction='column'><Text>Senha Atual</Text><Input borderColor={'gray'} textAlign={'center'} size='sm' value={usu_senha} onChange={e => setUsu_senha(e.target.value)} type={ver_senha === false ? 'password' : 'text'} /></Stack>
                  <Stack spacing={1} direction='column'><Text>Nova Senha</Text><Input borderColor={'gray'} size='sm' value={nova_senha} onChange={e => setNova_senha(e.target.value)} textAlign={'center'} type={ver_senha === false ? 'password' : 'text'} /></Stack>
                  <Stack spacing={1} direction='column'><Text>Repetir Nova Senha</Text><Input borderColor={'gray'} size='sm' value={repetir_senha} onChange={e => setRepetir_senha(e.target.value)} textAlign={'center'} type={ver_senha === false ? 'password' : 'text'} /></Stack>
                </SimpleGrid>
                <Flex w={'60px'} mt={12}>
                  <Button variant={'ghost'} w={'100%'} size={'sm'} onClick={e => setVer_senha(true)} display={ver_senha === false ? 'block' : 'none'}><ViewIcon color={'gray.500'} boxSize={5} /></Button>
                  <Button variant={'ghost'} w={'100%'} size={'sm'} onClick={e => setVer_senha(false)} display={ver_senha === true ? 'block' : 'none'}><ViewOffIcon color={'gray.500'} boxSize={5} /></Button>
                </Flex>
              </Flex>
              <Flex direction='row' justifyContent='center'><Text color='red.500'>{msg_senha}</Text></Flex>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" variant='outline' onClick={e => alterarSenha()}>Alterar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/*MODAL RESETAR SENHA*/}
      <Modal isOpen={isResetarOpen} onClose={onResetarClose} isCentered size='2xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center'>RESETAR SENHA</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={3} direction='column'>
              <Flex direction='column' align='center' gap={3} m={5}>
                <Text textAlign={'center'} display={senha_gerada === '' ? 'block' : 'none'}>Tem certeza que deseja resetar a senha do usuário {usu_nome}?</Text>
                <Text textAlign={'center'} display={senha_gerada === '' ? 'none' : 'block'}>Nova Senha Gerada</Text>
                <Text textAlign={'center'} color={'red'} display={msg_resetar === '' ? 'none' : 'block'}>{msg_resetar}</Text>
                <Center fontSize={'2xl'} borderWidth={'thin'} borderColor={'gray'} w={'300px'} h={'60px'} display={senha_gerada === '' ? 'none' : 'flex'}>{senha_gerada} </Center>
              </Flex>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" variant='outline' onClick={e => resetarSenha()}>Resetar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </div>
  );
}

export default ExibirPerfil;