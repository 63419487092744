import { createIcon } from '@chakra-ui/icons'

// using `path`
export const NucleoIcon = createIcon({
  displayName: 'NucleoIcon',
  viewBox: '0 0 24 24',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: [
    <circle
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      cx="12"
      cy="12"
      r="10"
    />,
    <rect
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      x="9"
      y="9"
      width="6"
      height="6" />
  ]
});