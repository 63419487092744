import { createIcon } from '@chakra-ui/icons'

// using `path`
export const ProtocoloIcon = createIcon({
	displayName: 'ProtocoloIcon',
	viewBox: '0 0 24 24',
	// path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
	path: [
		<path
			fill='none'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
			d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"
		/>]
});