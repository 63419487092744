import { createIcon } from '@chakra-ui/icons'

// using `path`
export const CheckSquareIcon = createIcon({
    displayName: 'CheckSquareIcon',
    viewBox: '0 0 24 24',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: [
        <polyline
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            points="9 11 12 14 22 4"
        />,
        <path
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"
        />
    ]
});