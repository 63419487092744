import { createIcon } from '@chakra-ui/icons'

// using `path`
export const RequerenteIcon = createIcon({
  displayName: 'RequerenteIcon',
  viewBox: '0 0 24 24',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: [
    <path
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"
    />,
    <circle
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      cx="9" cy="7" r="4"
    />,
    <path
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      d="M23 21v-2a4 4 0 0 0-3-3.87"
    />,
    <path
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      d="M16 3.13a4 4 0 0 1 0 7.75"
    />
  ]
});