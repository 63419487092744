import React, { useState, createContext, useEffect } from "react";
export const ReurbContext = createContext();

export const ReurbProvider = ({ children }) => {

    //Verificar sessionStorage, persist data on page refresh
    const [rem_uf, setRem_uf] = useState(sessionStorage.getItem("rem_uf") ? sessionStorage.getItem("rem_uf") : "SC");
    const [rem_id, setRem_id] = useState(sessionStorage.getItem("rem_id") ? sessionStorage.getItem("rem_id") : 0);
    const [rem_municipio, setRem_municipio] = useState(sessionStorage.getItem("rem_municipio") ? sessionStorage.getItem("rem_municipio") : "");
    const [rem_remessa, setRem_remessa] = useState(sessionStorage.getItem("rem_remessa") ? sessionStorage.getItem("rem_remessa") : 0);
    const [unid_id, setUnid_id] = useState(sessionStorage.getItem("unid_id") ? sessionStorage.getItem("unid_id") : 0);
    const [req_id, setReq_id] = useState(sessionStorage.getItem("req_id") ? sessionStorage.getItem("req_id") : 0);
    const [ocup_id, setOcup_id] = useState(sessionStorage.getItem("ocup_id") ? sessionStorage.getItem("ocup_id") : 0);
    const [nui_id, setNui_id] = useState(sessionStorage.getItem("nui_id") ? sessionStorage.getItem("nui_id") : 0);

    useEffect(() => {

        sessionStorage.setItem("rem_uf", rem_uf);
        sessionStorage.setItem("rem_id", rem_id);
        sessionStorage.setItem("rem_municipio", rem_municipio);
        sessionStorage.setItem("rem_remessa", rem_remessa);
        sessionStorage.setItem("unid_id", unid_id);
        sessionStorage.setItem("req_id", req_id);
        sessionStorage.setItem("ocup_id", ocup_id);
        sessionStorage.setItem("nui_id", nui_id);

    }, [rem_uf, rem_id, rem_municipio, rem_remessa, unid_id, req_id, ocup_id, nui_id]);

    
    const [editar_requerente, setEditar_requerente] = useState(0);
    const [editar_processo, setEditar_processo] = useState(0);
    const [editar_nucleo, setEditar_nucleo] = useState(0);
    const [pesquisar, setPesquisar] = useState("");
    const [botao_pesquisar, setBotao_pesquisar] = useState(0);
    const [comentario_alterado, setComentario_alterado] = useState(0);

    return (
        <ReurbContext.Provider
            value={{ rem_uf, setRem_uf, rem_id, setRem_id, rem_municipio, setRem_municipio, rem_remessa, setRem_remessa, unid_id, setUnid_id, req_id, setReq_id, ocup_id, setOcup_id, nui_id, setNui_id, editar_requerente, setEditar_requerente, editar_processo, setEditar_processo, editar_nucleo, setEditar_nucleo, pesquisar, setPesquisar, botao_pesquisar, setBotao_pesquisar, comentario_alterado, setComentario_alterado }}
        >
            {children}
        </ReurbContext.Provider>
    );
};