import React from "react";
import { useContext, useState, useEffect } from "react";
import { Text, Box, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Button, Flex, Spacer, Container, Heading, ButtonGroup } from '@chakra-ui/react';
import { PlusSquareIcon, ChevronLeftIcon, HamburgerIcon, WarningIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

import NavSuperior from "../../components/NavBar/navsuperior";
import DadosRemessa from "../../components/Remessa/dadosremessa";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

import { api } from "../../../services/api";

const NucleosPage = () => {
	const navigate = useNavigate();

	//Variáveis de Contexto
	const { logout } = useContext(AuthContext);
	const { rem_id, setNui_id } = useContext(ReurbContext);

	//Variáveis
	const [qtd_nucleos, setQtd_nucleos] = useState(0);
	const [sem_nucleo, setSem_nucleo] = useState(0);
	const [lista_nucleos, setLista_nucleos] = useState([{ nui_id: 0, nui_cod: '-', nui_nome: '-', processos: 0, matricula: '-', app: '-', risco: '-', responsavel: '-', nui_etapa: '-', nui_situacao: '-' }]);

	//Ler dados
	useEffect(() => {
		api.post('/nucleo/LerListaNucleos.php', JSON.stringify({ rem_id }))
			.then(function (rp) {
				setQtd_nucleos(rp.data.qtd_nucleos);
				setSem_nucleo(rp.data.sem_nucleo);
				setLista_nucleos(rp.data.lista_nucleos);
			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				} else {
					console.log(error);
				}
			});
	}, []);

	//Ir para remessa
	function goToRemessa() {
		navigate('/remessa');
	}

	//Ir para requerentes
	function goToRequerentes() {
		navigate('/requerentes');
	}

	//Ir para processos
	function goToProcessos() {
		navigate('/processos');
	}

	//Ir para núcleo
	function goToNucleo(id) {
		setNui_id(id);
		if (id !== 0) {
			navigate('/nucleo');
		}
	}

	//Adicionar novo núcleo
	async function adicionarNucleo() {
		//Novo núcleo
		await api.post('/nucleo/NovoNucleo.php', JSON.stringify({ rem_id }))
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				} else {
					console.log(error);
				}
			});

		//Recarregar lista de núcleos
		await api.post('/nucleo/LerListaNucleos.php', JSON.stringify({ rem_id }))
			.then(function (rp) {
				setQtd_nucleos(rp.data.qtd_nucleos);
				setLista_nucleos(rp.data.lista_nucleos);
			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				} else {
					console.log(error);
				}
			});
	}

	return (
		<div id="nucleos">
			<NavSuperior />
			<DadosRemessa titulo="Lista de Núcleos" />
			<Container maxW='95%' bg='white' mb={20}>
				<Flex direction='row'>
					<Button colorScheme="teal" w={'5%'} rounded={'none'} h={'58px'} onClick={e => goToRemessa()}><ChevronLeftIcon boxSize={8} /></Button>
					<Flex background={'white'} border={'1px'} p={2} w={'95%'}>
						<Heading size={'md'} color={'gray.700'} mt={2} ml={3}>Núcleos Cadastrados ({qtd_nucleos})</Heading>
						<Spacer />
						<ButtonGroup gap='4'>
							<Button colorScheme="teal" variant='outline' onClick={e => goToRequerentes()}>Requerentes<HamburgerIcon ml={2} /></Button>
							<Button colorScheme="teal" variant='outline' onClick={e => goToProcessos()}>Processos<HamburgerIcon ml={2} /></Button>
							<Button colorScheme="teal" variant='outline' onClick={e => adicionarNucleo()}>Novo Núcleo<PlusSquareIcon ml={2} /></Button>
						</ButtonGroup>
					</Flex>
				</Flex>
				<Box>
					<TableContainer mt={5}>
						<Table size='sm'>
							<Thead>
								<Tr>
									<Th textAlign={'center'}>CÓDIGO</Th>
									<Th textAlign={'center'}>NOME</Th>
									<Th textAlign={'center'}>PROCESSOS</Th>
									<Th textAlign={'center'}>ANÁLISE DA MATRÍCULA</Th>
									<Th textAlign={'center'}>AMBIENTAL</Th>
									<Th textAlign={'center'}>RISCO</Th>
									<Th textAlign={'center'}>RESPONSÁVEL</Th>
									<Th textAlign={'center'}>PROJETO</Th>
									<Th textAlign={'center'}>SITUAÇÃO</Th>
								</Tr>
							</Thead>
							<Tbody>
								{lista_nucleos.map((item, i) => {
									return (
										<Tr key={i} cursor='pointer' _hover={{ bg: 'gray.200' }} onClick={e => goToNucleo(item.nui_id)} bg={item.nui_situacao === 'Cancelado' ? 'red.100' : 'white'}>
											<Td textAlign={'center'}>{item.nui_cod}</Td>
											<Td>{item.nui_nome}</Td>
											<Td textAlign={'center'}>{item.processos}</Td>
											<Td textAlign={'center'}>{item.matricula}</Td>
											<Td textAlign={'center'}>{item.app}</Td>
											<Td textAlign={'center'}>{item.risco}</Td>
											<Td>{item.responsavel}</Td>
											<Td textAlign={'center'}>{item.nui_etapa}</Td>
											<Td textAlign={'center'}>{item.nui_situacao}</Td>
										</Tr>
									);
								})}
							</Tbody>
						</Table>
					</TableContainer>
				</Box>
				<Flex display={sem_nucleo > 0 ? 'flew' : 'none'} direction={'row'} borderRadius={5} borderColor={'red'} borderWidth={'thin'} m={5} p={5}>
					<WarningIcon color={'red.300'} boxSize={6} mr={5} />
					<Text>A remessa possui {sem_nucleo} {sem_nucleo > 1 ? 'processos ativos' : 'processo ativo'} sem núcleo definido!</Text>
				</Flex>
			</Container>
		</div>
	);
}

export default NucleosPage;