import React from "react";
import { useContext, useState, useEffect } from "react";
import { Box, Flex, Spacer, Button, ButtonGroup, Input, Divider, Menu, MenuButton, MenuList, MenuItem, Avatar, Text, InputGroup, InputRightElement } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

import { api } from "../../../services/api";

const NavSuperior = () => {
	const { logout, user, setUsuario_selecionado } = useContext(AuthContext);
	const { pesquisar, setPesquisar, botao_pesquisar, setBotao_pesquisar } = useContext(ReurbContext);
	const navigate = useNavigate();

	//Variáveis
	const [nome, setNome] = useState("");

	//Ler dados
	useEffect(() => {
		const data = {
			usu_id: user
		};
		api.post('/navbar/LerUsuario.php', JSON.stringify(data))
			.then(function (rp) {

				setNome(rp.data.nome);

			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				}
			});
	}, []);

	//Páginas
	const goToHome = () => {
		navigate("/");
	}

	const goToMetas = () => {
		navigate("/metas");
	}

	const goToUsuarios = () => {
		navigate("/usuarios");
	}

	const goToReurb = () => {
		navigate("/reurb");
	}

	const goToOficios = () => {
		navigate("/oficios");
	}

	const goToContato = () => {
		navigate("/contato");
	}

	//Perfil
	function handlePerfil() {
		setUsuario_selecionado(user);
		navigate("/perfil");
	}

	//Pesquisar
	function handlePesquisar() {
		setBotao_pesquisar(botao_pesquisar === 0 ? 1 : 0);
		navigate("/pesquisar");
	}

	function handleKeyDown(event) {
		if (event.key == "Enter") {
			handlePesquisar();
		}
	}

	//Logout
	const handleLogout = () => {
		logout();
	}



	return (
		<div id="nav-superior">
			<Flex bg='#17183D'>
				<Box w="40%" p='4'>
					<ButtonGroup gap='8'>
						<Button color='white' variant='link' size='sm' onClick={goToHome}>
							Home
						</Button>
						{/*<Button color='white' variant='link' size='sm' onClick={goToMetas}>
							Metas
						</Button>*/}
						<Button color='white' variant='link' size='sm' onClick={goToUsuarios}>
							Equipe
						</Button>
						<Button color='white' variant='link' size='sm' onClick={goToReurb}>
							Reurb
						</Button>
						{/*<Button color='white' variant='link' size='sm' onClick={goToOficios}>
							Ofícios
						</Button>*/}
						{/*<Button color='white' variant='link' size='sm' onClick={goToContato}>
							Contato
						</Button>*/}
					</ButtonGroup>
				</Box>
				<Spacer />
				<Box p='4'>
					<InputGroup w='400px'>
						<Input placeholder='Buscar Requerente' size='md' color='white' _placeholder={{ color: 'white' }} value={pesquisar} onChange={e => { setPesquisar(e.target.value) }} onKeyDown={e => handleKeyDown(e)} />
						<Button ml={-10} variant={'unstyled'} onClick={handlePesquisar}><SearchIcon boxSize={6} color={'white'} /></Button>
					</InputGroup>

				</Box>
				<Menu >
					<MenuButton ml={15} mr={8} mt={2}>
						<Avatar mt={-2} boxSize={'10'} bg={'gray.100'} color={'black'} name={nome} src='' />
					</MenuButton>
					<MenuList>
						<Text textAlign={'center'} m={5}>{nome}</Text>
						<Divider />
						<MenuItem onClick={e => handlePerfil()}>Meu Perfil</MenuItem>
						<MenuItem onClick={handleLogout}>Sair</MenuItem>
					</MenuList>
				</Menu>
			</Flex>
		</div>
	);
}

export default NavSuperior;